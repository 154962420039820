import * as sounds from '../../assets/sounds';

export function getSoundFromKey(key: string) {
	switch (key) {
	case 'teststeps':
		// return sounds.mcSteps;'
		return sounds.steps_01;
		break;


		// Caddy

	case 'caddy_00':
		return sounds.caddy_c00_dia;

		break;
	case 'caddy_01':
		return sounds.caddy_c01_dia;

		break;
	case 'caddy_02':
		return sounds.caddy_c02_dia;

		break;
	case 'caddy_03':
		return sounds.caddy_c03_dia;

		break;
	case 'caddy_04':
		return sounds.caddy_c04_dia;

		break;
				

		// Hal
	case 'hal_00':
		return sounds.hal_c00_dia;
		break;
	case 'hal_01':
		return sounds.hal_c01_dia;
		break;
	case 'hal_02':
		return sounds.hal_c02_dia;
		break;
	case 'hal_03':
		return sounds.hal_c03_dia;
		break;
	case 'hal_04':
		return sounds.hal_c04_dia;
		break;
	case 'hal_06':
		return sounds.hal_c06_dia;
		break;
	case 'hal_07':
		return sounds.hal_c07_dia;
		break;
	case 'hal_08':
		return sounds.hal_c08_dia;
		break;
	case 'hal_09':
		return sounds.scene_01_requested_09;
		break;
	case 'hal_10':
		return sounds.scene_01_requested_10;
		break;
	case 'hal_11':
		return sounds.scene_01_requested_11;
		break;

		// Woonkamer

	case 'woonkamer_01':
		return sounds.woonkamer_c01_dia;
		break;
	case 'woonkamer_02':
		return sounds.woonkamer_c02_dia;
		break;
	case 'woonkamer_03':
		return sounds.woonkamer_c03_dia;
		break;
	case 'woonkamer_05':
		return sounds.woonkamer_c05_dia;
		break;
	case 'woonkamer_06':
		return sounds.woonkamer_c06_dia;
		break;
	case 'woonkamer_07':
		return sounds.woonkamer_c07_dia;
		break;
	case 'woonkamer_09':
		return sounds.woonkamer_c09_dia;
		break;
	case 'woonkamer_10':
		return sounds.woonkamer_c10_dia;
		break;
	case 'woonkamer_11':
		return sounds.woonkamer_c11_dia;
		break;
	case 'woonkamer_12':
		return sounds.scene_02_requested_11;
		break;
	case 'woonkamer_13':
		return sounds.scene_02_requested_12;
		break;
	case 'woonkamer_14':
		return sounds.scene_02_requested_13;
		break;
	case 'woonkamer_15':
		return sounds.scene_02_requested_14;
		break;


		// Werkkamer

	case 'werkkamer_00':
		return sounds.werkkamer_c00_dia;
		break;
	case 'werkkamer_02':
		return sounds.werkkamer_c02_dia;
		break;
	case 'werkkamer_03':
		return sounds.werkkamer_c03_dia;
		break;
	case 'werkkamer_04':
		return sounds.werkkamer_c04_dia;
		break;
	case 'werkkamer_05':
		return sounds.werkkamer_c05_dia;
		break;
	case 'werkkamer_07':
		return sounds.werkkamer_c07_dia;
		break;
	case 'werkkamer_08':
		return sounds.werkkamer_c08_dia;
		break;
	case 'werkkamer_09':
		return sounds.werkkamer_c09_dia;
		break;
	case 'werkkamer_10':
		return sounds.scene_03_requested_10;
		break;
	case 'werkkamer_11':
		return sounds.scene_03_requested_11;
		break;
	case 'werkkamer_12':
		return sounds.scene_03_requested_12;
		break;

		
  
  
  

		// Game Room

	case 'gamekamer_00':
		return sounds.gamekamer_c00_dia;
		break;
	case 'gamekamer_02':
		return sounds.gamekamer_c02_dia;
		break;
	case 'gamekamer_03':
		return sounds.gamekamer_c03_dia;
		break;
	case 'gamekamer_04':
		return sounds.gamekamer_c04_dia;
		break;
	case 'gamekamer_06':
		return sounds.gamekamer_c06_dia;
		break;
	case 'gamekamer_07':
		return sounds.gamekamer_c07_dia;
		break;
	case 'gamekamer_08':
		return sounds.gamekamer_c08_dia;
		break;
	case 'gamekamer_09':
		return sounds.gamekamer_c09_dia;
		break;
	case 'gamekamer_10':
		return sounds.gamekamer_c10_dia;
		break;
	case 'gamekamer_11':
		return sounds.gamekamer_c11_dia;
		break;
	case 'gamekamer_12':
		return sounds.gamekamer_c12_dia;
		break;
	case 'gamekamer_13':
		return sounds.gamekamer_c13_dia;
		break;
	case 'gamekamer_14':
		return sounds.scene_04_requested_09;
		break;
	case 'gamekamer_15':
		return sounds.scene_04_requested_10;
		break;
		

		// Keuken

	case 'keuken_01':
		return sounds.keuken_c01_dia;
		break;
	case 'keuken_02':
		return sounds.keuken_c02_dia;
		break;
	case 'keuken_03':
		return sounds.keuken_c03_dia;
		break;
	case 'keuken_04':
		return sounds.keuken_c04_dia;
		break;
	case 'keuken_05':
		return sounds.keuken_c05_dia;
		break;
	case 'keuken_07':
		return sounds.keuken_c07_dia;
		break;
	case 'keuken_08':
		return sounds.keuken_c08_dia;
		break;
	case 'keuken_09':
		return sounds.keuken_c09_dia;
		break;
	case 'keuken_10':
		return sounds.keuken_c10_dia;
		break;
	case 'keuken_11':
		return sounds.keuken_c11_dia;
		break;
	case 'keuken_12':
		return sounds.keuken_c12_dia;
		break;
	case 'keuken_13':
		return sounds.keuken_c13_dia;
		break;


		// General

	case 'deur_01':
		return sounds.dooropen_01;
		break;

	case 'notification':
		return sounds.dialogueNotification_02;
		break;

	case 'background_01':
		return sounds.drone_01;
		break;

	case 'background_02':
		return sounds.drone_02;
		break;

	case 'code_wrong':
		return sounds.code_wrong;
		break;

	default:
		return null;
		break;
	// case 'testbackground':
	// 	return sounds.backgroundmusic_01;
	// 	break;
	}
}