import { useGLTF } from '@react-three/drei';
import { Meterkast } from 'assets/models';
import InteractivePoints from 'components/InteractivePoints';
import Model from 'components/Model';
import { useGameContext } from 'context/gameContext';
import React, { Suspense, useEffect } from 'react';

useGLTF.preload(Meterkast);

const scale = 1.2;

const MeterkastScene = () => { 
	const {currentModelData, onAddActiveAnimation, onSetActiveLayer, onStoryEvent, closeButtonActive, setCloseButtonActive, activeAnimations, modelIntroDone, queueStoryEvent, queueFragmentSwap, activeModelLayer, onSetNotificationText, setNotificationActive} = useGameContext();


	useEffect(() => {
		if(modelIntroDone){
			onStoryEvent && onStoryEvent({type:'setRequestedNarrative', param:'scene_01_requested_01'});
			onSetNotificationText && onSetNotificationText(9);
		}
	}, [modelIntroDone]);

	useEffect(() => {
		if(closeButtonActive)
			setCloseButtonActive && setCloseButtonActive(false);
	}, []);

	const onInteractivePointClick = (point) => {
		switch(point.name){
		case '01':
			onAddActiveAnimation && onAddActiveAnimation({nodeName: 'troep_01', animationName:'troep_01'});
			onSetActiveLayer && onSetActiveLayer(2);
			onStoryEvent && onStoryEvent({type:'setRequestedNarrative', param:null});
			setNotificationActive && setNotificationActive(false);
			break;
		case '02':
			onAddActiveAnimation && onAddActiveAnimation({nodeName: 'troep_02', animationName:'troep_02'});
			onSetActiveLayer && onSetActiveLayer(3);
			break;
		case '03':
			onAddActiveAnimation && onAddActiveAnimation({nodeName: 'troep_03', animationName:'troep_03_slecht'});
			onAddActiveAnimation && onAddActiveAnimation({nodeName: 'troep_03_goed', animationName:'troep_03_goed'});

			onSetActiveLayer && onSetActiveLayer(4);
			break;
		case '04':
			onAddActiveAnimation && onAddActiveAnimation({nodeName: 'KPN_ISRA2', animationName:'kpn_isra_zoom'});
			onAddActiveAnimation && onAddActiveAnimation({nodeName: 'Meterkast', animationName:'meterkast_fade'});
			onAddActiveAnimation && onAddActiveAnimation({nodeName: 'troep_03_goed', animationName:'meterkast_fade'});
			onSetActiveLayer && onSetActiveLayer(5);
			break;
		case '05':
			onAddActiveAnimation && onAddActiveAnimation({nodeName: 'UTP_Kabel_Blauw_Kapot', animationName:'kabel_kapot'});
			onStoryEvent && onStoryEvent({type:'setRequestedNarrative', param:'scene_01_requested_05'});
			onSetActiveLayer && onSetActiveLayer(6);
			break;
		case '06':
			onAddActiveAnimation && onAddActiveAnimation({nodeName: 'UTP_Kabel_Kapot_Swap', animationName:'kabel_swap'});
			onSetActiveLayer && onSetActiveLayer(7);
			break;

		case '07':
			onStoryEvent && onStoryEvent({type:'setRequestedNarrative', param:null});
			onAddActiveAnimation && onAddActiveAnimation({nodeName: 'UTP_Kabel_Blauw_Goed', animationName:'kabel_goed'});
			onSetActiveLayer && onSetActiveLayer(8);
			break;
		}
	};

	// Event Triggers based on animationEnd and activeLayer
	useEffect(() => {
		if(activeAnimations && activeAnimations.length === 0){
			switch(activeModelLayer){

			case 8:	
				onStoryEvent && onStoryEvent({type:'openDialogue', param: 'scene_01_automatic_02'});
				setCloseButtonActive && setCloseButtonActive(true);
				queueStoryEvent && queueStoryEvent({type: 'openModel', param: 'deur'});
				queueFragmentSwap && queueFragmentSwap(4);
				break;
			}
		}
	}, [activeAnimations]);
	
	return (
		<>
			<Suspense fallback={null}>
				{currentModelData && <Model customScale={scale}/>}
				{currentModelData && <InteractivePoints onInteractivePointClick={(name: string) => onInteractivePointClick(name)} customModelScale={scale}/>}
			</Suspense>
		</>
	);
};

export default MeterkastScene;