import React, {useState, createContext, useContext, useEffect} from 'react';
import {getSoundFromKey} from '../components/audio/AudioLibrary';

type ContextProps = {
	playBackground: (state, key?, volume?) => void,
	playSound: (key, volume?) => void,
	playVO: (key, volume?) => void,
	duration: number,
	setInterfaceReady: (val: boolean) => void,
	clientDone: boolean,
}

const AudioContext = createContext<Partial<ContextProps>>({});

interface SceneProps {
	children: JSX.Element,
}

const AudioProvider = ({ children }: SceneProps) => {

	const [audioSources, setAudioSources] = useState<HTMLAudioElement[]>([new Audio()]);
	const [voSource] = useState<HTMLAudioElement>(new Audio());
	const [source, setSource] = useState<any>();
	const [duration, setDuration] = useState<any>();
	const [interfaceReady, setInterfaceReady] = useState<boolean>(false);
	const [clientDone, setClientDone] = useState<boolean>(false);

	const [background, setBackground] = useState(new Audio(getSoundFromKey('testbackground')));
	const [newSource, setNewSource] = useState<boolean>(false);

	const maxAudioSources = 50;

	const playBackground = (state : boolean, key?: string, volume?: number) =>{
		if(background) {
			background.pause();
		}
		if(state || key){
			if(key){
				background.src = getSoundFromKey(key);
			}
			if(volume){
				background.volume = volume;
			}
			background.loop = true;
			background.play();
		}
		else{
			background.pause();
		}
	};

	const playSound = (key: string, volume?: number) =>{
		let source = audioSources.find(el => el.paused);
		if (!source && audioSources.length <= maxAudioSources){
			source = new Audio();
			audioSources.push(source);
		}
		if (source) {
			source.src = getSoundFromKey(key);
			if(volume){
				source.volume = volume;
			}
			source.play();
		}
	};

	const playVO = (key: string, volume?: number) => {
		setDuration(null);
		setClientDone(false);
		setInterfaceReady(false);
		const newKey = getSoundFromKey(key);
		if(!newKey){
			setSource(null);
			setDuration(1);
			setTimeout(() => {
				setClientDone(true);
			}, 1000);	
		}
		setSource(newKey);
		setNewSource(true);
	};

	const addEventListeners = () => {
		if(voSource) {
			voSource.addEventListener('loadeddata', () => {setDuration(voSource.duration);});
			voSource.addEventListener('ended', () => {setClientDone(true);});
		}
	};

	const removeEventListeners = () => {
		if(voSource) {
			// voSource.removeEventListener('loadeddata', () => {});
			voSource.addEventListener('ended', () => {setClientDone(false);});
		}
	};

	useEffect(() => {
		if(!source) {
			const VO = voSource;
			VO.pause();
		}
		if(source && newSource) {
			const VO = voSource;

			VO.src = source;

			removeEventListeners();
			addEventListeners();
			setNewSource(false);
		}
	}, [source, newSource]);

	useEffect(() => {
		if(!interfaceReady) {
			interfaceReady;
		}
		if(interfaceReady && source) {
			voSource.play();
		}
	}, [interfaceReady]);
	

	return(
		<AudioContext.Provider value={{playVO, playBackground, playSound, duration, setInterfaceReady, clientDone}}>
			{children}
		</AudioContext.Provider>
	);
};

const useAudioContext = () => React.useContext(AudioContext);

export { useAudioContext, AudioContext};

export default AudioProvider;
