import { useGLTF } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import { hexagons1 } from 'assets/models';
import InteractivePoints from 'components/InteractivePoints';
import Model from 'components/Model';
import { useGameContext } from 'context/gameContext';
import React, { Suspense, useEffect } from 'react';

useGLTF.preload(hexagons1);

const scale = 2;

const Hexagons1Scene = () => { 
	const {currentModelData, onSetActiveLayer, activeModelLayer, onStoryEvent} = useGameContext();
	const { scene } = useThree();

	const onInteractivePointClick = (point) => {
		switch(point.name){
		case '01':
			if(scene) {
				scene.getObjectByName('hex_01_uit').visible = false;
				scene.getObjectByName('hex_01_aan').visible = true;
				setTimeout(() => {
					scene.getObjectByName('hex_01_uit').visible = true;
					scene.getObjectByName('hex_01_aan').visible = false;
				}, 2000);
			}
			break;
		}
	};
		
	return (
		<>
			<Suspense fallback={null}>
				{currentModelData && <Model rotation-y={9.5} customScale={scale}/>}
				{currentModelData && <InteractivePoints onInteractivePointClick={(name: string) => onInteractivePointClick(name)} customModelScale={scale}/>}
			</Suspense>
		</>
	);
};

export default Hexagons1Scene;