const designFormats = [640, 1920];

const theme = {
	colors: {
		light_100: 'white',
		light_200: '#e2e2e2',
		dark_100: 'black',
		dark_200: 'grey',
		accent_1: '#009900',
		accent_1_dark: '#009100',
	},
	breakpoints: [`${designFormats[0]}px`, `${designFormats[1]}px`],
	designFormats: designFormats,
	mediaQueries: {
		l_max: '@media (max-width: 640px)',
		l: '@media (min-width: 640px)',
		xl: '@media (min-width: 1920px)',
	},
};


export {designFormats};
export default theme;