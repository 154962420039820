import { useSceneContext } from 'context/sceneContext';
import React, { useEffect, useRef } from 'react';
import ReactPannellum, { addScene, loadScene} from 'react-pannellum';
import { caddy_01_p1, hal_01_p1, sceneList } from './Scenes';
import PubSub from 'pubsub-js';
import { resetCamera } from './CameraControls';
import {useAudioContext} from '../../context/AudioContext';
import { useGameContext } from 'context/gameContext';


const style = {
	width: '100vw',
	height: '100vh',
	background: '#000000'
};

const HOTSPOT = 'HOTSPOT';
const NAVIGATION = 'NAVIGATION';
const CHANGE = 'CHANGE SCENE';

const Pannellum = () => {

	const subscribeToken = useRef();

	useEffect(() => {

		subscribeToken.current = PubSub.subscribe(HOTSPOT, (name, val) => onHotspotActive(name, val));
		subscribeToken.current = PubSub.subscribe(NAVIGATION, (name, val) => onNavigation(name, val));
		subscribeToken.current = PubSub.subscribe(CHANGE, (name, val) => {onUpdateScene && onUpdateScene(val);});

		return () => {
			if(subscribeToken.current){PubSub.unsubscribe(subscribeToken.current);} 
		};
	}, []);

	const {onUpdateScene, currentScene, sceneFragment = 0, setSceneFragment, onUpdateActiveModel, activeModel, triggerQueuedStoryEvent, updatingScene} = useGameContext();
	const { playSound } = useAudioContext();
	
	const onAddScene = (newScene) => {
		addScene(newScene.sceneId, newScene.config, () => loadScene(
			newScene.sceneId, 
			newScene.startingPitch ? newScene.startingPitch : 0,
			newScene.startingYaw ? newScene.startingYaw : 0,   
			90, 
			false
		)); 
	};

	const onHotspotActive = (name, val) => {
		if(val && onUpdateActiveModel) {
			onUpdateActiveModel(val);
		}
	};

	const onNavigation = (name, val) => {
		if(setSceneFragment){
			setSceneFragment(val);
		}
	};

	useEffect(() => {
		if(!activeModel) {
			resetCamera();
			triggerQueuedStoryEvent && triggerQueuedStoryEvent();
		}
	},[activeModel]);

	
	useEffect(() => {

		const pannellumData = currentScene && currentScene.pannellumData;
		
		const newSceneId = pannellumData && sceneFragment > -1 && pannellumData[sceneFragment];
		
		const newScene = newSceneId && sceneList.find((scene) => scene.sceneId === newSceneId);

		if(newScene) {
			onAddScene(newScene);
		}

	}, [sceneFragment]);

	return (
		<div>
			<ReactPannellum
				config={caddy_01_p1.config}
				imageSource={caddy_01_p1.config.imageSource}
				id={caddy_01_p1.sceneId}
				sceneId={caddy_01_p1.sceneId}
				style={style}
			>
			</ReactPannellum>
		</div>
	);
};
    
export default Pannellum;
