import React from 'react';
import { useGameContext } from 'context/gameContext';
import InteractivePoint from 'components/CanvasContainer/InteractivePoint';

interface interactivePointsProps {
	onInteractivePointClick : any, // arrow function
	customModelScale? : number,
	customPointScale? : number,
}

const InteractivePoints = ({onInteractivePointClick, customModelScale, customPointScale}: interactivePointsProps) => {
	const {interactivePointsDisplay, modelPoints} = useGameContext();
	return (
		<group scale={customModelScale ? customModelScale : 1}>
			{interactivePointsDisplay && modelPoints && modelPoints.map((point) => {
				return (
					<InteractivePoint
						key={`${point.name}-${point.position}`}
						onClickHandler={(e) => {onInteractivePointClick(e);}}
						pointData={point.pointData}
						position={point.position}
						customModelScale={customModelScale}
						customBaseScale={customPointScale}
					/>
				);
			})}
		</group>
	);
};

export default InteractivePoints;