import { render_gamekamer_01_p2} from 'assets/renders';
import { getHotspots } from '../Hotspots';
import getConfig from './universalConfig';

const customConfig = {
	imageSource: render_gamekamer_01_p2,
};

// action: {label: changeScene | changeSceneFragment, value: string | number};

let customHotspots = [
	{
		'pitch': -8.1575,
		'yaw': 61.7561,
		'type': 'info',
		'createTooltipArgs': {
			'text': 'Numpad',
			'type': 'scene',
			'sceneId': 'numpad_gamekamer',
		}
	},
	{
		'pitch': -14.583,
		'yaw': 83.5517,
		'type': 'info',
		'createTooltipArgs': {
			'text': 'Flipperkast',
			'type': 'scene',
			'sceneId': 'flipperkast',
		}
	},
	{
		'pitch': 0.84944,
		'yaw': 169.0069,
		'type': 'info',
		'createTooltipArgs': {
			'text': 'Vlinders',
			'type': 'scene',
			'sceneId': 'vlinders',
		}
	},
	{
		'pitch': 3.6297,
		'yaw': 83.8269,
		'type': 'info',
		'createTooltipArgs': {
			'text': 'Hexagons',
			'type': 'scene',
			'sceneId': 'hexagons1',
		}
	},
	{
		'pitch': -2.8939,
		'yaw': -170.111,
		'type': 'info',
		'createTooltipArgs': {
			'text': 'Hexagons',
			'type': 'scene',
			'sceneId': 'hexagons2',
		}
	},
	{
		'pitch':  0.3890,
		'yaw': 120.997,
		'type': 'info',
		'createTooltipArgs': {
			'text': 'Kastjes',
			'type': 'scene',
			'sceneId': 'hexagonkastjes',
		}
	},
	{
		'pitch': -23.3535,
		'yaw':  61.2808,
		'type': 'scene',
		'createTooltipArgs': {
			'text': '',
			action: {
				'label': 'navigation',
				value: 6,
			}
		}
	},
];

// Asign standard hotspot config
customHotspots = getHotspots(customHotspots);
customConfig['hotSpots'] = customHotspots;

const Scene = {
	sceneId: 'gamekamer_04_p2',
	config: getConfig(customConfig),
	startingPitch: -51.63,
	startingYaw: -33.039,
};

export default Scene;

