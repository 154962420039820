import { render_gamekamer_01_p2} from 'assets/renders';
import { getHotspots } from '../Hotspots';
import getConfig from './universalConfig';

const customConfig = {
	imageSource: render_gamekamer_01_p2,
};

// action: {label: changeScene | changeSceneFragment, value: string | number};

let customHotspots = [
	{
		'pitch': -23.3535,
		'yaw':  61.2808,
		'type': 'scene',
		'createTooltipArgs': {
			'text': '',
			action: {
				'label': 'navigation',
				value: 0,
			}
		}
	},
	{
		'pitch': -61.155,
		'yaw': -89.3046,
		'type': 'info',
		'createTooltipArgs': {
			'text': 'Spelcomputer',
			'type': 'scene',
			'sceneId': 'ps5_01',
		}
	},
];

// Asign standard hotspot config
customHotspots = getHotspots(customHotspots);
customConfig['hotSpots'] = customHotspots;

const Scene = {
	sceneId: 'gamekamer_01_p2',
	config: getConfig(customConfig),
	// startingPitch: -15.041,
	// startingYaw: 126.985,
};

export default Scene;

