import { useGLTF } from '@react-three/drei';
import { Ladder } from 'assets/models';
import InteractivePoints from 'components/InteractivePoints';
import Model from 'components/Model';
import { useGameContext } from 'context/gameContext';
import React, { Suspense, useEffect } from 'react';

useGLTF.preload(Ladder);

const scale = 2;

const LadderScene = () => { 
	const {currentModelData, modelIntroDone, activeModelLayer, onUpdateActiveModel, onSetActiveLayer, onStoryEvent} = useGameContext();

	useEffect(() => {
		if(modelIntroDone && activeModelLayer === 1){
			onStoryEvent && onStoryEvent({type: 'openDialogue', param: 'scene_02_automatic_01'});
			onSetActiveLayer && onSetActiveLayer(2);
			onUpdateActiveModel && onUpdateActiveModel(null);
		}
	}, [modelIntroDone]);

	const onInteractivePointClick = (point) => {
		switch(point.name){
		case '01':
		}
	};

	return (
		<>
			<Suspense fallback={null}>
				{currentModelData && <Model customScale={scale}/>}
				{currentModelData && <InteractivePoints onInteractivePointClick={(name: string) => onInteractivePointClick(name)} customModelScale={scale}/>}
			</Suspense>
		</>
	);
};

export default LadderScene;
