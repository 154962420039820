import { sceneProps } from 'data';
import scene1 from './scene1';
import scene2 from './scene2';
import scene3 from './scene3';
import scene4 from './scene4';
import scene5 from './scene5';
import scene6 from './scene6';

const scenes: sceneProps[] = [
	{...scene1},
	{...scene2},
	{...scene3},
	{...scene4},
	{...scene5},
	{...scene6},
];

export default scenes;

 
