import React, { useEffect, useState, useRef, useCallback } from 'react';
import styled from 'styled-components';
import { proportional } from 'util/proportional';
import { InformationIcon } from 'assets/icons';
import { motion } from 'framer-motion';
import { useGameContext } from 'context/gameContext';
import Title from '../text/Title';
import Button from 'components/Button';

const Wrap = styled.div`
	position: absolute;
	display: flex;
	justify-content: center;
	width: 100%;
`;

const Blockinput = styled.div`
	position: absolute;
	background-color: black;
	opacity: 0.7;
	height: 100vw;
	width: 100vw;
	z-index: 1;
`;

const Panel = styled(motion.div).attrs({paddingLeft: [40, 40], paddingRight: [40, 40], paddingBottom: [10, 20], paddingTop: [10, 20], width:[,1024], marginTop:[,50]})`

	${proportional};
	position: relative;
	background-color: white;
	border-radius: 10px;
	text-align: left;
	color: black;

	z-index: 6;

	pointer-events: none;

`;

const Icon = styled.div.attrs({height:[,60], width:[,60]})`

	${proportional}

	background-image: url(${InformationIcon});
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;

	position: absolute;
	left:0;
	top:0;

	transform: translate(-40%, -40%);

`;

const NewButton = styled.div`
	position: absolute;
	right: 0;
	margin-right: 10%;
	bottom: 10;
	pointer-events: all;
`;

const notifications = [
	{
		text: 'no notification has been set',
		blockinput: false,
	},
	{
		text: 'Bij een bezoek aan een klant moet je eerst altijd bellen om je komst aan te kondigen. Hier heb je je tablet voor nodig. Die zit in het dashboard. Helaas zit deze op slot. Zoek de sleutel om verder te kunnen. Kijk rond door te slepen met je muis. Klik op de groene cirkel om interactie te starten met een object.',
		blockinput: false,
	},
	{
		blockinput: false,
		text:'Je kunt dit object vrijuit roteren en het zo van alle kanten bekijken. Klik en sleep om het object te draaien. Ook kun je klikken op de groene \'interactie punten\' om acties uit te voeren.'
	},
	{
		blockinput: false,
		text:'Zorg ervoor dat je netjes aan het werk kunt. Klik op de groene punten om de rommel op te ruimen.'
	},
	{
		blockinput: false,
		text:'Heb je alles gedaan? Klik op het kruisje rechtsboven om terug te gaan naar de game. In het spel beweeg je door verschillende ruimtes. Je ziet vanaf elke plek andere items. Let op: Vergeet niet goed om je heen te kijken. Alleen dan kan je de hoogste score behalen! Neem plaats op de bijrijderstoel door op het ‘locatie’ icoon te klikken.'
	},
	{
		blockinput: false,
		text:'Heel goed, je bus is opgeruimd. Het is belangrijk dat je netjes begint en eindigt bij de klant. Laat je vuilnis niet achter en zorg ervoor dat er geen gevoelige informatie in je bus ligt. Jij bent het gezicht van KPN!'
	},
	{
		text: 'Reageer op Robèrt door op een reactie te klikken.',
		blockinput: false

	},
	{
		blockinput: false,
		text: 'Meestal zal je meerdere opties hebben. Gebaseerd op je keuze zal je een eindscore krijgen. Je hebt beperkt de tijd om een keuze te maken, dus let goed op de timer.'
	},

	// Hal

	{
		text:'Soms kun je in het spel zelf een gesprek beginnen met Robèrt. Het icoontje linksonder wordt dan groen met een uitroepteken. ',
		blockinput: false
	},
	{
		text: 'Vergeet niet dat je zelf een gesprek met Robért kan starten. Het icoontje links onderin is dan groen en je hoort een geluidje. Ga het gesprek met de klant aan om punten te scoren! ',
		blockinput: true,
	}
];

const AnimatedPanel = ({notificationText, activeText, closing, callBack}: any) => {

	const {onSetNotificationText} = useGameContext();

	return (	<>	
		{notifications[activeText].blockinput && <Blockinput/>}				
		<Panel onAnimationComplete={() => closing && callBack()} initial={{y: -200}} animate={{y: closing ? -200 : 0}}  transition={{type: 'spring', stiffness: 100 }}
		>	
			<Icon/>
			<Title variant="p2">{notificationText && notifications[activeText].text}</Title>
			{notifications[activeText].blockinput && <NewButton><Button onClick={() => onSetNotificationText && onSetNotificationText(0)}>Sluit notificatie</Button></NewButton>}
		</Panel></>	
		
	);
};


const NotificationPanel = () => {

	const {notificationText, onSetNotificationText, notificationActive, gameStarted} = useGameContext();
	const [closing, setClosing] = useState<boolean>(false);
	const [activeText, setActiveText] = useState<number>(1);
	const [queuedText, setQueuedText] = useState<number>(0);
	const [textOpacity, setTextOpacity] = useState<number>(1);

	const callBack = useCallback(() => {
		if(notificationText) {
			setActiveText(notificationText - 1);
			setClosing(false);
		}
	}, [notificationText]);

	useEffect(() => {
		if(notificationActive && notificationText && (notificationText -1) !== activeText) {
			setClosing(true);
		} else {
			notificationText && setActiveText(notificationText - 1);
		}
	}, [notificationText, notificationActive]);


	return (
		<Wrap>  {notificationActive && gameStarted && activeText && (
			<AnimatedPanel callBack={callBack} closing={closing} key={activeText} notificationText={notificationText} activeText={activeText}/>
		)}
		</Wrap>
	);
};

export default NotificationPanel;