import { useGLTF } from '@react-three/drei';
import {  Lade } from 'assets/models';
import InteractivePoints from 'components/InteractivePoints';
import Model from 'components/Model';
import { useGameContext } from 'context/gameContext';
import React, { Suspense, useEffect } from 'react';

useGLTF.preload(Lade);

const scale = 1.5;

const LadeScene = () => { 
	const {currentModelData, onAddActiveAnimation, onSetActiveLayer, activeAnimations, activeModelLayer, onStoryEvent} = useGameContext();

	const onInteractivePointClick = (point) => {
		switch(point.name){
		case '01':
			onAddActiveAnimation && onAddActiveAnimation({nodeName: 'KeukenKast_La', animationName:'keukenla_action'});
			onSetActiveLayer && onSetActiveLayer(2);
			break;
		case '02':
			onStoryEvent && onStoryEvent({type:'openDialogue', param: 'scene_05_requested_03'});
			onSetActiveLayer && onSetActiveLayer(3);
			break;
		}
	};

	useEffect(() => {
		if(activeAnimations && activeAnimations.length === 0){
			switch(activeModelLayer){

			case 3:
				// onSetActiveLayer && onSetActiveLayer(4);
				break;
			}
		}
	}, [activeAnimations]);

	return (
		<>
			<Suspense fallback={null}>
				{currentModelData && <Model customScale={scale}/>}
				{currentModelData && <InteractivePoints onInteractivePointClick={(name: string) => onInteractivePointClick(name)} customModelScale={scale} />}
			</Suspense>
		</>
	);
};

export default LadeScene;
