import { moveCamera, resetCamera } from './CameraControls';
import PubSub from 'pubsub-js';
// Hotspot callbacks


const setScene = (id) => {
	PubSub.publish('HOTSPOT', id);
};

function onHotspotClick(event){
	if(event.type === 'scene') {
		moveCamera(event, () => setScene(event.sceneId));
	} else {
		moveCamera(event, resetCamera);
	}


}

const onActionRequested = (action) => {
	switch(action.label){
	case 'changeScene':
		PubSub.publish('CHANGE SCENE', action.value);
		break;
	case 'navigation':
		PubSub.publish('NAVIGATION', action.value);

		break;
	}
};


function onNavHotspotClick(event){
	onActionRequested(event.action);
	// PubSub.publish('NAVIGATION', event);
}

// Hotspot creation functions

const createHotspot = function(props, args){
	props.addEventListener('click', function(){onHotspotClick(args);});
	const span = document.createElement('span');
	span.innerHTML = args.text;
	props.appendChild(span);
}; 

const createNavHotspot = function(props, args){
	props.addEventListener('click', function(){onNavHotspotClick(args);});
};

const NavHotspotConfig = {
	'cssClass': 'custom-nav-hotspot',
	'createTooltipFunc': createNavHotspot,
};

const HotspotConfig = {
	'cssClass': 'custom-hotspot',
	'createTooltipFunc': createHotspot,
};

function getHotspots(customHotspots){

	const newHotspots = [customHotspots.length];

	for(let i = 0; i < customHotspots.length; i++){
		switch(customHotspots[i].type){
		case 'scene':
			newHotspots[i] = {
				...NavHotspotConfig,
				...customHotspots[i]
			};
			break;
		case 'info':
			newHotspots[i] = {
				...HotspotConfig,
				...customHotspots[i]
			};
			break;
		}

		const hotspotArgs = {
			'pitch' : newHotspots[i].pitch,
			'yaw': newHotspots[i].yaw
		};

		newHotspots[i].createTooltipArgs = {
			...newHotspots[i].createTooltipArgs,
			...hotspotArgs
		};
	}
	return newHotspots;
}

export { createHotspot, createNavHotspot, getHotspots };