import { useSpring } from '@react-spring/core';
import { useGLTF, Html } from '@react-three/drei';
import { Speedtest } from 'assets/icons';
import { Tablet } from 'assets/models';
import Button from 'components/Button';
import InteractivePoints from 'components/InteractivePoints';
import Model from 'components/Model';
import { useGameContext } from 'context/gameContext';
import React, { Suspense, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

useGLTF.preload(Tablet);

const scale = 7;

const WifiIcon = styled.div`
	background-image: url(${Speedtest});
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;

	height:100%;

`;

const Wrapper = styled.div`
	background-color: #1A1B2F;

	display: flex;
	flex-direction: column;

	justify-content: center;

	width: 419px;
	height: 256px;

	padding: 15px;
	user-select: none;
	border-radius: 5px;
`;

const TabletButton = styled.button`

	background-color: #009900;
	color:black;
	padding: 7px 70px 7px 70px;
	border-radius: 1em;
	font-size: 1.3rem;
	margin-bottom: 1rem;

	border: none;

	&:disabled{
		background-color: grey;
	}

	&:hover, :focus{
		color:white;
	}

	cursor: pointer;

`;
const TabletSceneWerkkamer = () => { 
	const {currentModelData, modelIntroDone, onUpdateScene, onUpdateActiveModel, setCloseButtonActive, closeButtonActive, onSetActiveLayer, onStoryEvent, activeModelLayer ,onSetNotificationText} = useGameContext();

	const onInteractivePointClick = (point) => {
		switch(point.name){
		case '01':
		}
	};

	//#region Tablet UI Logic

	const [clicked, setClicked] = useState<boolean>(false);

	const clickHandler = () => {
		setClicked(true);
		onSetActiveLayer && onSetActiveLayer(2);
		onStoryEvent && onStoryEvent({type:'setRequestedNarrative', param:'scene_03_requested_01'});
	};

	useEffect(() => {
		if(closeButtonActive)
			setCloseButtonActive && setCloseButtonActive(false);
	}, []);

	//#endregion

	return (
		<>
			<Suspense fallback={null}>
				{modelIntroDone && 
				<Html
					as='div'
					distanceFactor={2}
					transform
					occlude
					position={[0,0,0.035]}
					scale={1}
				>
					<Wrapper>
						{!clicked && activeModelLayer === 1? 

							<TabletButton onClick={clickHandler}>Start Speedtest</TabletButton>:
							<WifiIcon/>
							
						}
						
					</Wrapper>
				</Html>
				}	
				{currentModelData && <Model customScale={scale}/>}
				{currentModelData && <InteractivePoints onInteractivePointClick={(name: string) => onInteractivePointClick(name)} customModelScale={scale}/>}
			</Suspense>
		</>
	);
};

export default TabletSceneWerkkamer;
