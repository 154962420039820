import { DialogueIcon } from 'assets/icons';
import React from 'react';
import styled from 'styled-components';
import { proportional } from 'util/proportional';


const WindowStyle = styled.div.attrs({marginLeft:[,60], marginTop:[,50], marginBottom:[,50], paddingLeft:[,60], paddingRight: [,50]})`

    ${proportional}

    position: relative;

    background-color: white;
    border-radius: 10px;

    color: black;
    
    overflow-y: auto;
    overflow-x: hidden;
    text-align: left;
`;

const Icon = styled.div.attrs({marginLeft: [,60], marginTop:[,110], width:[,120], height:[,120]})`

        ${proportional}

        position: absolute;

        left:0;
        top:0;

        background-image: url(${DialogueIcon});
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        z-index: 1;
`;

const Content = styled.div.attrs({})`
`;

export interface windowProps {
    children: React.ReactNode
    iconEnabled?: boolean;
}

const Window = ({children, iconEnabled = false}:windowProps)=> {
	return (
		<>
			{iconEnabled && <Icon/> }
			<WindowStyle>
				<Content>
					{children}
				</Content>
			</WindowStyle>
		</>
	);
};

export default Window;