import React from 'react';
import styled from 'styled-components';

const Backdrop = styled.div`
    position: absolute;
    left:0;
    top:0;
    height: 100vh;
    width: 100vw;
    backdrop-filter: blur(2px);

    opacity: 0.9;
    `;

const ThreeBackdrop = () => {
	return (
		<div>
			<Backdrop/>
		</div>
	);
};

export default ThreeBackdrop;