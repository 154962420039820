import { render_hal_02_p2 } from 'assets/renders';
import { getHotspots } from '../Hotspots';
import getConfig from './universalConfig';

const customConfig = {
	imageSource: render_hal_02_p2,
};

// action: {label: changeScene | changeSceneFragment, value: string | number};

let customHotspots = [
	{
		'pitch': -9.264,
		'yaw': -76.003,
		'type': 'info',
		'createTooltipArgs': {
			'text': 'meterkast',
			'type': 'scene',
			'sceneId': 'meterkast',
		}
	},	
	{
		'pitch': -18.2919,
		'yaw': 179.974,
		'type': 'scene',
		'createTooltipArgs': {
			'text': '',
			action: {
				'label': 'navigation',
				value: 2,
			}
		}
	},
];

// Asign standard hotspot config
customHotspots = getHotspots(customHotspots);
customConfig['hotSpots'] = customHotspots;

const Scene = {
	sceneId: 'hal_02_p2',
	config: getConfig(customConfig),
	startingPitch: -3.865,
	startingYaw: -179.290,
};

export default Scene;