import { render_werkkamer_02_p1 } from 'assets/renders';
import { getHotspots } from '../Hotspots';
import getConfig from './universalConfig';

const customConfig = {
	imageSource: render_werkkamer_02_p1,
};

// action: {label: changeScene | changeSceneFragment, value: string | number};

let customHotspots = [
	{
		pitch: -19.1477,
		yaw: 115.05499,
		'type': 'scene',
		'createTooltipArgs': {
			'text': '',
			action: {
				'label': 'changeScene',
				value: 5,
			}
		}
	},

];

// Asign standard hotspot config
customHotspots = getHotspots(customHotspots);
customConfig['hotSpots'] = customHotspots;

const Scene = {
	sceneId: 'werkkamer_04_p1',
	config: getConfig(customConfig),
	startingPitch: -16.290,
	startingYaw: 114.965,
};

export default Scene;

