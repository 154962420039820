import { useGLTF } from '@react-three/drei';
import { Sleutel } from 'assets/models';
import InteractivePoints from 'components/InteractivePoints';
import Model from 'components/Model';
import { useGameContext } from 'context/gameContext';
import React, { Suspense, useEffect } from 'react';

useGLTF.preload(Sleutel);

const scale = 6;

const SleutelScene = () => { 
	const {currentModelData, onAddActiveAnimation, onSetActiveLayer, setNotificationActive, queueStoryEvent, queueFragmentSwap, onStoryEvent, activeModelLayer, activeAnimations, onUpdateActiveModel} = useGameContext();

	const onInteractivePointClick = (point) => {
		switch(point.name){
		case '01':
			onAddActiveAnimation && onAddActiveAnimation({nodeName: 'autosleutel', animationName:'key_grab'});
			// queueStoryEvent && queueStoryEvent({type: 'openModel', param: 'dashboard'});
			onSetActiveLayer && onSetActiveLayer(3);
		}
	};

	useEffect(() => {
		setNotificationActive && setNotificationActive(false);
		if(activeAnimations && activeAnimations.length === 0){
			switch(activeModelLayer){

			case 3:	
				onUpdateActiveModel && onUpdateActiveModel(null);
				onStoryEvent && onStoryEvent({type:'swapFragment', param: 4});
				break;
			}
		}
	}, [activeAnimations]);

	return (
		<>
			<Suspense fallback={null}>
				{currentModelData && <Model customScale={scale}/>}
				{currentModelData && <InteractivePoints onInteractivePointClick={(name: string) => onInteractivePointClick(name)} customModelScale={scale}/>}
			</Suspense>
		</>
	);
};

export default SleutelScene;
