import { useGLTF } from '@react-three/drei';
import { Rommel2 } from 'assets/models';
import InteractivePoints from 'components/InteractivePoints';
import Model from 'components/Model';
import { useGameContext } from 'context/gameContext';
import React, { Suspense, useEffect } from 'react';

useGLTF.preload(Rommel2);

const scale = 1.2;

const Rommel2Scene = () => { 
	const {currentModelData, onAddActiveAnimation,activeModelLayer, onUpdateActiveModel, activeAnimations, onSetNotificationText, setNotificationActive,onSetActiveLayer, onStoryEvent} = useGameContext();

	useEffect(() => {
		setNotificationActive && setNotificationActive(false);
	}, []);

	const onInteractivePointClick = (point) => {
		switch(point.name){
		case '02':
			onAddActiveAnimation && onAddActiveAnimation({nodeName: 'troep_01', animationName:'troep_01_bijrijder'});
			onSetActiveLayer && onSetActiveLayer(3);
			break;	

		case '03':
			onAddActiveAnimation && onAddActiveAnimation({nodeName: 'troep_02', animationName:'troep_02_bijrijder'});
			onSetActiveLayer && onSetActiveLayer(4);

			break;			
		case '04':
			onAddActiveAnimation && onAddActiveAnimation({nodeName: 'troep_03', animationName:'troep_03_bijrijder'});
			onSetActiveLayer && onSetActiveLayer(5);
			onSetNotificationText && onSetNotificationText(6);

			break;			
		case '01':
			onAddActiveAnimation && onAddActiveAnimation({nodeName: 'vuilniszak', animationName:'zak_fade_in'});
			onSetActiveLayer && onSetActiveLayer(2);

			break;	
		
		}
		
	};

	useEffect(() => {
		if(activeAnimations && activeAnimations.length === 0){
			switch(activeModelLayer){
			case 5:	
				onStoryEvent && onStoryEvent({type:'swapFragment', param: 3});
				// onUpdateActiveModel && onUpdateActiveModel(null);
				break;
			}
		}
	}, [activeAnimations]);

	return (
		<>
			<Suspense fallback={null}>
				{currentModelData && <Model customScale={scale}/>}
				{currentModelData && <InteractivePoints onInteractivePointClick={(name: string) => onInteractivePointClick(name)} customModelScale={scale}/>}
			</Suspense>
		</>
	);
};

export default Rommel2Scene;
