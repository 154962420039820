import { useGLTF } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import {  MonitorOud } from 'assets/models';
import InteractivePoints from 'components/InteractivePoints';
import Model from 'components/Model';
import { useGameContext } from 'context/gameContext';
import React, { Suspense, useEffect } from 'react';

useGLTF.preload(MonitorOud);

const scale = 4.5;

const MonitorOudScene = () => { 
	const {currentModelData,} = useGameContext();
	const { scene } = useThree();



	useEffect(() => {
		const object = scene.getObjectByName('scherm_aan' );

		if(object){
			object.visible = false;
		}else{
			setTimeout(() => {
				scene.getObjectByName('scherm_aan').visible = false;
			}, 100);
		}

	}, []);

	const onInteractivePointClick = (point) => {
		switch(point.name){
		case '01':
			// IpHandler();
			if(scene) {
				scene.getObjectByName( 'scherm_aan' ).visible = true;
			}
		}
	};

	return (
		<>
			<Suspense fallback={null}>
				{currentModelData && <Model customScale={scale}/>}
				{currentModelData && <InteractivePoints onInteractivePointClick={(name: string) => onInteractivePointClick(name)} customModelScale={scale} />}
			</Suspense>
		</>
	);
};

export default MonitorOudScene;
