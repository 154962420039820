
import { PaintingScene, PlantScene, DeurmatScene, MeterkastScene, MeterkastDichtScene, KinderjasScene, DeurScene, ThermostaatScene, HandleidingScene, KlokScene, KpnkastjeScene, Rommel1Scene, Rommel2Scene, SleutelScene, DashboardScene} from 'components/CanvasContainer/scenes';
import { Painting, Numpad, Plant, Kinderjas, Meterkast, Deurmat, Deur, Thermostaat, Klok, Handleiding, Kpnkastje, Rommel1, Rommel2, Sleutel, Dashboard, Tablet, Wifipunt, Printer, MonitorOud, MonitorNieuw, Ps5, Kast, KabelFout, Fotolijstje, Lade, DeurPrive, voetbal, Kast2, IPsign, Klok_groen, Klok_klein, Klok_rood, Ladder, Toetsenbord, Flipperkast, Fotocamera, Tv, Schilderij, hexagons1, hexagons2, hexagonkastjes, vlinders} from 'assets/models';
import WifipuntScene from 'components/CanvasContainer/scenes/Wifipunt';
import MonitorOudScene from 'components/CanvasContainer/scenes/MonitorOud';
import MonitorNieuwScene from 'components/CanvasContainer/scenes/MonitorNieuw';
import KastScene from 'components/CanvasContainer/scenes/Kast';
import KabelFoutScene from 'components/CanvasContainer/scenes/KabelFout';
import FotolijstjeScene from 'components/CanvasContainer/scenes/Fotolijstje';
import LadeScene from 'components/CanvasContainer/scenes/Lade';
import PrinterScene from 'components/CanvasContainer/scenes/Printer';
import DeurPriveScene from 'components/CanvasContainer/scenes/DeurPrive';
import Ps5Scene_01 from 'components/CanvasContainer/scenes/Ps5_01';
import Ps5Scene_02 from 'components/CanvasContainer/scenes/Ps5_02';
import NumpadSceneWoonkamer from 'components/CanvasContainer/scenes/NumpadWoonkamer';
import NumpadSceneWerkkamer from 'components/CanvasContainer/scenes/NumpadWerkkamer';
import NumpadSceneGamekamer from 'components/CanvasContainer/scenes/NumpadGamekamer';

import TabletSceneCaddy from 'components/CanvasContainer/scenes/Tablet_01';
import TabletSceneWerkkamer from 'components/CanvasContainer/scenes/Tablet_02';
import TabletSceneKeuken from 'components/CanvasContainer/scenes/Tablet_03';
import FlipperkastScene from 'components/CanvasContainer/scenes/Flipperkast';
import KlokGroenScene from 'components/CanvasContainer/scenes/Klok_groen';
import KlokroodScene from 'components/CanvasContainer/scenes/Klok_rood';
import KlokKleinScene from 'components/CanvasContainer/scenes/Klok_klein';
import LadderScene from 'components/CanvasContainer/scenes/Ladder';
import VoetbalScene from 'components/CanvasContainer/scenes/Voetbal';
import Kast2Scene from 'components/CanvasContainer/scenes/Kast2';
import IPsignScene from 'components/CanvasContainer/scenes/IPsign';
import ToetsenbordScene from 'components/CanvasContainer/scenes/Toetsenbord';
import FotocameraScene from 'components/CanvasContainer/scenes/Fotocamera';
import TvScene from 'components/CanvasContainer/scenes/Tv';
import SchilderijScene from 'components/CanvasContainer/scenes/Schilderij';
import Hexagons1Scene from 'components/CanvasContainer/scenes/hexagons1';
import Hexagons2Scene from 'components/CanvasContainer/scenes/hexagons2';
import HexagonkastjesScene from 'components/CanvasContainer/scenes/hexagonkastjes';
import VlindersScene from 'components/CanvasContainer/scenes/vlinders';

type ModelScene = 

	// General

	'painting' | 
	'numpad'|
	'deur'|

	//Caddy

	'rommel1'|
	'rommel2'|
	'sleutel'|
	'dashboard'|
	'tablet_caddy'|

	// Hal 

	'plant' | 
	'meterkast'|
	'meterkast_dicht'|
	'kinderjas'|
	'deurmat'|
	'voetbal'|
	'schilderij'|

	// Woonkamer

	'thermostaat'|
	'handleiding'|
	'tv'|
	'klok'|
	'kpnkastje'|
	'numpad_woonkamer'|
	'ladder'|
	'klok_groen'|
	'klok_rood'|
	'klok_klein'|


	// Werkkamer

	'wifipunt'|
	'printer'|
	'monitor_oud'|
	'monitor_nieuw'|
	'numpad_werkkamer'|
	'tablet_werkkamer'|
	'toetsenbord'|
	'ipsign'|
	'fotocamera'|


	// Gamekamer

	'ps5_01'|
	'ps5_02'|

	'kast'|
	'kast2'|
	'flipperkast'|
	'kabel_fout'|
	'prive_deur'|
	'numpad_gamekamer'|
	'hexagons1'|
	'hexagons2'|
	'hexagonkastjes'|
	'vlinders'|

	// Keuken
	
	'fotolijstje'|
	'tablet_keuken'|
	'lade';
	


const modelScenes: ModelScene[] = [

	// General

	'painting',
	'deur',

	// Caddy

	'rommel1',
	'rommel2',
	'sleutel',
	'dashboard',
	'tablet_caddy',

	// Hal

	'plant',
	'meterkast',
	'meterkast_dicht',
	'kinderjas',
	'deurmat',
	'voetbal',
	'schilderij',

	// Woonkamer

	'thermostaat',
	'handleiding',
	'klok',
	'kpnkastje',
	'numpad_woonkamer',
	'ladder',
	'klok_groen',
	'klok_rood',
	'klok_klein',
	'tv',

	// Werkkamer

	'wifipunt',
	'printer',
	'monitor_oud',
	'monitor_nieuw',
	'numpad_werkkamer',
	'tablet_werkkamer',
	'toetsenbord',
	'ipsign',
	'fotocamera',

	// Gamekamer

	'ps5_01',
	'ps5_02',
	'kast',
	'kast2',
	'flipperkast',
	'kabel_fout',
	'prive_deur',
	'numpad_gamekamer',
	'hexagons1',
	'hexagons2',
	'hexagonkastjes',
	'vlinders',

	// Keuken
	
	'fotolijstje',
	'tablet_keuken',
	'lade',
];

const modelComponents = {



	// General

	'painting': {label: 'painting', component: PaintingScene, model: Painting }, 
	'deur': {label: 'deur', component: DeurScene, model: Deur},

	// Caddy 

	'rommel1': {label: 'rommel1', component: Rommel1Scene , model: Rommel1 },
	'rommel2': {label: 'rommel2', component: Rommel2Scene, model: Rommel2},
	'sleutel': {label: 'sleutel', component: SleutelScene, model: Sleutel},
	'dashboard': {label: 'dashboard', component: DashboardScene, model: Dashboard},
	'tablet_caddy': {label: 'tablet_caddy', component: TabletSceneCaddy, model: Tablet},

	// Hal
	'voetbal': {label: 'voetbal', component: VoetbalScene, model: voetbal}, 
	'plant': {label: 'plant', component: PlantScene, model: Plant}, 
	'meterkast': {label: 'meterkast', component: MeterkastScene, model: Meterkast}, 
	'meterkast_dicht': {label: 'meterkast_dicht', component: MeterkastDichtScene, model: Deur}, 
	'kinderjas': {label: 'kinderjas', component: KinderjasScene, model: Kinderjas}, 
	'deurmat': {label: 'deurmat', component: DeurmatScene, model: Deurmat}, 
	'schilderij': {label: 'schilderij', component: SchilderijScene, model: Schilderij}, 


	// Woonkamer
	
	'klok_groen': {label: 'klok_groen', component: KlokGroenScene, model: Klok_groen}, 
	'klok_rood': {label: 'klok_rood', component: KlokroodScene, model: Klok_rood},
	'klok_klein': {label: 'klok_klein', component: KlokKleinScene, model: Klok_klein},
	'ladder': {label: 'ladder', component: LadderScene, model: Ladder},
	'thermostaat': {label: 'thermostaat', component: ThermostaatScene, model: Thermostaat}, 
	'handleiding': {label: 'handleiding', component: HandleidingScene, model: Handleiding}, 
	'klok': {label: 'klok', component: KlokScene, model: Klok}, 
	'kpnkastje': {label: 'kpnkastje', component: KpnkastjeScene, model: Kpnkastje}, 
	'numpad_woonkamer': {label: 'numpad_woonkamer', component: NumpadSceneWoonkamer, model: Numpad }, 
	'tv': {label: 'tv', component: TvScene, model: Tv}, 


	// Werkkamer
	'wifipunt': {label: 'wifipunt', component: WifipuntScene, model: Wifipunt}, 
	'printer': {label: 'printer', component: PrinterScene, model: Printer}, 
	'ipsign': {label: 'ipsign', component: IPsignScene, model: IPsign}, 
	'toetsenbord': {label: 'toetsenbord', component: ToetsenbordScene, model: Toetsenbord}, 
	'fotocamera': {label: 'fotocamera', component: FotocameraScene, model: Fotocamera}, 
	'monitor_oud': {label: 'monitor_oud', component: MonitorOudScene, model: MonitorOud}, 
	'monitor_nieuw': {label: 'monitor_nieuw', component: MonitorNieuwScene, model: MonitorNieuw}, 
	'numpad_werkkamer': {label: 'numpad_werkkamer', component: NumpadSceneWerkkamer, model: Numpad }, 
	'tablet_werkkamer': {label: 'tablet_werkkamer', component: TabletSceneWerkkamer, model: Tablet},



	// Gamekamer
	'ps5_01': {label: 'ps5_01', component: Ps5Scene_01, model: Ps5}, 
	'ps5_02': {label: 'ps5_02', component: Ps5Scene_02, model: Ps5}, 
	'kast': {label: 'kast', component: KastScene, model: Kast}, 
	'kast2': {label: 'kast2', component: Kast2Scene, model: Kast2}, 
	'flipperkast': {label: 'flipperkast', component: FlipperkastScene, model: Flipperkast}, 
	'kabel_fout': {label: 'kabel_fout', component: KabelFoutScene, model: KabelFout}, 
	'prive_deur': {label: 'prive_deur', component: DeurPriveScene, model: DeurPrive}, 
	'numpad_gamekamer': {label: 'numpad_gamekamer', component: NumpadSceneGamekamer, model: Numpad }, 
	'hexagons1': {label: 'hexagons1', component: Hexagons1Scene, model: hexagons1},
	'hexagons2': {label: 'hexagons2', component: Hexagons2Scene, model: hexagons2}, 
	'hexagonkastjes': {label: 'hexagonkastjes', component: HexagonkastjesScene, model: hexagonkastjes}, 
	'vlinders': {label: 'vlinders', component: VlindersScene, model: vlinders}, 

 


	// Keuken
	
	'fotolijstje': {label: 'fotolijstje', component: FotolijstjeScene, model: Fotolijstje}, 
	'tablet_keuken': {label: 'tablet_keuken', component: TabletSceneKeuken, model: Tablet},

	'lade': {label: 'lade', component: LadeScene, model: Lade}, 

};


export {modelScenes, modelComponents};
export default ModelScene;