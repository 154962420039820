import React, { useEffect, useRef, useState } from 'react';
import * as THREE from 'three';
import { a, animated, useSpring } from '@react-spring/three';
import { useGameContext } from 'context/gameContext';
import { useAnimations } from '@react-three/drei';

//region Mesh Component

const Mesh = ({node, animations, materials, animation, prepAnimationList, onRemoveActiveAnimation, scale}: any) => {

	const {activeAnimations} = useGameContext();

	const { ref, mixer, names, actions } = useAnimations(animations);  
	const [preparedActions, setPreparedActions] = useState<any>();
	
	const [animationObjects, setAnimationObjects] = useState({});
	const [animationList, setAnimationList] = useState<string[]>([]);

	const onAnimComplete = () => {
		onRemoveActiveAnimation(node.name);
	};

	useEffect(() => {
		if(animation) {
			const action = preparedActions.find((action) => action.label === animation.animation);
			action && action.reset();
			action && action.play();
		}
	}, [animation]);

	useEffect(() => {
		actions && setPreparedActions(Object.keys(actions).map((action) => { const newAction = actions[action]; if(newAction) { newAction.repetitions = 1; newAction.clampWhenFinished = true; newAction.label = action; } return newAction;  }));
	}, [actions]);

	useEffect(() => {
		names && setAnimationList(names);
	}, [names]);

	useEffect(() => {
		mixer && mixer.addEventListener('finished', onAnimComplete);
	}, [mixer]);

	const material = materials[node?.userData?.material] ? materials[node.userData.material] : materials.material;
	const childrenArray = node.children ? node.children.filter((item) => item.type === 'Mesh') : null;
	return (
		<a.mesh
			key={node.uuid} // Unique Key
			name={node.name} // Node Name
			ref={ref}
			geometry={node.geometry}
			material={material}
			rotation={node.rotation}
			position={node.position}
			scale={scale ? scale : node.scale}
		>
			{ childrenArray && childrenArray.map((child) => {
				return (
					<Mesh 
						onRemoveActiveAnimation={onRemoveActiveAnimation} 
						animation={activeAnimations && activeAnimations.find((animation) => animation.key === child.name)} 
						key={child.uuid} 
						node={child} 
						materials={materials} 
						animations={animations}
					/>
				);
			})}
		</a.mesh>
	);
};

//#endregion

//#region Model Component

const Model = (props: any) => {
	const {onUpdateInteractivePointsDisplay, setModelIntroDone, currentModelData, onFinishAnimation, activeAnimations, onRemoveActiveAnimation, onSetAnimationList, onSetAnimationObjects } = useGameContext();
	const [active, setActive] = useState(0);

	const [nodes] = useState(currentModelData && currentModelData.nodes);
	const [materials] = useState(currentModelData && currentModelData.materials);
	const [animations] = useState(currentModelData && currentModelData.animations);

	// get meshlist and prepare group
	const getMeshList = () => {
		// return Object.keys(nodes).filter((key) => nodes[key].type === 'Mesh').map((key) => nodes[key]);
		return nodes.Scene.children.filter((item) => item.type === 'Mesh');
	};

	const [meshList] = useState(() => getMeshList());
	const group = useRef<THREE.Group>();

	const checkActiveState = () => {
		if(active === 1){
			setModelIntroDone && setModelIntroDone(true);
			onUpdateInteractivePointsDisplay && onUpdateInteractivePointsDisplay(true);
		}
	};

	const { spring } = useSpring({
		spring: active,
		config: { mass: 5, tension: 400, friction: 50, precision: 0.0001 },
		onRest: checkActiveState,
	});
	
	const scale = spring.to([0.5, 1], [0.5, 1]);

	const prepAnimationList = (nodeName, names) => {
		nodeName;
	};

	const removeActiveAnimation = (nodeName) => {
		onRemoveActiveAnimation && onRemoveActiveAnimation(nodeName);
	};

	useEffect(() => {		
		setTimeout(() => {
			setActive(Number(!active));
		}, 200);

		return () => {
			onUpdateInteractivePointsDisplay && onUpdateInteractivePointsDisplay(false);
		};
	}, []);
	
	return <group ref={group} {...props} dispose={null} scale={props.customScale? props.customScale : 1} >
		{ meshList && meshList.map((node, index) => {
			return(
				<Mesh
					key={node.uuid} 
					onRemoveActiveAnimation={removeActiveAnimation} 
					animation={activeAnimations && activeAnimations.find((animation) => animation.key === node.name)} 
					node={node} 
					materials={materials} 
					animations={animations}
					scale={scale}
				/>
			);
		})}
	</group>;
};

//#endregion

export default Model;


// Old Code

// Old Render Return of Model

{/* {meshList && meshList.map((node, index) => {
	return (
		<Mesh 
			onRemoveActiveAnimation={removeActiveAnimation} 
			animation={activeAnimations && activeAnimations.find((animation) => animation.key === node.name)} 
			key={node.name} 
			node={node} 
			materials={materials} 
			animations={animations}
		/>
	);
})} */}