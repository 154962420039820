import { sceneProps } from 'data';

const Scene: sceneProps = {
	id: 2,
	story: {
		narrative: {
			'main': ['scene_01_main_01'],
			'subScenes': [],
			'requested': [
				'scene_01_requested_01', 
				'scene_01_requested_02', 
				'scene_01_requested_03',
				'scene_01_requested_04', 
				'scene_01_requested_05', 
				'scene_01_requested_06',
				'scene_01_requested_07', 
				'scene_01_requested_08', 
				'scene_01_requested_09',
				'scene_01_requested_10',
				'scene_01_requested_11',
				'scene_01_automatic_01',
				'scene_01_automatic_02',
				'scene_01_automatic_03'
			]
		},
	},
	pannellumData: [
		'hal_01_p1',
		'hal_01_p2',
		'hal_02_p1',
		'hal_02_p2',
		'hal_03_p2'
	]
};

export default Scene;