import { useAnimations, useGLTF } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import { Kpnkastje } from 'assets/models';
import InteractivePoints from 'components/InteractivePoints';
import Model from 'components/Model';
import { useAudioContext } from 'context/AudioContext';
import { useGameContext } from 'context/gameContext';
import React, { Suspense, useEffect, useRef } from 'react';
import * as THREE from 'three';
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader';

useGLTF.preload(Kpnkastje);

const scale = 6.6;

const KpnkastjeScene = () => { 
	const {currentModelData, onAddActiveAnimation,onUpdateActiveModel, onSetActiveLayer, closeButtonActive, setCloseButtonActive,  activeAnimations, activeModelLayer, onStoryEvent, queueStoryEvent} = useGameContext();
	const { scene } = useThree();

	const onInteractivePointClick = (point) => {
		switch(point.name){
		case '01':
			onAddActiveAnimation && onAddActiveAnimation({nodeName: 'RJ45_fout', animationName:'kabel_fout_uit'});
			onSetActiveLayer && onSetActiveLayer(2);
			break;
		case '02':
			onAddActiveAnimation && onAddActiveAnimation({nodeName: 'RJ45_fout', animationName:'kabel_fout_zoom'});
			onAddActiveAnimation && onAddActiveAnimation({nodeName: 'RJ45_kop', animationName:'kabel_fout_kop'});
			onAddActiveAnimation && onAddActiveAnimation({nodeName: 'KPN_TV_Ontvanger', animationName:'kast_down'});
			// onStoryEvent && onStoryEvent({type:'openDialogue', param:'scene_02_requested_09'});
			onStoryEvent && onStoryEvent({type:'setRequestedNarrative', param:'scene_02_requested_09'});

			onSetActiveLayer && onSetActiveLayer(16);
			break;
		case '03':			
			// onAddActiveAnimation && onAddActiveAnimation({nodeName: 'RJ45_fout', animationName:'scale_0'});
			// onAddActiveAnimation && onAddActiveAnimation({nodeName: 'RJ45_kop', animationName:'scale_0'});
			if(scene) {
				scene.getObjectByName( 'RJ45_fout' ).visible = false;
			}
			onAddActiveAnimation && onAddActiveAnimation({nodeName: 'KPN_TV_Ontvanger', animationName:'kast_up'});
			break;
		case '04':
			onAddActiveAnimation && onAddActiveAnimation({nodeName: 'RJ45', animationName:'kabel_goed_in'});
			setCloseButtonActive && setCloseButtonActive(true);
			onSetActiveLayer && onSetActiveLayer(6);
			onStoryEvent && onStoryEvent({type:'swapFragment', param:3});
			onStoryEvent && onStoryEvent({type:'setRequestedNarrative', param:null});
			break;
		}
	};

	useEffect(() => {
		if(closeButtonActive)
			setCloseButtonActive && setCloseButtonActive(false);
	}, []);

	useEffect(() => {
		if(activeAnimations && activeAnimations.length === 0){
			switch(activeModelLayer){

			case 2:
				break;
			case 16:	
				onSetActiveLayer && onSetActiveLayer(3);
				break;
			case 3: 
				onAddActiveAnimation && onAddActiveAnimation({nodeName: 'RJ45', animationName:'kabel_goed_fade_in'});
				onSetActiveLayer && onSetActiveLayer(4);
				break;
			case 4:	

				break;
			case 6:	
				onUpdateActiveModel && onUpdateActiveModel(null);

				break;
			}

		}
	}, [activeAnimations]);

	return (
		<>
			<Suspense fallback={null}>
				{currentModelData && <Model customScale={scale}/>}
				{/* <TestModel/> */}
				{currentModelData && <InteractivePoints onInteractivePointClick={(name: string) => onInteractivePointClick(name)} customModelScale={scale}/>}
			</Suspense>
		</>
	);
};

export default KpnkastjeScene;
