import { sceneProps } from 'data';

const Scene: sceneProps = 	{
	id: 1,
	story: {
		narrative: {
			'main': [],
			'subScenes': [],
			'requested': [
				'scene_00_requested_00',
				'scene_00_requested_01',
				'scene_00_requested_02',
				'scene_00_requested_03',
				'scene_00_requested_04',
			]
		},
	},
	pannellumData: [
		'caddy_01_p1',
		'caddy_02_p1',
		'caddy_02_p2',
		'caddy_03_p2',
		'caddy_04_p2',
		'caddy_05_p1'
	]
};

export default Scene;