const storyChunks = { 

	// Main Narratives & Sub Narratives

	'scene_01_main_01': {
		id: 'scene_01_main_01',
		narrative: {
			text: 'Ha Rene, Robèrt hier. Ik was thuis aan het werk toen de storing kwam en moet connected blijven. Ik heb zelf geen tijd om het op te lossen anders had ik het al lang zelf gedaan, you know? Er zijn je meer mensen voor geweest dus ik vraag me af of jij beter bent dan je collega’s. Ik zit trouwens nu boven, maar ik kan wel meekijken want ik heb camera’s in huis.',
			audioId: 'hal_00'
		},
		options: [
			{
				label: 'Prima hoor, ik kan u gewoon volgen via de intercom. Maar ja, niet alle monteurs zijn zo goed als ik, en goed ook dat u niet zelf heeft geprobeerd het probleem op te lossen.', 
				value: 1, 
			}, 
			{
				label: 'Geen probleem dat u boven zit, ik hoor u prima. Ik snap dat het belangrijk is en zal mijn best doen om de storing snel op te lossen zodat u weer aan het werk kunt.', 
				value: 2
			}, 
			{
				label: 'Prima hoor dat u boven zit. Jammer dat de andere monteurs niet konden helpen, ik ga aan de slag.', 
				value: 3
			}
		],
	},

	'scene_01_main_02': {
		id: 'scene_01_main_02',
		narrative: {
			text: 'reactie en einde',
			// audioId: 'test'
		},
	},

	// 'scene_01_sub_01': {
	// 	id: 'scene_01_sub_01',
	// 	narrative: {
	// 		text: 'Wat zeg je? Ik ben half doof',
	// 		audioId: 1
	// 	},
	// 	options: [
	// 		{label: '...', value: 1, storyId: 'scene_01_sub_02'}, 
	// 		{label: 'of je worst lust', value: 2}, 
	// 		{label: 'das naar voor je', value: 3}],
	// },
	// 'scene_01_sub_02': {
	// 	id: 'scene_01_sub_02',
	// 	narrative: {
	// 		text: 'Alleen als jij mijn puzzle weet op te lossen, ofzo',
	// 		audioId: 1
	// 	},
	// 	nextStoryId: 'scene_01_requested_01'
	// },

	// Automatic Narratives 

	'scene_01_automatic_01': {
		id: 'scene_01_automatic_01',
		narrative: {
			text: 'Die meterkast kijk ik eigenlijk nooit naar, dus geen idee wat daarin zit.',
			audioId: 'hal_01'
		},
		storyEvent: {
			type:'setRequestedNarrative',
			param: 'scene_01_requested_01'
		}
	},

	// Ontbreek Reacties

	'scene_01_automatic_02': {
		id: 'scene_01_automatic_02',
		narrative: {
			text: '...?',
		},
		options: [
			{
				label: 'Ik zie dat twee kabels hier in de poorten verwisseld zijn. Ik heb ze voor u omgewisseld, dan doet in ieder geval het internet het weer.', 
				value: 1, 
				//storyId: 'scene_01_automatic_03'

			}, 
			{
				label: 'De kabels zitten verkeerd om in de poorten, maar ik heb ze omgewisseld dus nu doet alles het weer.', 
				value: 2,
				//storyId: 'scene_01_automatic_03'


			}, 
			{
				label: 'Deze twee kabels zitten verkeerd in de poorten, wat slecht dat mijn collega dat niet gezien heeft. Blauw moet in blauw en wit in wit. Ik heb ze goed erin gedaan dus nu doet alles het weer.', 
				value: 3,
				//storyId: 'scene_01_automatic_03'

			}
		],
	},
	'scene_01_automatic_03': {
		id: 'scene_01_automatic_03',
		narrative: {
			text: 'ontbrekende reactie',
			audioId: 1
		},
	},

	// Requested Narrative 1

	'scene_01_requested_01':{
		id: 'scene_01_requested_01',
		narrative: {
			text: '...?',
			audioId: 1
		},
		options: [
			{
				label: 'Een volle meterkast kan storingen of zelfs branden veroorzaken. Ik zie een aantal losse kabels en ik maak het graag weer netjes. Ik ben hier een kwartier mee bezig en dit kost €15, maar levert u geen stress voor de toekomst op.', 
				value: 1, 
				storyId: 'scene_01_requested_02'
			}, 
			{
				label: 'Zoveel kabels bij elkaar zijn heel gevaarlijk en kunnen storingen en branden veroorzaken. Voor €15 leg ik alles weer aan op de goede plek. Zal ik dat voor u in orde maken?', 
				value: 2,
				storyId: 'scene_01_requested_03'

			}, 
			{
				label: 'Wat heeft u veel spullen in deze kast, dit is echt levensgevaarlijk dus laat mij deze rommel maar opruimen. Dat ik er langer mee bezig zijn betekent wel dat het €15 extra kost.', 
				value: 3,
				storyId: 'scene_01_requested_04'

			}
		],
	},

	//#region scene_01_requested_01 options

	'scene_01_requested_02': {
		id: 'scene_01_requested_02',
		narrative: {
			text: 'Oh ja dat is misschien wel handig. All right, doe maar.',
			audioId: 'hal_02'
		},
	},

	'scene_01_requested_03': {
		id: 'scene_01_requested_03',
		narrative: {
			text: 'Hmm zo gevaarlijk kan het niet zijn toch? Maar all right, doe maar.',
			audioId: 'hal_03'

		},
	},

	'scene_01_requested_04': {
		id: 'scene_01_requested_04',
		narrative: {
			text: 'Nou zo rommelig is dit niet! Wat een geld voor een beetje opruimen, maar ik ben niet zo moeilijk dus all right doe maar.',
			audioId: 'hal_04'
		},
	},

	//#endregion

	// Requested Narrative 2 

	'scene_01_requested_05':{
		id: 'scene_01_requested_05',
		narrative: {
			text: '...?',
			audioId: 1
		},
		options: [
			{
				label: 'Ik heb een kabel gevonden die kapot is omdat u waarschijnlijk muizen in huis heeft. Deze moet vervangen worden en dat kan ik nu doen, maar dat zorgt wel voor extra kosten. ', 
				value: 1, 
				storyId: 'scene_01_requested_06'
			}, 
			{
				label: 'Bah, hier heeft een muis aan gezeten. Deze kabel werkt echt niet meer en moet vervangen worden. Gelukkig voor u kan ik deze ter plekke vervangen, maar dat kost wel weer extra. ', 
				value: 2,
				storyId: 'scene_01_requested_07'

			}, 
			{
				label: 'Ik ben bang dat een reden voor de storing deze doorgebeten kabel kan zijn. Deze kabels horen niet standaard in het pakket waar mijn service onder valt, maar ik heb gelukkig een nieuwe in de bus liggen. Zal ik deze voor een kleine meerprijs vervangen? ', 
				value: 3,
				storyId: 'scene_01_requested_08'
			}
		],
	},

	//#region scene_01_requested_05 options

	'scene_01_requested_06': {
		id: 'scene_01_requested_06',
		narrative: {
			text: 'Muizen? Dat is crazy, ik heb geen ongedierte in huis. Maar goed als je het nu kunt vervangen, prima. ',
			audioId: 'hal_06'
		},
	},
	
	'scene_01_requested_07': {
		id: 'scene_01_requested_07',
		narrative: {
			text: 'Nog meer extra kosten? Daar heb ik geen zin in, ik doe het zelf wel.',
			audioId: 'hal_07'
		},
	},
	
	'scene_01_requested_08': {
		id: 'scene_01_requested_08',
		narrative: {
			text: 'Oh handig dat je die bij je hebt. Prima, doe maar.',
			audioId: 'hal_08'
		},
	},
	'scene_01_requested_09': {
		id: 'scene_01_requested_09',
		narrative: {
			text: 'Hé dude, blijf je even van de jas van mijn dochter af?',
			audioId: 'hal_09'
		},
	},
	
	'scene_01_requested_10': {
		id: 'scene_01_requested_10',
		narrative: {
			text: 'Deze is nog locked natuurlijk. Eerst even die key vinden.',
			audioId: 'hal_10'
		},
	},
	
	'scene_01_requested_11': {
		id: 'scene_01_requested_11',
		narrative: {
			text: 'Art is echt mijn ding weet je. Maar hier ligt geen key.',
			audioId: 'hal_11'
		},
	},
	
	//#endregion

	
};

export default storyChunks;