import { Page, Text, Document, StyleSheet, View } from '@react-pdf/renderer';

const styles = StyleSheet.create({
	page: {
		paddingTop: 35,
		paddingBottom: 65,
		paddingHorizontal: 35,
	},
	title: {
		fontSize: 24,
		textAlign: 'center',
		marginBottom: 40,
	},
	header: {
		fontSize: 18,
		textAlign: 'left',
		marginBottom: 20,
		marginTop: 20,
	},
	bodyWrong: {
		fontSize: 14,
		fontFamily: 'Times-Roman',
		marginBottom: 20,
		color: 'red',
	},
	bodyCorrect: {
		fontSize: 14,
		fontFamily: 'Times-Roman',
		marginBottom: 20,
		color: 'green',
	},
	bodyFeedback:{
		fontSize: 14,
		fontFamily: 'Times-Roman',
		marginBottom: 20,
		color: 'black',
	},
	pageNumber: {
		position: 'absolute',
		fontSize: 12,
		bottom: 30,
		left: 0,
		right: 0,
		textAlign: 'center',
		color: 'grey',
	},
});

const PDF = ({pdfData}: any) => {

	return(
		<Document>
			<Page size="A4" style={styles.page}>
				<Text style={styles.title}>KPN Escape Game - Resultaat</Text>
				{ pdfData && pdfData.themeContent.map((theme, index) => {
					return(
						// Left at type mismatch error
						<View key={`${theme.questions}`}>
							<Text style={styles.header}> {theme.label} ({pdfData.themeScoring[index].label})</Text>  
							{ pdfData.playerChoice[index] && pdfData.playerChoice[index].map((choice, iindex) =>
								<>
									{ choice.empty ? 
										<>
											<Text style={choice.correct ? styles.bodyCorrect : styles.bodyWrong} key={`${choice}-${iindex}-${index}-choice`}> Geen keuze gemaakt </Text>
											<Text style={styles.bodyFeedback} key={`${choice}-${iindex}-${index}-feedback`}>{theme.questions[iindex].feedbackEmpty? theme.questions[iindex].feedbackEmpty : 'N.V.T. '} </Text>
										</>
										:
										<>
											<Text style={choice.correct ? styles.bodyCorrect : styles.bodyWrong} key={`${choice}-${iindex}-${index}-choice`}> {choice.text} ({choice.correct ? 'correct' : 'niet correct'}) </Text>
											<Text style={styles.bodyFeedback} key={`${choice}-${iindex}-${index}-feedback`}> {theme.questions[iindex].feedback[choice.choice]} </Text>
										</>
									}
								</>	
							)}
						</View>
					);
				})}
				<Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
					`${pageNumber} / ${totalPages}`
				)} fixed />
			</Page>

		</Document>
	);
};

export default PDF;