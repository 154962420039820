import scene2Story from './scene_2';
import scene3Story from './scene_3';
import scene1Story from './scene_1';
import scene4Story from './scene_4';
import scene5Story from './scene_5';
import scene6Story from './scene_6';


const story = {
	...scene1Story,
	...scene2Story,
	...scene3Story,
	...scene4Story,
	...scene5Story,
	...scene6Story,
};

export default story;