import * as THREE from 'three';
import React, { useEffect, useRef, useState } from 'react';
import { useLoader } from '@react-three/fiber';
import { EmptyIcon } from 'assets/icons';

interface spriteInterface {
    onClickHandler: clickEventWithParam,
    pointData: any
    position?: number[]
	customModelScale?: number
	customBaseScale?: number

}

type clickEventWithParam = (param: any) => void;

const Sprite = ({onClickHandler, pointData, position = [0,0,0], customModelScale, customBaseScale}: spriteInterface) => {
	
	const sprite = useRef();
	const texture = useLoader(THREE.TextureLoader, EmptyIcon);
	const userClick = (e) => {
		onClickHandler(pointData);
		e.stopPropagation();
	};
	const baseScale = customBaseScale ? customBaseScale : 0.1;
	const scale = customModelScale ? baseScale * (1 / customModelScale) : baseScale;

	return (
		<sprite 
			scale={scale}
			position={position}
			onClick={userClick}
		>
			<spriteMaterial map={texture} />

		</sprite>
	);

};

// interface interactivePointInterface {
//     onClickHandler: clickEventWithParam,
//     pointId: string
// }

const InteractivePoint = ({...props}: spriteInterface) => { 

	return (
		<>
			<Sprite
				{...props}
			/>
		</>
	);
    
};

export default InteractivePoint;


