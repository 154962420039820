import { render_caddy_03_p2 } from 'assets/renders';
import { getHotspots } from '../Hotspots';
import getConfig from './universalConfig';

const customConfig = {
	imageSource: render_caddy_03_p2,
};

// action: {label: changeScene | changeSceneFragment, value: string | number};

let customHotspots = [
	{
		'pitch': -48.054,
		'yaw': 3.346,
		'type': 'info',
		'createTooltipArgs': {
			'text': 'sleutel',
			'type': 'scene',
			'sceneId': 'sleutel',
		}
	},
];

// Asign standard hotspot config
customHotspots = getHotspots(customHotspots);
customConfig['hotSpots'] = customHotspots;

const Scene = {
	sceneId: 'caddy_03_p2',
	config: getConfig(customConfig),
};

export default Scene;