import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const StaggeredText = styled(motion.span)`
    position: absolute;
    top: 0;
    left: 0;
`;

const InvisibleText = styled.span`
    opacity: 0;
`;

const RelativePositionContainer = styled.span`
    position: relative;
`;

const staggerAnimation = {
	visible: data => ({
		opacity: 1,
		x: 0,
		y: 0,
		transition: {
			delay: data[1] + data[0] * data[2],
		},
	}),
	hidden: {
		opacity: 0,
		transition: {
			when: 'afterChildren',
		},
	},
};



const SplitText = ({chars = false, words = false, text='test', baseDelay = 1, duration, readyCallBack, ready = true}: {chars?: boolean, words?: boolean, text: string, baseDelay?: number, duration?: number, readyCallBack?: (val) => void, ready?: boolean}) => {
	const [array, SetArray] = useState<any>();
	const [durationDivided, setDurationDivided] = useState<number>();
	// const [ready, setReady] = useState(false);

	useEffect(() => {
		if(chars) {
			const newArray = Array.from(text);
			SetArray(newArray);
		}

		if(words) {
			const newArray = text.split(' ');
			SetArray(newArray);
		}
		if(!chars && !words) {
			readyCallBack && readyCallBack(true);
			// setReady(true);
		}

	}, []); 

	useEffect(() => {

		if(array) {
			duration && setDurationDivided(duration / array.length);
			readyCallBack && readyCallBack(true);
			// setReady(true);
		}
	}, [array]);


	return (
		<>{array && array.map((entry, i) => <RelativePositionContainer key={`${i}-${entry}`}><InvisibleText>{words ? entry + ' ' : entry}</InvisibleText>{ready && (<StaggeredText custom={[i, !durationDivided ? baseDelay: 0, durationDivided || 0.03]} animate="visible" initial={{opacity: 0, x: -10, y: -10}} variants={{...staggerAnimation}}>{entry} {words}</StaggeredText>)}</RelativePositionContainer>)}</>
	);
};

export default SplitText;