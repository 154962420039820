import { render_hal_01_p2 } from 'assets/renders';
import { getHotspots } from '../Hotspots';
import getConfig from './universalConfig';

const customConfig = {
	imageSource: render_hal_01_p2,
};

let customHotspots = [
	{
		'pitch': -9.264,
		'yaw': -76.003,
		'type': 'info',
		'createTooltipArgs': {
			'text': 'meterkast',
			'type': 'scene',
			'sceneId': 'meterkast_dicht',
		}
	},	
	{
		'pitch': -36.2220,
		'yaw': 50.890,
		'type': 'info',
		'createTooltipArgs': {
			'text': 'plant',
			'type': 'scene',
			'sceneId': 'plant',
		}
	},
	{
		'pitch': -14.2570,
		'yaw': 92.0077,
		'type': 'info',
		'createTooltipArgs': {
			'text': 'schilderij',
			'type': 'scene',
			'sceneId': 'schilderij',
		}
	},	
	{
		'pitch': -16.1767,
		'yaw': -174.937,
		'type': 'info',
		'createTooltipArgs': {
			'text': 'deurmat',
			'type': 'scene',
			'sceneId': 'deurmat',
		}
	},
	{
		'pitch': -18.2919,
		'yaw': 179.974,
		'type': 'scene',
		'createTooltipArgs': {
			'text': '',
			action: {
				'label': 'navigation',
				value: 0,
			}
		}
	},
];

// Asign standard hotspot config
customHotspots = getHotspots(customHotspots);
customConfig['hotSpots'] = customHotspots;

const Scene = {
	sceneId: 'hal_01_p2',
	config: getConfig(customConfig),
	startingPitch: -3.865,
	startingYaw: -179.290,
};

export default Scene;