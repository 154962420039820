import { render_woonkamer_01_p1} from 'assets/renders';
import { getHotspots } from '../Hotspots';
import getConfig from './universalConfig';

const customConfig = {
	imageSource: render_woonkamer_01_p1,
};

// action: {label: changeScene | changeSceneFragment, value: string | number};

let customHotspots = [
	{
		'pitch': -13.8385,
		'yaw': 42.6233,
		'type': 'info',
		'createTooltipArgs': {
			'text': 'numpad',
			'type': 'scene',
			'sceneId': 'numpad_woonkamer',
		}
	},
	{
		'pitch': -4.540,
		'yaw': 76.288,
		'type': 'info',
		'createTooltipArgs': {
			'text': 'klok',
			'type': 'scene',
			'sceneId': 'klok',
		}
	},
	{
		'pitch': -22.4003,
		'yaw': 88.1449,
		'type': 'info',
		'createTooltipArgs': {
			'text': 'kleine koekoeksklok',
			'type': 'scene',
			'sceneId': 'klok_klein',
		}
	},
	{
		'pitch': -3.8674,
		'yaw': 117.1253,
		'type': 'info',
		'createTooltipArgs': {
			'text': 'groene koekoeksklok',
			'type': 'scene',
			'sceneId': 'klok_groen',
		}
	},
	{
		'pitch': 0.9341,
		'yaw': 129.7568,
		'type': 'info',
		'createTooltipArgs': {
			'text': 'rode koekoeksklok',
			'type': 'scene',
			'sceneId': 'klok_rood',
		}
	},
	{
		'pitch': '-21.437407542213943', 
		'yaw': '-0.4906847407560626',
		'type': 'scene',
		'createTooltipArgs': {
			'text': '',
			action: {
				'label': 'navigation',
				value: 5,
			}
		}
	},
];

// Asign standard hotspot config
customHotspots = getHotspots(customHotspots);
customConfig['hotSpots'] = customHotspots;

const Scene = {
	sceneId: 'woonkamer_03_p1',
	config: getConfig(customConfig),
	startingPitch: -15.041,
	startingYaw: 126.985,

};

export default Scene;

