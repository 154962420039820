import { Html, useGLTF } from '@react-three/drei';
import { CrossHover, Wifi } from 'assets/icons';
import {  MonitorNieuw } from 'assets/models';
import InteractivePoints from 'components/InteractivePoints';
import Model from 'components/Model';
import { useGameContext } from 'context/gameContext';
import React, { Suspense, useState } from 'react';
import styled from 'styled-components';

useGLTF.preload(MonitorNieuw);

const scale = 4.5;

const Wrapper = styled.div`
	/* background-color: pink; */

	display: flex;
	flex-direction: column;

	justify-content: center;

	width: 417px;
	height: 250px;

	padding: 15px;
	user-select: none;
`;

const TabletButton = styled.button`

	background-color: #009900;
	/* color:black */
	padding: 7px 70px 7px 70px;
	border-radius: 1em;
	/* font-family: 'KPNMetricRegular'; */
	font-size: 1.3rem;
	margin-bottom: 1rem;

	border: none;

	&:disabled{
		background-color: grey;
	}

	&:hover, :focus{
	background-color: #009100;
	}

	cursor: pointer;
`;

const WifiIcon = styled.div`
	background-image: url(${Wifi});
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;

	height:100%;

`;

const MonitorNieuwScene = () => { 
	const {currentModelData, modelIntroDone, onSetActiveLayer, activeModelLayer, setSceneFragment} = useGameContext();
	const [clicked, setClicked] = useState<boolean>(false);

	const onInteractivePointClick = (point) => {
		switch(point.name){
		case '01':
		}
	};

	const clickHandler = () => {
		setClicked(true);
		onSetActiveLayer && onSetActiveLayer(2);
		setSceneFragment && setSceneFragment(1);
	};


	return (
		<>
			<Suspense fallback={null}>
				{modelIntroDone && 
				<Html
					as='div'
					distanceFactor={2}
					transform
					occlude
					position={[0,0.12,0.12]}
					scale={1}
				>
					<Wrapper>
						{!clicked && activeModelLayer === 1 ?
							<TabletButton onClick={clickHandler}>Test Internet</TabletButton>:
							<WifiIcon/>
						}
					</Wrapper>
				</Html>
				}	
				{currentModelData && <Model customScale={scale}/>}
				{currentModelData && <InteractivePoints onInteractivePointClick={(name: string) => onInteractivePointClick(name)} customModelScale={scale} />}
			</Suspense>
		</>
	);
};

export default MonitorNieuwScene;
