
import caddy_01_p1 from './caddy_01_p1';
import caddy_02_p1 from './caddy_02_p1';
import caddy_02_p2 from './caddy_02_p2';
import caddy_03_p2 from './caddy_03_p2';
import caddy_04_p2 from './caddy_04_p2';
import caddy_05_p1 from './caddy_05_p1';

import hal_01_p1 from './hal_01_p1';
import hal_01_p2 from './hal_01_p2';
import hal_02_p1 from './hal_02_p1';
import hal_02_p2 from './hal_02_p2';
import hal_03_p2 from './hal_03_p2';

import woonkamer_01_p1 from './woonkamer_01_p1';
import woonkamer_01_p2 from './woonkamer_01_p2';
import woonkamer_02_p2 from './woonkamer_02_p2';
import woonkamer_02_p1 from './woonkamer_02_p1';
import woonkamer_03_p1 from './woonkamer_03_p1';
import woonkamer_03_p2 from './woonkamer_03_p2';
import woonkamer_04_p2 from './woonkamer_04_p2';


import werkkamer_01_p1 from './werkkamer_01_p1';
import werkkamer_02_p1 from './werkkamer_02_p1';
import werkkamer_03_p1 from './werkkamer_03_p1';
import werkkamer_03_p2 from './werkkamer_03_p2';
import werkkamer_04_p1 from './werkkamer_04_p1';


import gamekamer_01_p1 from './gamekamer_01_p1';
import gamekamer_01_p2 from './gamekamer_01_p2';
import gamekamer_02_p1 from './gamekamer_02_p1';
import gamekamer_02_p2 from './gamekamer_02_p2';
import gamekamer_03_p2 from './gamekamer_03_p2';
import gamekamer_04_p1 from './gamekamer_04_p1';
import gamekamer_04_p2 from './gamekamer_04_p2';
import gamekamer_05_p2 from './gamekamer_05_p2';


import keuken_01_p1 from './keuken_01_p1';
import keuken_02_p1 from './keuken_02_p1';
import keuken_03_p1 from './keuken_03_p1';



const sceneList = [

	{...caddy_01_p1},
	{...caddy_02_p1},
	{...caddy_02_p2},
	{...caddy_03_p2},
	{...caddy_04_p2},
	{...caddy_05_p1},

	{...hal_01_p1}, 
	{...hal_01_p2},
	{...hal_02_p1}, 
	{...hal_02_p2},
	{...hal_03_p2},

	{...woonkamer_01_p1},
	{...woonkamer_01_p2},
	{...woonkamer_02_p2},
	{...woonkamer_02_p1},
	{...woonkamer_03_p1},
	{...woonkamer_03_p2},
	{...woonkamer_04_p2},

	{...werkkamer_01_p1},
	{...werkkamer_02_p1},
	{...werkkamer_03_p1},
	{...werkkamer_03_p2},
	{...werkkamer_04_p1},

	{...gamekamer_01_p1},
	{...gamekamer_01_p2},
	{...gamekamer_02_p1},
	{...gamekamer_02_p2},
	{...gamekamer_03_p2},
	{...gamekamer_04_p1},
	{...gamekamer_04_p2},
	{...gamekamer_05_p2},


	{...keuken_01_p1},
	{...keuken_02_p1},
	{...keuken_03_p1},

	
];

export {sceneList};

export {

	caddy_01_p1,

	hal_01_p1, 
	hal_01_p2, 
	hal_02_p1, 
	hal_02_p2, 
	hal_03_p2, 
	
	woonkamer_01_p1,
	woonkamer_01_p2,
	woonkamer_02_p2



};