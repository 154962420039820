import { render_gamekamer_01_p2} from 'assets/renders';
import { getHotspots } from '../Hotspots';
import getConfig from './universalConfig';

const customConfig = {
	imageSource: render_gamekamer_01_p2,
};

// action: {label: changeScene | changeSceneFragment, value: string | number};

let customHotspots = [
	{
		'pitch': -61.155,
		'yaw': -89.3046,
		'type': 'info',
		'createTooltipArgs': {
			'text': 'spelcomputer',
			'type': 'scene',
			'sceneId': 'ps5_02',
		}
	},
];

// Asign standard hotspot config
customHotspots = getHotspots(customHotspots);
customConfig['hotSpots'] = customHotspots;

const Scene = {
	sceneId: 'gamekamer_03_p2',
	config: getConfig(customConfig),
	startingPitch: -51.63,
	startingYaw: -33.039,
};

export default Scene;

