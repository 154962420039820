import { useGLTF } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import { hexagonkastjes } from 'assets/models';
import InteractivePoints from 'components/InteractivePoints';
import Model from 'components/Model';
import { useGameContext } from 'context/gameContext';
import React, { Suspense, useEffect } from 'react';

useGLTF.preload(hexagonkastjes);

const scale = 2;

const HexagonkastjesScene = () => { 
	const {currentModelData, onAddActiveAnimation} = useGameContext();
	const { scene } = useThree();

	const onInteractivePointClick = (point) => {
		switch(point.name){
		case '01':
			if(scene) {
				scene.getObjectByName('hexplank_uit').visible = false;
				scene.getObjectByName('hexplank_aan').visible = true;
				setTimeout(() => {
					scene.getObjectByName('hexplank_uit').visible = true;
					scene.getObjectByName('hexplank_aan').visible = false;
				}, 2000);
			}
			break;
		}
	};
		
	return (
		<>
			<Suspense fallback={null}>
				{currentModelData && <Model customScale={scale}/>}
				{currentModelData && <InteractivePoints onInteractivePointClick={(name: string) => onInteractivePointClick(name)} customModelScale={scale}/>}
			</Suspense>
		</>
	);
};

export default HexagonkastjesScene;