import { sceneProps } from 'data';

const Scene: sceneProps = 	{
	id: 4,
	story: {
		narrative: {
			'main': [
				'scene_03_main_01' , 
			],
			'subScenes': [],
			'requested': [
				'scene_03_requested_01', 
				'scene_03_requested_02', 
				'scene_03_requested_03',
				'scene_03_requested_04', 
				'scene_03_requested_05', 
				'scene_03_requested_06',
				'scene_03_requested_07', 
				'scene_03_requested_08', 
				'scene_03_requested_09', 
				'scene_03_requested_10', 
				'scene_03_requested_11', 
				'scene_03_requested_12', 
			],
			'requestedAtStart': [
			],
		},
	},
	pannellumData: [
		'werkkamer_01_p1',
		'werkkamer_02_p1',
		'werkkamer_03_p1',
		'werkkamer_03_p2',
		'werkkamer_04_p1',
	]
};

export default Scene;