import { render_gamekamer_01_p2} from 'assets/renders';
import { getHotspots } from '../Hotspots';
import getConfig from './universalConfig';

const customConfig = {
	imageSource: render_gamekamer_01_p2,
};

// action: {label: changeScene | changeSceneFragment, value: string | number};

let customHotspots = [
	// {
	// 	'pitch': -61.155,
	// 	'yaw': -89.3046,
	// 	'type': 'info',
	// 	'createTooltipArgs': {
	// 		'text': 'spelcomputer',
	// 		'type': 'scene',
	// 		'sceneId': 'ps5_02',
	// 	}
	// },
	{
		'pitch': -51.63,
		'yaw': -33.039,
		'type': 'info',
		'createTooltipArgs': {
			'text': 'kast',
			'type': 'scene',
			'sceneId': 'kast',
		}
	},
	{
		'pitch': -15.71864,
		'yaw':  139.963,
		'type': 'info',
		'createTooltipArgs': {
			'text': 'Prive deur',
			'type': 'scene',
			'sceneId': 'prive_deur',
		}
	},
	{
		'pitch': -15.7996,
		'yaw': 117.665,
		'type': 'info',
		'createTooltipArgs': {
			'text': 'kast',
			'type': 'scene',
			'sceneId': 'kast2',
		}
	},
	{
		'pitch': -21.80593143072135,
		'yaw': 55.91,
		'type': 'scene',
		'createTooltipArgs': {
			'text': '',
			action: {
				'label': 'navigation',
				value: 2,
			}
		}
	},
];

// Asign standard hotspot config
customHotspots = getHotspots(customHotspots);
customConfig['hotSpots'] = customHotspots;

const Scene = {
	sceneId: 'gamekamer_02_p2',
	config: getConfig(customConfig),
	// startingPitch: -15.041,
	// startingYaw: 126.985,
};

export default Scene;

