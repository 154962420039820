import { render_werkkamer_01_p1 } from 'assets/renders';
import { getHotspots } from '../Hotspots';
import getConfig from './universalConfig';

const customConfig = {
	imageSource: render_werkkamer_01_p1,
};

// action: {label: changeScene | changeSceneFragment, value: string | number};

let customHotspots = [
	{
		'pitch': -15.9207,
		'yaw': 26.54678,
		'type': 'info',
		'createTooltipArgs': {
			'text': 'pc',
			'type': 'scene',
			'sceneId': 'monitor_nieuw',
		}
	},
	{
		'pitch': -22.52049,
		'yaw': 68.9055,
		'type': 'info',
		'createTooltipArgs': {
			'text': 'foto camera',
			'type': 'scene',
			'sceneId': 'fotocamera',
		}
	},	
	{
		'pitch': -21.29748,
		'yaw': 28.5103,
		'type': 'info',
		'createTooltipArgs': {
			'text': 'toetsenbord',
			'type': 'scene',
			'sceneId': 'toetsenbord',
		}
	},	
	{
		'pitch': -32.03450,
		'yaw': -115.52200,
		'type': 'info',
		'createTooltipArgs': {
			'text': 'monitor',
			'type': 'scene',
			'sceneId': 'monitor_oud',
		}
	},
	{
		'pitch': -19.19839,
		'yaw': -124.8140,
		'type': 'info',
		'createTooltipArgs': {
			'text': 'printer',
			'type': 'scene',
			'sceneId': 'printer',
		}
	},
	// {
	// 	'pitch': 9.6495556,
	// 	'yaw': 5.7551476,
	// 	'type': 'info',
	// 	'createTooltipArgs': {
	// 		'text': 'IP adres',
	// 		'type': 'scene',
	// 		'sceneId': 'ipsign',
	// 	}
	// },
];

// Asign standard hotspot config
customHotspots = getHotspots(customHotspots);
customConfig['hotSpots'] = customHotspots;

const Scene = {
	sceneId: 'werkkamer_01_p1',
	config: getConfig(customConfig),
	// startingPitch: -15.041,
	// startingYaw: 126.985,
};

export default Scene;

