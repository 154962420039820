import { useGLTF } from '@react-three/drei';
import { Deurmat } from 'assets/models';
import InteractivePoints from 'components/InteractivePoints';
import Model from 'components/Model';
import { useAudioContext } from 'context/AudioContext';
import { useGameContext } from 'context/gameContext';
import React, { Suspense, useEffect } from 'react';

useGLTF.preload(Deurmat);

const scale = 2;

const DeurmatScene = () => { 
	const {currentModelData, onAddActiveAnimation, onSetActiveLayer, modelIntroDone, queueStoryEvent, queueFragmentSwap} = useGameContext();
	const {playSound, playVO} = useAudioContext();

	const onInteractivePointClick = (point) => {
		switch(point.name){
		case '01':
			onAddActiveAnimation && onAddActiveAnimation({nodeName: 'Deurmat_Sleutel', animationName:'sleutel_action'});
			queueStoryEvent && queueStoryEvent({type: 'openModel', param: 'deur'});
			queueFragmentSwap && queueFragmentSwap(2);
			onSetActiveLayer && onSetActiveLayer(-1);
			break;
		}
	};

	return (
		<>
			<Suspense fallback={null}>
				{currentModelData && <Model customScale={scale}/>}
				{currentModelData && <InteractivePoints onInteractivePointClick={(name: string) => onInteractivePointClick(name)} customModelScale={scale}/>}
			</Suspense>
		</>
	);
};

export default DeurmatScene;
