import React from 'react';
import styled from 'styled-components';
import { proportional } from 'util/proportional';

const WindowStyle = styled.div.attrs({marginLeft:[,60], paddingLeft:[,50], paddingTop:[,50], paddingBottom:[,50], paddingRight: [,50]})`

    ${proportional}

    position: relative;

    background-color: white;
    border-radius: 10px;

    color: black;
    
    overflow-y: auto;
    overflow-x: hidden;
    text-align: left;
`;

const Content = styled.div.attrs({})`
`;

export interface windowProps {
    children: React.ReactNode
}

const Window2 = ({children = false}:windowProps)=> {
	return (
		<>
			
			<WindowStyle>
				<Content>
					{children}
				</Content>
			</WindowStyle>
		</>
	);
};

export default Window2;