import React from 'react';
import {createGlobalStyle, ThemeProvider} from 'styled-components';
import theme from '../theme';
import { EmptyIcon, NavIcon } from 'assets/icons';
// import { KPNMetricRegular } from 'assets/fonts';
// import * as css from 'assets/fonts/fonts.css';

// eslint-disable-next-line
const fonts = require('assets/fonts/fonts.css');


// // 
// import fonts  from '../assets/fonts/fonts.css';

const GlobalStyle = createGlobalStyle`
    //#region Fonts

    ${fonts}
    body {
        background-color: black;
        color:white;
        font-family: 'KPNMetric';
        overflow:hidden;
    }


    //#endregion

    //#region Pannellum Styling

    .pnlm-panorama-info {
        display: none!important;
    }

    .custom-hotspot {
        height: 20px;
        width: 20px;
        //background: ${theme.colors.light_100};
        background-image: url(${EmptyIcon});
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        cursor: pointer;
    }

    .custom-hotspot span {
        visibility: hidden;
        position: absolute;
        border-radius: 3px;
        background-color: #fff;
        color: #000;
        text-align: center;
        max-width: 600px;
        min-width: 100px;
        padding: 5px 10px;
        transform: translate(-50%, -100%);
        top: -10px;
        border-radius: 100px;
        cursor: default;
    }

    .custom-hotspot span::after{
        content: '';
        position: absolute;
        left:50%;
        top: 98%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 8px solid white;
        clear: both;
    }

    .custom-hotspot:hover span{
        visibility: visible;
    }

    .custom-nav-hotspot {
        height: 50px;
        width: 50px;
        //background: ${theme.colors.light_100};
        background-image: url(${NavIcon});
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        cursor: pointer;
    }

    // Pannellum Loading 

    .pnlm-load-box{
        display: none!important;
    }

    .pnlm-about-msg {
        display: none!important;
    }

    //#endregion

`;

export interface LayoutProps {
    children: React.ReactNode
}

const Layout = ({children}:LayoutProps) => {
    
	return (
		<ThemeProvider theme={theme}>
			<GlobalStyle/>
			{children}
		</ThemeProvider>
	);
};
    
export default Layout;