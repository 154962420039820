import * as THREE from 'three';
import ModelScene, { modelComponents } from 'constants/Scenes';
import { useAnimations, useGLTF } from '@react-three/drei';
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader';
import React, { useEffect, useState } from 'react';
import gameContext, { useGameContext } from 'context/gameContext';


type GLTFResult = GLTF & {
    nodes: {
      Painting_01: THREE.Mesh
    }
    materials: {
      low_poly_interior: THREE.MeshStandardMaterial
    }
  }
  
interface ModalRenderProps {
    activeModal: ModelScene
}

const ModelLogicUnit = ({children, model}: any) => {
	const {modelData, onUpdateModelData} = useGameContext();
	const getHasModelData = (name) => {
		if(modelData && modelData[name]) {
			return true;
		}
		return false;
	};
	const hasModalData = getHasModelData(model.label);

	const getModelData = () => {

		const { nodes, materials, animations } = useGLTF(model.model) as GLTFResult;
		const payload = {nodes, materials, animations};
		return payload;

	};
	
	useEffect(() => {
		if(!hasModalData) {
			// update state
			onUpdateModelData && onUpdateModelData(model.label, getModelData());
		}
	}, []);
	// schrijf state voor current active model. in game context

	return <>{children}</>;
};

const ModelRenderer = ({activeModal}: ModalRenderProps) => {
	const model = modelComponents[activeModal];
	const ModelElement = model && model.component;

	// prepare some of the logic
	return (
		<>
			{model && <ModelLogicUnit model={model}><ModelElement/></ModelLogicUnit>}
		</>
	);
};

export default ModelRenderer;
