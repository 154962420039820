import { useGLTF } from '@react-three/drei';
import {  Wifipunt } from 'assets/models';
import InteractivePoints from 'components/InteractivePoints';
import Model from 'components/Model';
import { useGameContext } from 'context/gameContext';
import React, { Suspense, useEffect } from 'react';

useGLTF.preload(Wifipunt);

const scale = 5;

const WifipuntScene = () => { 
	const {currentModelData, onAddActiveAnimation,activeModelLayer, onUpdateActiveModel, onSetActiveLayer, activeAnimations, closeButtonActive, setSceneFragment, setCloseButtonActive} = useGameContext();

	useEffect(() => {
		if(closeButtonActive)
			setCloseButtonActive && setCloseButtonActive(false);
	}, []);

	// useEffect(() => {
	// 	return () => {
	// 		setCloseButtonActive && setCloseButtonActive(true);
	// 	};
	// }, []);

	const onInteractivePointClick = (point) => {
		switch(point.name){
		case '01':
			onAddActiveAnimation && onAddActiveAnimation({nodeName: 'RJ45', animationName:'kabel_fade_in'});
			onSetActiveLayer && onSetActiveLayer(2);

			break;
		case '02':
			onAddActiveAnimation && onAddActiveAnimation({nodeName: 'RJ45', animationName:'kabel_in'});
			
			setCloseButtonActive && setCloseButtonActive(true);
			onSetActiveLayer && onSetActiveLayer(3);
			setSceneFragment && setSceneFragment(2);
			//onUpdateActiveModel && onUpdateActiveModel(null);

			break;
		}
	};

	
	useEffect(() => {
		if(activeAnimations && activeAnimations.length === 0){
			switch(activeModelLayer){

			case 3:	
				onUpdateActiveModel && onUpdateActiveModel(null);

				break;
			}

		}
	}, [activeAnimations]);

	return (
		<>
			<Suspense fallback={null}>
				{currentModelData && <Model customScale={scale}/>}
				{currentModelData && <InteractivePoints onInteractivePointClick={(name: string) => onInteractivePointClick(name)} customModelScale={scale} />}
			</Suspense>
		</>
	);
};

export default WifipuntScene;
