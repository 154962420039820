import { sceneProps } from 'data';

const Scene: sceneProps = 	{
	id: 3,
	story: {
		narrative: {
			'main': [],
			'subScenes': [],
			'requested': [
				'scene_02_requested_01', 
				'scene_02_requested_02', 
				'scene_02_requested_03',
				'scene_02_requested_04', 
				'scene_02_requested_05', 
				'scene_02_requested_06',
				'scene_02_requested_07', 
				'scene_02_requested_08', 
				'scene_02_requested_09', 
				'scene_02_requested_10', 
				'scene_02_requested_11', 
				'scene_02_requested_12', 
				'scene_02_requested_12_02',
				'scene_02_requested_13', 
				'scene_02_requested_14', 
				'scene_02_automatic_01',
				'scene_02_automatic_02',
			],
			'requestedAtStart': [
				'scene_02_requested_05', 
			],
		},
	},
	pannellumData: [
		'woonkamer_01_p1',
		'woonkamer_01_p2',
		'woonkamer_02_p1',
		'woonkamer_02_p2',
		'woonkamer_03_p1',
		'woonkamer_03_p2',
		'woonkamer_04_p2',
	]
};

export default Scene;