import { useGLTF } from '@react-three/drei';
import {  Ps5 } from 'assets/models';
import InteractivePoints from 'components/InteractivePoints';
import Model from 'components/Model';
import { useGameContext } from 'context/gameContext';
import React, { Suspense, useEffect } from 'react';

useGLTF.preload(Ps5);

const scale = 4;

const Ps5Scene_02 = () => { 
	const {currentModelData, onAddActiveAnimation, activeAnimations, activeModelLayer, onSetActiveLayer, setSceneFragment, onStoryEvent, queueStoryEvent} = useGameContext();

	const onInteractivePointClick = (point) => {
		switch(point.name){
		case '01':
			onAddActiveAnimation && onAddActiveAnimation({nodeName:'RJ45', animationName:'kabel_fade_in'});
			onSetActiveLayer && onSetActiveLayer(2);
			break;
		case '02':
			onAddActiveAnimation && onAddActiveAnimation({nodeName:'RJ45', animationName:'kabel_in'});
			onSetActiveLayer && onSetActiveLayer(3);

			break;
				
		}
	};

	useEffect(() => {
		if(activeAnimations && activeAnimations.length === 0){
			switch(activeModelLayer){

			case 3:
				queueStoryEvent && queueStoryEvent({type:'openDialogue', param: 'scene_04_automatic_05'});
				setSceneFragment && setSceneFragment(6);
				break;
			}
		}
	}, [activeAnimations]);

	// useEffect(() => {
	// 	if(activeAnimations && activeAnimations.length === 0){
	// 		switch(activeModelLayer){

	// 		case 1:
	// 			onAddActiveAnimation && onAddActiveAnimation({nodeName:'RJ45', animationName:'kabel_in'});
	// 			break;
	// 		}
	// 	}
	// }, [activeAnimations]);

	return (
		<>
			<Suspense fallback={null}>
				{currentModelData && <Model customScale={scale}/>}
				{currentModelData && <InteractivePoints onInteractivePointClick={(name: string) => onInteractivePointClick(name)} customModelScale={scale} />}
			</Suspense>
		</>
	);
};

export default Ps5Scene_02;
