import { media } from './media';
import { css } from 'styled-components';

function cssPropFromKey(key) {
	return key
		.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
		.map((x) => x.toLowerCase())
		.join('-');
}

function isValidKey(key) {
	return [
		'fontSize',
		'width',
		'height',
		'left',
		'right',
		'top',
		'bottom',
		'paddingTop',
		'paddingLeft',
		'paddingRight',
		'paddingBottom',
		'padding',
		'marginTop',
		'marginLeft',
		'marginRight',
		'marginBottom',
		'margin',
		'fontSize',
		'lineHeight',
		'borderRadius'
	].includes(key);
}

function proportionalRules(key, units, theme) {
	const [a, b] = units;
	const property = cssPropFromKey(key);
	return css`  
		${property}: ${(a / theme.designFormats[0]) * 100}vw;
		
		${media('l')} {
			${property}: ${(b / theme.designFormats[1]) * 100}vw;
		}
		
		${media('xl')} {
			${property}: ${b}px;
		} 
  	`;
}

export function proportional(props) {
	const {theme, ...rest } = props;
	const rules = Object.entries(rest)
		.filter(([key, value]) => {
			return isValidKey(key) && Array.isArray(value);
		})
		.map(([key, value]) => proportionalRules(key, value, theme));

	return css`
    ${rules}
  `;
}
