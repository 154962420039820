import { useGLTF } from '@react-three/drei';
import { Deur } from 'assets/models';
import InteractivePoints from 'components/InteractivePoints';
import Model from 'components/Model';
import { useGameContext } from 'context/gameContext';
import React, { Suspense } from 'react';
import { useEffect } from 'react';

useGLTF.preload(Deur);

const scale = 1.2;

const MeterkastDichtScene = () => { 
	const {currentModelData, onAddActiveAnimation, modelIntroDone, onStoryEvent, activeAnimations, activeModelLayer} = useGameContext();

	const onInteractivePointClick = (point) => {
		switch(point.name){
		case '01':
			// onAddActiveAnimation && onAddActiveAnimation({nodeName: 'Deur_Anim', animationName:'deur_action'});
			onAddActiveAnimation && onAddActiveAnimation({nodeName: 'Deur_Anim_001', animationName:'klink_locked'});
			break;		
		}
	};

	useEffect(() => {
		if(activeAnimations && activeAnimations.length === 0){
			switch(activeModelLayer){
		
			case 1:	
				if (modelIntroDone){
					onStoryEvent && onStoryEvent({type:'openDialogue', param: 'scene_01_requested_10'});
				}	
				break;
			}
		}
	}, [activeAnimations]);

	return (
		<>
			<Suspense fallback={null}>
				{currentModelData && <Model customScale={scale}/>}
				{currentModelData && <InteractivePoints onInteractivePointClick={(name: string) => onInteractivePointClick(name)} customModelScale={scale}/>}
			</Suspense>
		</>
	);
};

export default MeterkastDichtScene;