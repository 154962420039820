import { render_keuken_01_p1 } from 'assets/renders';
import { getHotspots } from '../Hotspots';
import getConfig from './universalConfig';

const customConfig = {
	imageSource: render_keuken_01_p1,
};

// action: {label: changeScene | changeSceneFragment, value: string | number};

let customHotspots = [
	{
		'pitch': 1.55,
		'yaw': 93.438,
		'type': 'info',
		'createTooltipArgs': {
			'text': 'fotolijstje',
			'type': 'scene',
			'sceneId': 'fotolijstje',
		}
	},
	{
		'pitch': -44.052,
		'yaw': -75.7074,
		'type': 'info',
		'createTooltipArgs': {
			'text': 'tablet',
			'type': 'scene',
			'sceneId': 'tablet_keuken',
		}
	},
];


// Asign standard hotspot config
customHotspots = getHotspots(customHotspots);
customConfig['hotSpots'] = customHotspots;

const Scene = {
	sceneId: 'keuken_01_p1',
	config: getConfig(customConfig),
	// startingPitch: -15.041,
	// startingYaw: 126.985,
};

export default Scene;

