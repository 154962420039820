import { render_hal_01_p1 } from 'assets/renders';
import { getHotspots } from '../Hotspots';
import getConfig from './universalConfig';

const customConfig = {
	imageSource: render_hal_01_p1,
};

// action: {label: changeScene | changeSceneFragment, value: string | number};

let customHotspots = [
	{
		'pitch': -64.40,
		'yaw': -174.875,
		'type': 'info',
		'createTooltipArgs': {
			'text': 'deurmat',
			'type': 'scene',
			'sceneId': 'deurmat',
		}
	},
	{
		'pitch': -3.972,
		'yaw': 34.414,
		'type': 'info',
		'createTooltipArgs': {
			'text': 'kinderjas',
			'type': 'scene',
			'sceneId': 'kinderjas',
		}
	},
	{
		'pitch': -28.4940,
		'yaw': 38.36363,
		'type': 'info',
		'createTooltipArgs': {
			'text': 'voetbal',
			'type': 'scene',
			'sceneId': 'voetbal',
		}
	},
	{
		'pitch': -16.971,
		'yaw': 2.022,
		'type': 'scene',
		'createTooltipArgs': {
			'text': '',
			action: {
				'label': 'navigation',
				value: 1,
			}
		}
	},
];

// Asign standard hotspot config
customHotspots = getHotspots(customHotspots);
customConfig['hotSpots'] = customHotspots;

const Scene = {
	sceneId: 'hal_01_p1',
	config: getConfig(customConfig),
};

export default Scene;