import { useGLTF } from '@react-three/drei';
import { Painting } from 'assets/models';
import InteractivePoints from 'components/InteractivePoints';
import Model from 'components/Model';
import { useGameContext } from 'context/gameContext';
import React, { Suspense, useRef } from 'react';

useGLTF.preload(Painting);


/* 
    Model Requirements

    Props
    * Interactive Points Toggle method
    * Set Interactive Points Data method
    * Interactive Point Data 
    * Set Animation Actions Object method
    * Set Animation Action Names method
    * Active Interactive Points Layer
    * Set Interactive Points Layer

    Functionality
    * 

*/

// const animationNames = ['Painting_01_Move_01', 'Painting_01_Rotate_01'];

const PaintingScene = () => { 
	const {currentModelData, onSetActiveLayer} = useGameContext();
	const animations = currentModelData && currentModelData.animations;

	const animationActionRef = useRef<any>();
	
    
	const setAnimationPose = (id: any) => {
		// const animation = animations.find((animation) => animation.name === id);
		if(animations) {
			// if(animationRef.current) {
			// 	animationRef.current.actions.stop();
			// 	animationRef.current.actions.reset();
			// }
			// animationRef.current = animation;
			// animation.actions.play();
			// if(lastPoseAnim){
			// 	animations[lastPoseAnim].stop();
			// 	animations[lastPoseAnim].reset();
			// }
			// animations[id].time = actions[id].getClip.duration;
			// actions[id].play();
			// setLastPoseAnim(id);
		}
	};

	const onInteractivePointClick = (point) => {
		switch(point.name){

		case '01':
			// playAnimation(animActionNames[1]);
			onSetActiveLayer && onSetActiveLayer(2);
			
			// setAnimationPose(animationNames[0]);
			break;

		case '02':
			onSetActiveLayer && onSetActiveLayer(1);
			// setAnimationPose(animationNames[1]);
			// setAnimationPose(animActionNames[0]);
			// playAnimation(animActionNames[0]);
			break;
		}

	};

	return (
		<>
			{/*Model Component*/}
			<Suspense fallback={null}>
				{currentModelData && <Model/>}
				{currentModelData && <InteractivePoints onInteractivePointClick={(name: string) => onInteractivePointClick(name)}/>}
			</Suspense>
			{/*Interactive Points Component*/}
		</>
	);
};

export default PaintingScene;