import { render_caddy_05_p1 } from 'assets/renders';
import { getHotspots } from '../Hotspots';
import getConfig from './universalConfig';

const customConfig = {
	imageSource: render_caddy_05_p1,
};

// action: {label: changeScene | changeSceneFragment, value: string | number};

const customHotspots = [
];

// Asign standard hotspot config
// customHotspots = getHotspots(customHotspots);
customConfig['hotSpots'] = customHotspots;

const Scene = {
	sceneId: 'keuken_03_p1',
	config: getConfig(customConfig),
	// startingPitch:-44.052,
	// startingYaw:-75.7074,
};

export default Scene;

