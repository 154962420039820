import { useSpring } from '@react-spring/core';
import { useGLTF, Html } from '@react-three/drei';
import { Tablet } from 'assets/models';
import Button from 'components/Button';
import InteractivePoints from 'components/InteractivePoints';
import Model from 'components/Model';
import { useGameContext } from 'context/gameContext';
import React, { Suspense, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

useGLTF.preload(Tablet);

const scale = 7;

const Wrapper = styled.div`
	/* background-color: pink; */

	display: flex;
	flex-direction: column;

	justify-content: center;

	width: 417px;
	height: 250px;

	padding: 15px;
	user-select: none;
`;

const TabletButton = styled.button`

	background-color: #009900;
	color:black;
	padding: 7px 70px 7px 70px;
	border-radius: 1em;
	font-size: 1.3rem;
	margin-bottom: 1rem;

	border: none;

	&:disabled{
		background-color: grey;
	}

	&:hover, :focus{
	background-color: #009100;
	}

	cursor: pointer;
`;

const TabletSceneCaddy = () => { 
	const {currentModelData, modelIntroDone, onUpdateScene, onUpdateActiveModel, onStoryEvent, onSetNotificationText, closeButtonActive, setCloseButtonActive} = useGameContext();

	const onInteractivePointClick = (point) => {
		switch(point.name){
		case '01':
		}
	};

	//#region Tablet UI Logic

	const [travelButtonActive, setTravelButtonActive] = useState<boolean>(false);

	const onButtonCall = () => {
		setTravelButtonActive(true);
		onStoryEvent && onStoryEvent({type:'openDialogue', param: 'scene_00_requested_00'});
		onSetNotificationText && onSetNotificationText(7);
	};

	const onTravelButton = () => {
		onUpdateScene && onUpdateScene(2);
		onUpdateActiveModel && onUpdateActiveModel(null);
	};

	useEffect(() => {
		if(closeButtonActive)
			setCloseButtonActive && setCloseButtonActive(false);

		return () => {
			setCloseButtonActive && setCloseButtonActive(true);
		};
	}, []);


	//#endregion

	return (
		<>
			<Suspense fallback={null}>
				{modelIntroDone && 
				<Html
					as='div'
					distanceFactor={2}
					transform
					occlude
					position={[0,0,0.04]}
					scale={1}
				>
					<Wrapper>
						{!travelButtonActive ? 

							<TabletButton onClick={onButtonCall}>Bel Klant</TabletButton>:
							<>
								<TabletButton onClick={onTravelButton}>Zet status: Started</TabletButton>
								<TabletButton onClick={onTravelButton}>Zet status: Travelling</TabletButton>
								<TabletButton onClick={onTravelButton}>Zet status: Completed</TabletButton>
							</>
						}
						
					</Wrapper>
				</Html>
				}	
				{currentModelData && <Model customScale={scale}/>}
				{currentModelData && <InteractivePoints onInteractivePointClick={(name: string) => onInteractivePointClick(name)} customModelScale={scale}/>}
			</Suspense>
		</>
	);
};

export default TabletSceneCaddy;
