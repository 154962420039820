import { render_hal_03_p2 } from 'assets/renders';
import { getHotspots } from '../Hotspots';
import getConfig from './universalConfig';

const customConfig = {
	imageSource: render_hal_03_p2,
};

// action: {label: changeScene | changeSceneFragment, value: string | number};

let customHotspots = [
	{
		'pitch': -16.971,
		'yaw': 2.022,
		'type': 'scene',
		'createTooltipArgs': {
			'text': '',
			action: {
				'label': 'changeScene',
				value: 3,
			}
		}
	},
];

// Asign standard hotspot config
customHotspots = getHotspots(customHotspots);
customConfig['hotSpots'] = customHotspots;

const Scene = {
	sceneId: 'hal_03_p2',
	config: getConfig(customConfig),
	startingPitch: -9.20386,
	startingYaw: -0.966152,
};

export default Scene;