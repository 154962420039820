import { useGLTF } from '@react-three/drei';
import { Deur } from 'assets/models';
import InteractivePoints from 'components/InteractivePoints';
import Model from 'components/Model';
import { useAudioContext } from 'context/AudioContext';
import { useGameContext } from 'context/gameContext';
import React, { Suspense } from 'react';
import { useEffect } from 'react';

useGLTF.preload(Deur);

const scale = 1.2;



const DeurScene = () => { 
	const {currentModelData, onAddActiveAnimation, modelIntroDone, onStoryEvent, onSetActiveLayer, activeAnimations, onUpdateActiveModel, setControls} = useGameContext();
	
	const { playSound } = useAudioContext();

	const onInteractivePointClick = (point) => {
		switch(point.name){
		case '01':
		}
	};

	useEffect(() => {
		if(modelIntroDone){
			onAddActiveAnimation && onAddActiveAnimation({nodeName: 'Deur_Anim', animationName:'deur_action'});
			onAddActiveAnimation && onAddActiveAnimation({nodeName: 'Deur_Anim_001', animationName:'klink_action'});
			playSound && playSound('deur_01');
			onStoryEvent && onStoryEvent({type:'swapQueuedFragment'});
			//onStoryEvent && onStoryEvent({type: 'openDialogue', param: 'scene_01_requested_10'});
		}
	}, [modelIntroDone]);

	useEffect(() => {
		currentModelData && onSetActiveLayer && onSetActiveLayer(-1);
		setControls && setControls(false);

		return () => {
			setControls && setControls(true);
		};
	}, []);

	useEffect(() => {
		if(activeAnimations && activeAnimations.length === 0){
			modelIntroDone && onUpdateActiveModel && onUpdateActiveModel(null);
		}

	}, [activeAnimations]);

	return (
		<>
			<Suspense fallback={null}>
				{currentModelData && <Model customScale={scale}/>}
				{/* {currentModelData && <InteractivePoints onInteractivePointClick={(name: string) => onInteractivePointClick(name)} customModelScale={scale} customPointScale={0.03}/>} */}
			</Suspense>
		</>
	);
};

export default DeurScene;