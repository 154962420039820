import ModelScene from 'constants/Scenes';
import Scene from 'constants/Scenes';
import React, {useEffect, useState} from 'react';
// import Scene from '../constants/Scenes';
import {narativeSceneName, storyProps, sceneProps, scenes, story} from '../data';
import { useAudioContext } from './AudioContext';


const amountOfScenes = scenes.length;

type DialogueButtonStates = 'hidden' | 'inactive' | 'active' | 'available' | 'active-no-pointer';

//#region Story Event Typing

type storyEventTypes = 'openModel' | 'openDialogue' | 'setRequestedNarrative' | 'swapFragment' | 'swapQueuedFragment' | 'queueFragmentSwap' | 'setNotificationText' | 'queueStoryEvent' | 'setActiveModelLayer' |'endGame' | 'toggleCloseButton';

interface storyEvent {
	type: storyEventTypes;
	param?: any;
}

//#endregion

type ContextProps = { 
    currentScene: sceneProps,
	sceneFragment: number,
	setSceneFragment: (val: number) => void
    onUpdateScene: (sceneId: number) => void,
	setUpdatingScene: (val: boolean) => void,
	updatingScene: boolean;
	displayInterface: boolean,
    setDisplayInterface: (newVal: boolean) => void,
    toggleDisplayInterface: () => void,
	storyChunkObject: any,
	amountOfScenes: number,
	activeStoryChunk: narativeSceneName,
	activeRequestedStoryChunk: narativeSceneName,
	getStoryChunk: (id?:any) => void,
	setActiveStoryChunk: (newActiveStoryChunk: narativeSceneName) => void,
	setActiveRequestedStoryChunk: (newActiveRequestStoryChunk: narativeSceneName) => void,
	activeModel: ModelScene,
	onUpdateActiveModel: (newValue: string | null) => void,
	onUpdateModalDisplay: (newValue: boolean) => void,
	modelData: any,
	onUpdateModelData: (name: ModelScene, data: any) => void,
	interactivePointsDisplay: boolean,
	onUpdateInteractivePointsDisplay: (newVal: boolean) => void,
	currentModelData: any,
	modelPoints: any,
	activeModelLayer: number,
	onSetActiveLayer: (val: number) => void,
	currentAnimationName: string,
	setCurrentAnimationName: (val: string) => void,
	animationList: any[],
	onSetAnimationList: (val: string[]) => void,
	meshList: any[],
	onSetMeshList: (val: string[]) => void,
	animationObjects: any,
	onSetAnimationObjects: (val: any) => void,
	nextRequestedDialogue: string,
	dialogueButtonState: DialogueButtonStates
	onSetDialogueButtonState: (newVal: DialogueButtonStates) => void,
	savedDialogueButtonState: boolean;
	setSavedDialogueButtonState: (val: boolean) => void;
	onSaveDialogueButtonState: (val: DialogueButtonStates) => void;
	onIncrementStoryProgression: () => void,
	storyType: string,
	storyData: any,
	openRequestedNarrative: () => void,
	onAddActiveAnimation: (any) => void
	onRemoveActiveAnimation: (any) => void
	activeAnimations: any,
	playAnimation: (meshNameId: string, animationNameId: string) => void,
	onFinishAnimation: () => void,
	modelIntroDone: any,
	setModelIntroDone: (newVal: boolean) => void,

	// Story Event Properties
	onStoryEvent: (e: storyEvent) => void,
	queueStoryEvent: (e: storyEvent) => void,
	triggerQueuedStoryEvent: () => void,

	queuedStoryEvent: storyEvent,
	setQueuedStoryEvent: (e: storyEvent) => void,
	queueFragmentSwap: (e: number) => void,

	// Controls
	controls: boolean,
	setControls: (val:boolean) => void,
	closeButtonActive: boolean,
	setCloseButtonActive: (val:boolean) => void,


	// Game State
	setGameStarted: (val:boolean) => void,

	// Notification
	notificationActive: boolean;
	setNotificationActive: (val: boolean) => void,

	notificationText: number;
	setNotificationText: (val:number) => void,
	onSetNotificationText: (val:number) => void,

	storeAnswer: (val: {value: string}) => void,
	gameStarted: boolean,

	endOfGame: boolean,
	setEndOfGame: (val: boolean) => void,

	storedAnswers: any,

	totalTime: any,

};

const GameContext = React.createContext<Partial<ContextProps>>({});

interface contextProps {
    children: JSX.Element, 
}


const GameProvider = ({ children }: contextProps) => {

	const {playVO, playSound, playBackground} = useAudioContext();

	const [endOfGame, setEndOfGame] = useState<boolean>(false);

	// model for main Provider
	const [currentModelData, setCurrentModelData] = useState<any>();
	const [modelData, setModelData] = useState<any>({});
	const [activeModelLayer, setActiveModelLayer] = useState<any>(1);
	const [modelPoints, setModelPoints] = useState<any>();
	const [modelIntroDone, setModelIntroDone] = useState<any>(false);

	// model pos unused
	const [currentAnimationName, setCurrentAnimationName] = useState<string>();




	// selected scene logic
	const [currentScene, setCurrentScene] = useState<sceneProps>(scenes[0]);
	const [sceneFragment, setSceneFragment] = useState<number>(0);
	const [startRequestedSet, setStartRequestedSet] = useState<boolean>(false);


	// const [animationList, setAnimationList] = useState<string[]>();
	// const [animationObjects, setAnimationObjects] = useState<any>();
	// const [meshList, setMeshList] = useState<string[]>();

	const [dialogueButtonState, setDialogueButtonState] = useState<DialogueButtonStates>('hidden');
	const [savedDialogueButtonState, setSavedDialogueButtonState] = useState<DialogueButtonStates>('hidden');
	
	const onSetDialogueButtonState = (newVal: DialogueButtonStates, save = false) => {
		if(save){
			setSavedDialogueButtonState(newVal);
		}
		setDialogueButtonState(newVal);
	};

	const onSaveDialogueButtonState = (val) => {
		setSavedDialogueButtonState(val);
	};
	
	// const onSetAnimationList = (val) => {setAnimationList(val);};
	// const onSetMeshList = (val) => {setMeshList(val);};
	// const onSetAnimationObjects = (val) => {setAnimationObjects(val);};


	const [updatingScene, setUpdatingScene] = useState<boolean>(false);

	const onUpdateScene = (newCurrentScene) => { const newScene = scenes.find((scene) => {return scene.id === Number(newCurrentScene);}); newScene && setCurrentScene(newScene); setUpdatingScene(true);};
	
	const [sceneStoryProgression, setSceneStoryProgression] = useState<number>(-1);

	//timer

	const [startTime, setStartTime] = useState<any>();
	const [endTime, setEndTime] = useState<any>();
	const [totalTime, setTotalTime] = useState<any>();

	const startTimer = () =>{
		setStartTime(new Date);
	};
	const stopTimer = () =>{
		setEndTime(new Date);
	};

	const millisToMinutesAndSeconds = (millis) => {
		const minutes = Math.floor(millis / 60000);
		const seconds = ((millis % 60000) / 1000).toFixed(0);
		return minutes + ':' + (Number(seconds) < 10 ? '0' : '') + seconds;
	};

	const getTotalTime = () =>{
		if(endTime && startTime) {
			const time = millisToMinutesAndSeconds(endTime.getTime() - startTime.getTime());
			setTotalTime(time);
		}
	};


	// story
	const [gameStarted, setGameStarted] = useState<boolean>(false);
	const [activeStoryChunk, setActiveStoryChunk] = useState<any>(null);
	const [activeRequestedStoryChunk, setActiveRequestedStoryChunk] = useState<any>();
	const [storyData, setStoryData] = useState<any>(null);
	const [storyChunkObject, setStoryChunkObject] = useState<any>(null);
	const [storyType, setStoryType] = useState<any>(null);
	const [storedAnswers, setStoredAnswers] = useState<{value: string, storyId: string}[]>([]);
	

	useEffect(() => {
		activeRequestedStoryChunk;
	}, [activeRequestedStoryChunk]);

	useEffect(() => {
		if(sceneFragment == 4 && currentScene == scenes[1]){
			setTimeout(()=> {
				onSetNotificationText && onSetNotificationText(10);
			}, 2000);
		}
	}, [sceneFragment]);
	
	useEffect(() => {
		activeRequestedStoryChunk;
	}, [activeRequestedStoryChunk]);
	const onSetActiveStoryChunk = (storyChunk) => {
		setActiveStoryChunk(storyChunk);
	};

	const getStoryPiece = (narativeItem) => {
		const storyPiece = narativeItem.map((storyChunkKey) => story[storyChunkKey]);
		return storyPiece;
	};

	const getSceneStoryChunkObject = () => {
		const narrative = currentScene && currentScene.story && currentScene.story.narrative;
		const newChunkObject = {};
		if(narrative) {
			Object.keys(narrative).forEach((key) => {
				newChunkObject[key] = getStoryPiece(narrative[key]);
			});
		}

		return newChunkObject;
	};

	const getStoryChunk = (id = -1) => {
		// activeStoryChunk && storyChunkObject[activeStoryChunk];
		const keys = Object.keys(storyChunkObject);
		const flatArray = keys.map((key) => {const chunks = storyChunkObject[key]; const storyChunks = chunks.map((storyChunk) => storyChunk); return storyChunks; }).flat();
		
		const newChunk = flatArray.find((chunk) => chunk.id === (id !== -1 ? id : activeStoryChunk));
		return newChunk;
	};

	const storeAnswer = (val) => {
		setStoredAnswers((stored) => {const newStored = {...stored}; newStored[storyData.id] = val; return newStored;});
	};

	useEffect(() => {
		if(storedAnswers) {
			storedAnswers;
		}
	}, [storedAnswers]);

	// dev tool togic
	const [displayInterface, setDisplayInterface] = useState<boolean>(false);

	const toggleDisplayInterface = () => {setDisplayInterface((display) => !display);};

	// selected scene logic
	const [activeModel, setActiveModel] = useState<ModelScene>();
	const onUpdateActiveModel = (newActiveModel) => setActiveModel(newActiveModel);

	const [interactivePointsDisplay, setInteractivePointsDisplay] = useState(false); 
	const onUpdateInteractivePointsDisplay= (newVal) => setInteractivePointsDisplay(newVal);

	// const [animationsActive, setAnimationsActive] = useState<number>(0);
	const [activeAnimations, setActiveAnimations] = useState<any>([]);

	const onAddActiveAnimation = ({nodeName, animationName}) => {
		if(activeAnimations.indexOf(activeAnimations.find((animation) => animation.key === nodeName)) === -1) {
			setActiveAnimations((animations) => [...animations, {key: nodeName, animation: animationName}]);
		}
	};

	const onRemoveActiveAnimation = (nodeName) => {
		setActiveAnimations((animations) => animations.filter((animation) => animation.key !== nodeName));
	};

	useEffect(() => {
		if(activeAnimations && activeAnimations.length > 0) {
			setInteractivePointsDisplay(false);
		} else {
			modelIntroDone && setInteractivePointsDisplay(true);
		}
	}, [activeAnimations, modelIntroDone]);

	const onUpdateModelData = ((name, modelDataChunk) => {
		const newModelData = {...modelData};

		newModelData[name] = {...modelDataChunk, 'activeLayer': 1};

		newModelData && setModelData(newModelData);
	});

	const onSetActiveLayer = (val) => {
		if(activeModel) {
			const newModelData = {...modelData};
			newModelData[activeModel].activeLayer = val;
			setModelData({...modelData, activeModel: newModelData[activeModel]});
			setActiveModelLayer(val);
		}
	};

	const onFinishAnimation = () => {
		// setAnimationsActive((aa) => aa - 1);
		// if(animationsActive === 0){
		// 	onUpdateInteractivePointsDisplay && onUpdateInteractivePointsDisplay(true);
		// }
	};

	const onIncrementStoryProgression = () => {
		// check if story has ended
		const storyLine = currentScene?.story?.narrative?.main;
		// if so, clear the progression back to -1
		if(storyLine && storyLine.length > sceneStoryProgression + 1) {
			setSceneStoryProgression((currentProgress) => currentProgress += 1);
		} else {
			// reset story state (for next scene)
			setActiveStoryChunk('');
			setSceneStoryProgression(-1);
		}

	};

	const onSetActiveRequestedStoryChunk = (newActiveRequestStoryChunk) => {
		if(newActiveRequestStoryChunk){
			onSaveDialogueButtonState('available');
		}
		else{
			onSaveDialogueButtonState('inactive');
		}
		setActiveRequestedStoryChunk(newActiveRequestStoryChunk);


	};

	const openRequestedNarrative = () => {
		if(activeRequestedStoryChunk && !activeStoryChunk){
			onSaveDialogueButtonState('active');
			setActiveStoryChunk(activeRequestedStoryChunk);
		}
	};

	const [closeButtonActive, setCloseButtonActive] = useState<boolean>(true);

	useEffect(() => {
		closeButtonActive;
	}, [closeButtonActive]);

	useEffect(() => {
		interactivePointsDisplay;
	}, [interactivePointsDisplay]);

	//#region Notification Panel

	const [notificationActive, setNotificationActive] = useState<boolean>(true);
	const [notificationText, setNotificationText] = useState<number>(2);

	const onSetNotificationText = (val) => {
		setNotificationText(val);
		setNotificationActive(val);
	};

	//#region Story Progression Events

	// Queued storyEvent
	const [queuedStoryEvent, setQueuedStoryEvent] = useState<storyEvent | null>(null);
	// Queued Pannellum Fragment
	const [queuedFragment, setQueuedFragment] = useState<number>(-1);

	// Handles storyEvents to trigger specified functionality
	const onStoryEvent = (e: storyEvent) => {
		switch(e.type){
		case 'openModel':
			//Set Active Model in Context
			onUpdateActiveModel(e.param);
			break;
		case 'openDialogue':
			//Set Active Dialogue in Context
			onSetActiveStoryChunk(e.param);
			break;
		case 'setRequestedNarrative':
			//Set Requested Narrative in Context
			onSetActiveRequestedStoryChunk(e.param);
			break;
		case 'swapFragment':
			//Set Pannellum Scene in Context
			setSceneFragment(e.param);
			break;

		case 'queueStoryEvent':
			//Set Pannellum Scene in Context
			queueStoryEvent(e.param);
			break;

		case 'setNotificationText':
			if(e.param != -1){
				onSetNotificationText(e.param);
			}
			else{
				setNotificationActive(false);
			}
			break;
		case 'queueFragmentSwap':
			//Set Pannellum Scene in Context
			queueFragmentSwap(e.param);
			break;

		case 'setActiveModelLayer':
			onSetActiveLayer(e.param);
			break;

		case 'endGame':
			setEndOfGame(true);
			break;
		case 'swapQueuedFragment':
			//Set Pannellum Scene in Context but with a queued parameter
			if(queuedFragment != -1){
				setSceneFragment(queuedFragment);	
				setQueuedFragment(-1);
			}
			break;
		case 'toggleCloseButton':
			setCloseButtonActive(e.param);
			break;
		}
	};

	// Queues a storyEvent
	const queueStoryEvent = (e: storyEvent) => {
		setQueuedStoryEvent(e);
	};

	// Triggers a queued storyEvent
	const triggerQueuedStoryEvent = () => {
		queuedStoryEvent && onStoryEvent(queuedStoryEvent);
		setQueuedStoryEvent(null);
	};

	const queueFragmentSwap = (param) => {
		setQueuedFragment(param);
	}; 

	//#endregion

	const [controls, setControls] = useState<boolean>(true);

	// Use Effects

	// On change Scene

	useEffect(() => {
		if(currentScene) {
			setStartRequestedSet(false);
			setSceneFragment(-1);
			setUpdatingScene(false);
			setStoryChunkObject(getSceneStoryChunkObject());
			onIncrementStoryProgression();
			if(currentScene.id === 3){
				// onSetNotificationText(1);
				playBackground && playBackground(true, 'background_02', 0.4);
			}
			if(currentScene.id === 4){
				// onSetNotificationText(1);
				playBackground && playBackground(true, 'background_01', 0.4);
			}

		}
	}, [currentScene]);

	useEffect(() => {
		if(sceneFragment && sceneFragment < 0) {
			setSceneFragment(0);
		}
	}, [sceneFragment]);



	// set an activeRequestedStoryChunk on changing scenes.

	useEffect(() => {
		if(currentScene) {
			const requestedAtStart = currentScene?.story?.narrative?.requestedAtStart;

			if(requestedAtStart && requestedAtStart.length > 0 && !startRequestedSet){
				setTimeout(() => {
					onSetActiveRequestedStoryChunk(requestedAtStart[0]);
					setStartRequestedSet(true);
				}, 3000);
				
			}
		}
	}, [currentScene, activeRequestedStoryChunk]);

	useEffect(() => {
		if(activeStoryChunk) {
			setStoryData(getStoryChunk());
			playVO && playVO(getStoryChunk()?.narrative?.audioId);
			const currentStoryline = currentScene.story?.narrative;
			const storyType = currentStoryline && Object.keys(currentStoryline).find((key) => currentStoryline[key].indexOf(activeStoryChunk) !== -1);
			storyType && setStoryType(storyType);
			// setSavedDialogueButtonState((e) => e = dialogueButtonState);
			onSetDialogueButtonState('active-no-pointer');
		}
		else{
			// setStoryData(story.scene_00_requested_00);
			onSetDialogueButtonState(savedDialogueButtonState);
		}
	}, [activeStoryChunk]);

	useEffect(() => {
		if(!activeStoryChunk){
			onSetDialogueButtonState(savedDialogueButtonState);
		}
	}, [savedDialogueButtonState]);


	useEffect(() => {
		if(sceneStoryProgression > -1) {
			// this will need to be done reversed for manual set Scene actions
			const currentStoryline = currentScene.story?.narrative.main;
			const currentNarative = currentStoryline && currentStoryline[sceneStoryProgression];
			currentNarative && setActiveStoryChunk(currentNarative);
		}
	}, [sceneStoryProgression]);


	// On Close Introduction Screen

	
	useEffect(() => {
		if(gameStarted){
			playBackground && playBackground(true, 'background_01', 0.4);
			startTimer();
			// Play Background Music
		}
	}, [gameStarted]);

	useEffect(() => {
		if(endOfGame){
			stopTimer();
			// Play Background Music
		}
	}, [endOfGame]);

	useEffect(() => {
		if(endOfGame){
			getTotalTime();
			// Play Background Music
		}
	}, [endTime]);

	useEffect(()=> {
		if(endOfGame){
			onSetDialogueButtonState('hidden');
		}
	}, [endOfGame, dialogueButtonState]);

	


	const passedFunctions = {
		getStoryChunk,
		onUpdateScene,
		setDisplayInterface,
		setActiveStoryChunk,
		setSceneFragment,
		onUpdateActiveModel,
		toggleDisplayInterface,
		onUpdateModelData,
		onUpdateInteractivePointsDisplay,
		onSetActiveLayer,
		setCurrentAnimationName,
		onSetDialogueButtonState,
		onFinishAnimation,
		onAddActiveAnimation,
		onRemoveActiveAnimation,
		setModelIntroDone,
		setActiveRequestedStoryChunk,
		onSetActiveRequestedStoryChunk,
		openRequestedNarrative,
		onIncrementStoryProgression,
		onSaveDialogueButtonState,
		setControls,
		setGameStarted,
		
		// Story Event Functions
		onStoryEvent,
		queueStoryEvent,
		triggerQueuedStoryEvent,
		queueFragmentSwap,

		//Notification Panel
		setNotificationActive,
		setNotificationText,
		onSetNotificationText,
		setCloseButtonActive,

		storeAnswer,
		setEndOfGame,

	};

	const passedValues = {
		currentScene,
		displayInterface,
		storyChunkObject, 
		amountOfScenes, 
		activeStoryChunk, 
		sceneFragment,
		activeModel,
		modelData,
		modelIntroDone,
		activeModelLayer,
		interactivePointsDisplay,
		currentModelData,
		currentAnimationName,
		modelPoints,
		activeAnimations,
		dialogueButtonState,
		storyType,
		storyData,
		activeRequestedStoryChunk,
		controls,
		updatingScene,

		// Notification Panel
		notificationActive,
		notificationText,
		gameStarted,
		endOfGame,
		storedAnswers,
		totalTime,

		closeButtonActive
	};

	return (
		<GameContext.Provider value={{ ...passedValues, ...passedFunctions }}>
			<ModelProcessor setActiveModelLayer={setActiveModelLayer} setModelPoints={setModelPoints} modelData={modelData} setModelIntroDone={setModelIntroDone} setCurrentModelData={(newVal) => setCurrentModelData(newVal)}/>
			{children}
		</GameContext.Provider>
	);
}; 

const ModelProcessor = ({setCurrentModelData, setModelIntroDone, modelData, setModelPoints, setActiveModelLayer}: any) => {
	const {activeModel, currentModelData, activeModelLayer} = useGameContext();

	useEffect(() => {
		if(currentModelData && activeModelLayer) {
			setActiveModelLayer(currentModelData.activeLayer || 1 );
			getModelPoints();
		}
	}, [currentModelData, activeModelLayer]);

	useEffect(() => {
		if(activeModel) {
			// setModelIntroDone(false);
			getCurrentModelData();
		}
		// else{
			
		// }
	}, [activeModel, modelData]);

	useEffect(() => {
		if(activeModel) {
			setModelIntroDone(false);
		}
		// else{
			
		// }
	}, [activeModel]);

	const getModelPoints = () => {
		const data = currentModelData;
		const {nodes} = data;
		const points = nodes.interactive_points?.children;

		const modelPoints = points && points.filter((point) => {
			if(point.userData.group === activeModelLayer){    
				return true;
			}
			else{
				return false; 
			}
		}).map((pointRef) => {  
            
			const position = [pointRef.position.x, pointRef.position.y, pointRef.position.z];
			const pointData = pointRef.userData;

			return {
				position, pointData
			}; 
        
		});
		setModelPoints(modelPoints);
	};

	const getCurrentModelData = () => {
		activeModel && setCurrentModelData(modelData[activeModel]);
	};

	return (
		<></>
	);
};

const useGameContext = () => React.useContext(GameContext);

export {useGameContext, GameContext};

export default GameProvider;
