import { useGLTF } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import { IPsign } from 'assets/models';
import InteractivePoints from 'components/InteractivePoints';
import Model from 'components/Model';
import { useGameContext } from 'context/gameContext';
import React, { Suspense, useEffect, useState } from 'react';

useGLTF.preload(IPsign);

const scale = 3.7;

const IPsignScene = () => { 
	const {currentModelData, onSetActiveLayer,activeModelLayer, onStoryEvent} = useGameContext();
	const { scene } = useThree();
	// const [IpColor, setIpColor] = useState<boolean>(false);

	// const IpHandler = () =>{
	// 	setIpColor(true);
	// };


	useEffect(() => {
		const object = scene.getObjectByName('7-0-groen' );
		if(activeModelLayer == 1){
			if(object){
				object.visible = false;
			}else{
				setTimeout(() => {
					scene.getObjectByName('7-0-groen' ).visible = false;
				}, 100);
			}
		}
	}, []);

	const onInteractivePointClick = (point) => {
		switch(point.name){
		case '01':
			// IpHandler();
			if(scene) {
				scene.getObjectByName( '7-0-groen' ).visible = true;
				onStoryEvent && onStoryEvent({type: 'openDialogue', param: 'scene_03_requested_10'});
				onStoryEvent && onStoryEvent({type:'swapFragment', param:3});
				onSetActiveLayer && onSetActiveLayer(2);
			}
		}
	};
	return (
		<>
			<Suspense fallback={null}>
				{currentModelData && <Model customScale={scale}/>}
				{currentModelData && <InteractivePoints onInteractivePointClick={(name: string) => onInteractivePointClick(name)} customModelScale={scale}/>}
			</Suspense>
		</>
	);
};

export default IPsignScene;
