const storyChunks = {

	// Main Narratives & Sub Narratives

	'scene_04_main_01': {
		id: 'scene_04_main_01',
		narrative: {
			text: 'Dit is de gameroom van m’n dochter. Soms loopt ze te screamen want dan verliest ze FIFA door de slechte verbinding. ',
			audioId: 'gamekamer_00'
		},
		options: [
			{
				label: 'Wat leuk dat uw dochter gamet! Ik ga onderzoeken hoe ik het probleem kan oplossen.',
				value: 1,
			},
			{
				label: 'Haha meisjes die gamen, wat een verhaal. Even kijken wat ik kan doen.',
				value: 2
			},
			{
				label: 'Ik zal kijken wat er aan de hand is zodat de verbinding wat beter wordt.',
				value: 3
			}
		],
	},

	'scene_04_main_02': {
		id: 'scene_04_main_02',
		narrative: {
			text: 'ontbrekende reactie',
			audioId: 1
		},
	},


	// Requested Narrative 1

	'scene_04_requested_01':{
		id: 'scene_04_requested_01',
		narrative: {
			text: '...?',
			audioId: 1
		},
		options: [
			{
				label: 'Gamen gaat soepeler als je een kabel in het apparaat hebt in plaats van gebruikmaakt van de wifi. Dat kan ik voor u aanleggen als u dat wilt.',
				value: 1,
				storyId: 'scene_04_requested_02'
			},
			{
				label: 'Ah het probleem is overduidelijk, gamen gaat natuurlijk beter met een kabel in de console. Kan ik wel effe aanleggen. ',
				value: 2,
				storyId: 'scene_04_requested_03'
			},
			{
				label: 'Ik denk dat ik zie wat het gamen soepeler kan laten verlopen. Een kabel kan de vertragingen tijdens het gamen verhelpen. Ik kan dit voor u aanleggen, wilt u dat?',
				value: 3,
				storyId: 'scene_04_requested_04'
			},
		],
	},

	//#region scene_04_requested_01 options

	'scene_04_requested_02': {
		id: 'scene_04_requested_02',
		narrative: {
			text: 'Oké dat is goed, ik heb nog wel kabels in deze kamer liggen.',
			audioId: 'gamekamer_02'
		},
	},

	'scene_04_requested_03': {
		id: 'scene_04_requested_03',
		narrative: {
			text: 'Bij veel games gaat het anders prima hoor! Maar prima, leg maar aan, er liggen nog wel wat kabels hier in de kamer.',
			audioId: 'gamekamer_03'
		},
	},

	'scene_04_requested_04': {
		id: 'scene_04_requested_04',
		narrative: {
			text: 'Ik denk dat mijn dochter daar heel blij van wordt. Graag, er liggen nog kabels in de gameroom.',
			audioId: 'gamekamer_04'
		},
	},


	//#endregion

	// Requested Narrative 2

	'scene_04_requested_05':{
		id: 'scene_04_requested_05',
		narrative: {
			text: '...?',
			audioId: 1
		},
		options: [
			{
				label: 'Ik moet hier even naar binnen om een kabel te zoeken.',
				value: 1,
				storyId: 'scene_04_requested_06'
			},
			{
				label: 'Ik mag hier niet in, maar weet u of hier een kabel ligt?',
				value: 2,
				storyId: 'scene_04_requested_07'

			},
			{
				label: 'Ik mag deze kamer niet in zonder uw toestemming, ligt hier een kabel?',
				value: 3,
				storyId: 'scene_04_requested_08'
			}
		],
	},

	//#region scene_04_requested_05 options

	'scene_04_requested_06': {
		id: 'scene_04_requested_06',
		narrative: {
			text: 'Nee, dat is privé.',
			audioId: 'gamekamer_06'
		},
	},

	'scene_04_requested_07': {
		id: 'scene_04_requested_07',
		narrative: {
			text: 'Nee, daar ligt niets.',
			audioId:  'gamekamer_07'
		},
	},

	'scene_04_requested_08': {
		id: 'scene_04_requested_08',
		narrative: {
			text: 'Dat begrijp ik, maar daar ligt geen kabel. ',
			audioId:  'gamekamer_08'
		},
	},
	'scene_04_requested_09': {
		id: 'scene_04_requested_09',
		narrative: {
			text: 'Deze pinball machine heb ik zelf gemaakt. ',
			audioId:  'gamekamer_14'
		},
	},

	'scene_04_requested_10': {
		id: 'scene_04_requested_10',
		narrative: {
			text: 'Hey, je hebt de goede kabel gevonden. Sluit jij hem even aan?',
			audioId:  'gamekamer_15'
		},
	},
	
	
	

	//#endregion


	// Automatic Narratives

	'scene_04_automatic_01': {
		id: 'scene_04_automatic_01',
		narrative: {
			text: 'Hé awesome, je hebt een kabel gevonden. ',
			audioId: 'gamekamer_09'
		},
		options: [
			{
				label: 'Deze kabel is niet bruikbaar op de PlayStation. Weet u of er andere kabels liggen?',
				value: 1,
				storyId: 'scene_04_automatic_02'
			},
			{
				label: 'Dit is niet de goede kabel, deze is voor telefoons. Is er nog een kabel in deze ruimte?',
				value: 2,
				storyId: 'scene_04_automatic_03'

			},
			{
				label: 'Ja hoor helemaal prima, ik kan deze kabel gebruiken.',
				value: 3,
				storyId: 'scene_04_automatic_04'
			}
		],
	},

	//#region scene_04_automatic_01 options

	'scene_04_automatic_02': {
		id: 'scene_04_automatic_02',
		narrative: {
			text: 'Damn, je hebt gelijk! Er ligt nog wel een kabel ergens in de gameroom.',
			audioId:  'gamekamer_10'
		},
	},

	'scene_04_automatic_03': {
		id: 'scene_04_automatic_03',
		narrative: {
			text: 'Ja hoor, in de kamer ligt nog wel een kabel.',
			audioId: 'gamekamer_11'
		},
	},

	'scene_04_automatic_04': {
		id: 'scene_04_automatic_04',
		narrative: {
			text: 'Wacht dit is niet de goede kabel, toch? Er moet nog een kabel ergens in de ruimte zijn.',
			audioId: 'gamekamer_12'
		},
	},

	//#endregion

	'scene_04_automatic_05': {
		id: 'scene_04_automatic_05',
		narrative: {
			text: 'Nu moeten we natuurlijk de snelheid testen. Ik challenge je voor een potje FIFA!',
			audioId: 'gamekamer_13'
		},
		options: [
			{
				label: 'Tuurlijk! Ik heb nog wel wat tijd over, het is toch bijna lunchpauze. Ben wel heel goed dus pas maar op.',
				value: 1,
				//storyId: 'scene_04_automatic_06'

			},
			{
				label: 'Dat zou ik graag willen, maar dit doe ik niet onder werktijd. Ik laat het testen aan de echte gamer over, uw dochter!',
				value: 2,
				//storyId: 'scene_04_automatic_06'

			},
			{
				label: 'Nee, dat mag ik niet onder werktijd. Ik laat het gamen wel aan u en uw dochter over, want nu doet alles het weer.',
				value: 3,
				//storyId: 'scene_04_automatic_06'

			}
		],
	},

	'scene_04_automatic_06': {
		id: 'scene_04_automatic_06',
		narrative: {
			text: 'ontbrekende reactie',
		},
	},
	'scene_04_automatic_07': {
		id: 'scene_04_automatic_07',
		narrative: {
			text: 'Helaas... Mijn zelfgemaakte flipperkast laat je zien hoe je uit deze kamer komt. Misschien dat er dan een lampje gaat branden.',
		},
	},
};

export default storyChunks;