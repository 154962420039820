import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { IntroBackground } from 'assets/images';
import { proportional } from 'util/proportional';
import Button from 'components/Button';
import { useGameContext } from 'context/gameContext';
import Title from 'components/text/Title';
import { motion } from 'framer-motion';
import SplitText from 'components/SplitText';

const Screen = styled.div.attrs({})`
    
    position: absolute;

    left:0;
    top:0;

    width:100vw;
    height:100vh;

    background-image: url(${IntroBackground});
    background-position: left;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: black;

    z-index: 10;

`;

const Content = styled.div.attrs({width: [690, 800], padding:[,100]})`

    ${proportional}

    position: absolute;

    left:0;
    top:0;
    bottom:0;

    display: flex; 
    flex-direction: column;
    justify-content: center;

    text-align: left;
    /* background: rgb(0,0,0);
background: linear-gradient(90deg, rgba(0,0,0,0.7959558823529411) 0%, rgba(0,0,0,0.20211834733893552) 80%, rgba(0,0,0,0) 100%); */

`;



const ButtonWrapper = styled(motion.div).attrs({})`
`;


const TextWrapper = styled.div.attrs({marginBottom: [,50]})`
    ${proportional};

`;



const IntroductionScreen = () => {

	const {setGameStarted, gameStarted} = useGameContext();

	const [screenEnabled, setScreenEnabled] = useState(true);

	return (
		<>
			{screenEnabled && !gameStarted && (
				<Screen>
					<Content>
						<Title variant="h1">
							<SplitText text={'Escape Game'} chars/>
						</Title>

						<TextWrapper>
							<Title variant="p">
								<SplitText baseDelay={2} text={'In deze game ben jij een monteur. Je gaat langs bij een klant, Robèrt, om een storing op te lossen. Hij heeft last van meerdere storingen in zijn huis. Aan jou de taak om per ruimte te onderzoeken wat je kunt doen.'} words/>
							</Title>
							<Title variant="p">
								<SplitText baseDelay={4} text={'Tijdens de game wordt je speeltijd bijgehouden. Ben je er klaar voor? Druk op begin om het spel te starten!'} words/>
							</Title>
						</TextWrapper>
						<ButtonWrapper animate={{opacity: 1, x: 0, y: 0}} transition={{delay: 5, x: 15, y:15}} initial={{opacity: 0}}><Button onClick={() => {setScreenEnabled(false); setGameStarted && setGameStarted(true);}}>Begin</Button></ButtonWrapper>
						{/* todo: removed when finished */}
						{/* <ButtonWrapper><Button onClick={() => {setScreenEnabled(false); setGameStarted && setGameStarted(true);}}>Begin</Button></ButtonWrapper> */}
					</Content>
				</Screen>
			)}
		</>
	);
};



export default IntroductionScreen;
