import { render_caddy_02_p2 } from 'assets/renders';
import { getHotspots } from '../Hotspots';
import getConfig from './universalConfig';

const customConfig = {
	imageSource: render_caddy_02_p2,
};

// action: {label: changeScene | changeSceneFragment, value: string | number};

let customHotspots = [
	{
		'pitch': -50.425505912929516,
		'yaw':  -3.2806838287118296,
		'type': 'info',
		'createTooltipArgs': {
			'text': 'Rommel',
			'type': 'scene',
			'sceneId': 'rommel2',
		}
	},
];

// Asign standard hotspot config
customHotspots = getHotspots(customHotspots);
customConfig['hotSpots'] = customHotspots;

const Scene = {
	sceneId: 'caddy_02_p2',
	config: getConfig(customConfig),
};

export default Scene;