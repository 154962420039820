import { useGLTF } from '@react-three/drei';
import { Dashboard } from 'assets/models';
import InteractivePoints from 'components/InteractivePoints';
import Model from 'components/Model';
import { useGameContext } from 'context/gameContext';
import React, { Suspense, useEffect } from 'react';

useGLTF.preload(Dashboard);

const scale = 2;

const DashboardScene = () => { 
	const {currentModelData, onAddActiveAnimation, onSetActiveLayer, onUpdateActiveModel, activeAnimations, activeModelLayer, modelIntroDone, onStoryEvent, setControls, queueFragmentSwap} = useGameContext();

	const onInteractivePointClick = (point) => {
		switch(point.name){
		case '01':
			// onAddActiveAnimation && onAddActiveAnimation({nodeName: 'dashboard_kastje', animationName:'dashboard kastje_open'});
			// onSetActiveLayer && onSetActiveLayer(2);
			break;
		case '02':
			onAddActiveAnimation && onAddActiveAnimation({nodeName: 'Tablet', animationName:'tablet_grab'});
			queueFragmentSwap && queueFragmentSwap(5);
			onSetActiveLayer && onSetActiveLayer(4);
			break;
		}
	};

	useEffect(() => {
		if(modelIntroDone){
			onAddActiveAnimation && onAddActiveAnimation({nodeName: 'dashboard_kastje', animationName:'dashboard kastje_open'});
			onSetActiveLayer && onSetActiveLayer(2);
		}
	}, [modelIntroDone]);
	
	useEffect(() => {
		currentModelData && onSetActiveLayer && onSetActiveLayer(-1);
		// setControls && setControls(false);

		return () => {
			// setControls && setControls(true);
		};
	}, []);

	useEffect(() => {
		if(activeAnimations && activeAnimations.length === 0){
			switch(activeModelLayer){

			case 4:	
				// onAddActiveAnimation && onAddActiveAnimation({nodeName: 'Tablet', animationName:'tablet_grab'});
				// onAddActiveAnimation && onAddActiveAnimation({nodeName: 'Smartphone', animationName:'smartphone_grab'});
				onStoryEvent && onStoryEvent({type:'swapQueuedFragment'});
				onUpdateActiveModel && onUpdateActiveModel(null);
				break;
			}
		}
	}, [activeAnimations]);

	return (
		<>
			<Suspense fallback={null}>
				{currentModelData && <Model customScale={scale}/>}
				{currentModelData && <InteractivePoints onInteractivePointClick={(name: string) => onInteractivePointClick(name)} customModelScale={scale}/>}
			</Suspense>
		</>
	);
};

export default DashboardScene;
