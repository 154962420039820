import React from 'react';
import styled from 'styled-components';
import { proportional } from 'util/proportional';
import Title from '../text/Title';

const ButtonWrap = styled.button.attrs({'borderRadius': [20, 20], 'paddingLeft': [30,30], 'paddingRight':[30,30], 'paddingTop': [10,10], 'paddingBottom': [10,10]})`
	${proportional};
	font-size: 1.3rem;

	background-color: ${(props) => props?.theme?.colors?.accent_1};
	border: none;
	outline: none;

	cursor: pointer;

	font-size: 14px;
    height: auto;
    line-height: 1em;
    border-radius: 20px;


	&:hover, :focus{
		background-color: ${(props) => props?.theme?.colors?.accent_1_dark};
	}

`;

const Button = ({children, onClick}:any) => {
	return <ButtonWrap onClick={onClick}>
		<Title variant='p2'>{children}</Title>
	</ButtonWrap>;
};

export default Button;