import { render_werkkamer_01_p2 } from 'assets/renders';
import { getHotspots } from '../Hotspots';
import getConfig from './universalConfig';

const customConfig = {
	imageSource: render_werkkamer_01_p2,

};

// action: {label: changeScene | changeSceneFragment, value: string | number};

let customHotspots = [
	{
		'pitch': -21.093649075706544,
		'yaw': -125.87723215833638,
		'type': 'info',
		'createTooltipArgs': {
			'text': 'printer',
			'type': 'scene',
			'sceneId': 'printer',
		}
	},
	{
		'pitch': -32.216995416109822,
		'yaw': -115.27796888385,
		'type': 'info',
		'createTooltipArgs': {
			'text': 'monitor',
			'type': 'scene',
			'sceneId': 'monitor_oud',
		}
	},	
	{
		'pitch': -10.91727,
		'yaw': 127.415,
		'type': 'info',
		'createTooltipArgs': {
			'text': 'numpad',
			'type': 'scene',
			'sceneId': 'numpad_werkkamer',
		}
	},	
	{
		'pitch': 9.6495556,
		'yaw': 5.7551476,
		'type': 'info',
		'createTooltipArgs': {
			'text': 'IP adres',
			'type': 'scene',
			'sceneId': 'ipsign',
		}
	},
	{
		'pitch': -22.52049,
		'yaw': 68.9055,
		'type': 'info',
		'createTooltipArgs': {
			'text': 'foto camera',
			'type': 'scene',
			'sceneId': 'fotocamera',
		}
	},	
	{
		'pitch': -21.29748,
		'yaw': 28.5103,
		'type': 'info',
		'createTooltipArgs': {
			'text': 'toetsenbord',
			'type': 'scene',
			'sceneId': 'toetsenbord',
		}
	},
];

// Asign standard hotspot config
customHotspots = getHotspots(customHotspots);
customConfig['hotSpots'] = customHotspots;

const Scene = {
	sceneId: 'werkkamer_03_p2',
	config: getConfig(customConfig),
	startingPitch: 9.6495556,
	startingYaw: 5.7551476,
};

export default Scene;

