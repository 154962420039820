import { render_caddy_01_p1 } from 'assets/renders';
import { getHotspots } from '../Hotspots';
import getConfig from './universalConfig';

const customConfig = {
	imageSource: render_caddy_01_p1,
};

// action: {label: changeScene | changeSceneFragment, value: string | number};

let customHotspots = [
	// {
	// 	'pitch': -16.971,
	// 	'yaw': 2.022,
	// 	'type': 'scene',
	// 	'createTooltipArgs': {
	// 		'text': '',
	// 		action: {
	// 			'label': 'navigation',
	// 			value: 1,
	// 		}
	// 	}
	// },

	{
		'pitch': -47.93311212683426,
		'yaw': -3.0380899287890784,
		'type': 'info',
		'createTooltipArgs': {
			'text': 'Rommel',
			'type': 'scene',
			'sceneId': 'rommel1',
		}
	},
];

// Asign standard hotspot config
customHotspots = getHotspots(customHotspots);
customConfig['hotSpots'] = customHotspots;

const Scene = {
	sceneId: 'caddy_01_p1',
	config: getConfig(customConfig),
};

export default Scene;