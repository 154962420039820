const storyChunks = {

	// Requested Narrative 1

	'scene_02_requested_01':{
		id: 'scene_02_requested_01',
		narrative: {
			text: '...?',
			audioId: 1
		},
		options: [
			{
				label: 'Wat een vreemd ding! U gaat me toch niet vertellen dat u klokken verzamelt?',
				value: 1,
				storyId: 'scene_02_requested_02'
			},
			{
				label: 'Wat een mooie koekoeksklok! Spaart u toevallig klokken?',
				value: 2,
				storyId: 'scene_02_requested_03'

			},
			{
				label: 'Die klok is wel geinig zeg, spaart u klokken ofzo?',
				value: 3,
				storyId: 'scene_02_requested_04'

			}
		],
	},

	//#region scene_02_requested_01 options

	'scene_02_requested_02': {
		id: 'scene_02_requested_02',
		narrative: {
			text: 'Wat ben je rude! Koekoeksklokken zijn heel bijzondere objecten die heel veel waard zijn.',
			audioId: 'woonkamer_01'
		},
	},

	'scene_02_requested_03': {
		id: 'scene_02_requested_03',
		narrative: {
			text: 'Dank je wel! Dit was de eerste klok die ik voor mijn verzameling kocht, ik ben al jaren bezig met de mooiste klokken verzamelen.',
			audioId: 'woonkamer_02'

		},
	},

	'scene_02_requested_04': {
		id: 'scene_02_requested_04',
		narrative: {
			text: 'Ja ik heb best wat klokken inderdaad.',
			audioId: 'woonkamer_03'
		},
	},


	//#endregion

	// Requested Narrative 2

	'scene_02_requested_05':{
		id: 'scene_02_requested_05',
		narrative: {
			text: '...?',
			audioId: 1
		},
		options: [
			{
				label: 'Het valt me op dat de tv flikkerend beeld heeft. Gebeurt dit vaker?',
				value: 1,
				storyId: 'scene_02_requested_06'
			},
			{
				label: 'Zou de storing door uw tv kunnen komen? Het beeld flikkert vast vaker.',
				value: 2,
				storyId: 'scene_02_requested_07'

			},
			{
				label: 'Ah ik zie het al, het beeld flikkert! De storing komt door uw tv.',
				value: 3,
				storyId: 'scene_02_requested_08'
			}
		],
	},

	//#region scene_02_requested_05 options

	'scene_02_requested_06': {
		id: 'scene_02_requested_06',
		narrative: {
			text: 'Ja dit gebeurt vaker, wel raar want de tv is brand new.',
			audioId: 'woonkamer_05'
		},
	},

	'scene_02_requested_07': {
		id: 'scene_02_requested_07',
		narrative: {
			text: 'Jij bent de expert, toch? De tv is brand new dus dit beeld zou niet zo moeten flikkeren. ',
			audioId: 'woonkamer_06'
		},
	},

	'scene_02_requested_08': {
		id: 'scene_02_requested_08',
		narrative: {
			text: 'Hoe weet jij dat? Die tv is brand new!',
			audioId: 'woonkamer_07'
		},
	},

	//#endregion

	// Requested Narrative 3

	'scene_02_requested_09':{
		id: 'scene_02_requested_09',
		narrative: {
			text: '...?',
			audioId: 1
		},
		options: [
			{
				label: 'Ah ik zie het al; u heeft dit zelf aangesloten? De volgorde van de kabels is duidelijk niet goed. Maar dit kan ik voor u oplossen.',
				value: 1,
				storyId: 'scene_02_requested_10'
			},
			{
				label: 'Heeft u toevallig zelf de nieuwe televisie geïnstalleerd? Ik zie dat er een fout in de kabel zit. Dit is niet erg hoor, ik heb het zo gepiept.',
				value: 2,
				storyId: 'scene_02_requested_10'

			},
			{
				label: 'Iedere kabel is weer anders! Ik zie dat u twisted 8 pair kabels heeft. En de manier waarop dit nu is aangesloten klopt niet, dit zal ik even voor u aanpassen.',
				value: 3,
				storyId: 'scene_02_requested_10'
			}
		],
	},

	//#region scene_02_requested_09 options

	'scene_02_requested_10': {
		id: 'scene_02_requested_10',
		narrative: {
			text: 'Yes, ik doe eigenlijk alles zelf! Kan me niet voorstellen dat ik een fout heb gemaakt, maar whatever. Pas jij het maar even aan!',
			audioId: 'woonkamer_09'
		},
	},
	'scene_02_requested_11': {
		id: 'scene_02_requested_11',
		narrative: {
			text: 'Handleiding, nee dat heb ik niet nodig weetje. ',
			audioId: 'woonkamer_12'
		},
	},
	'scene_02_requested_12': {
		id: 'scene_02_requested_12',
		// nextStoryId: 'scene_02_requested_12_02',
		narrative: {
			text: 'Het is pas tijd om te gaan als je naar mn klokken hebt gekeken.',
			audioId: 'woonkamer_13'
		},
	},
	'scene_02_requested_12_02': {
		id: 'scene_02_requested_12_02',
		narrative: {
			text: 'Het is pas tijd om te gaan als je naar mn klokken hebt gekeken.',
			// audioId: 'woonkamer_13'
		},
	},
	'scene_02_requested_13': {
		id: 'scene_02_requested_13',
		narrative: {
			text: 'Sommige klokken vertellen meer dan alleen de tijd. ',
			audioId: 'woonkamer_14'
		},
	},
	'scene_02_requested_14': {
		id: 'scene_02_requested_14',
		narrative: {
			text: 'Mooi hé. Deze klok heeft een bijzondere achterkant. ',
			audioId: 'woonkamer_15'
		},
	},

	//#endregion

	// Automatic Narratives

	'scene_02_automatic_01': {
		id: 'scene_02_automatic_01',
		narrative: {
			text: 'Die speakers zijn laatst uitgevallen. Zou jij die anders ook effe kunnen fixen? Ik heb de ladder al voor je klaargezet.',
			audioId: 'woonkamer_10'
		},
		options: [
			{
				label: 'Dit hoort helaas niet bij mijn service, maar ik kan u het visitekaartje geven van een bedrijfje dat u kan helpen. ',
				value: 1,
				storyId: 'scene_02_automatic_02'
			},
			{
				label: 'Ja joh geen probleem, dit kan ik wel oplossen. Voor een extra bedrag kan ik dit voor u fiksen!',
				value: 2,
				storyId: 'scene_02_automatic_02'

			},
			{
				label: 'Helaas behoort dit niet tot de service die ik u kan aanbieden tijdens mijn bezoek. Ik mag niet op een ladder staan. ',
				value: 3,
				storyId: 'scene_02_automatic_02'
			}
		],
		storyEvent: {
			type:'queueFragmentSwap',
			param: 2,
		}
	},

	//#region scene_02_requested_09 options

	'scene_02_automatic_02': {
		id: 'scene_02_automatic_02',
		narrative: {
			text: 'Oh wacht, dit hoort of course niet tot jouw service. Ik kijk er dan later zelf wel naar.',
			audioId: 'woonkamer_11'
		},
		storyEvent: {
			type:'swapFragment',
			param: 5
		}
	},
};

export default storyChunks;