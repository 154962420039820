import React, { Suspense, useContext, useEffect, useRef, useState } from 'react';
//// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {
	Canvas, useThree, useFrame, context
} from '@react-three/fiber';
import ModelRenderer from 'components/ModelRenderer';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { ThreeBackdrop } from 'components/ThreeBackdrop';
import styled from 'styled-components';
import GameProvider, { GameContext, useGameContext } from 'context/gameContext';
import AudioProvider, { AudioContext } from 'context/AudioContext';
import { proportional } from 'util/proportional';
import { CrossNormal, CrossHover } from 'assets/icons';
import InteractivePoints from 'components/InteractivePoints';


const CloseButton = styled.div.attrs({height:[,50], width:[,50], margin:[,30]})`

	${proportional};


	background-image: url(${CrossNormal});
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;

	position: absolute;
	right:0;
	top:0;
	z-index: 4;

	cursor: pointer;

	&:hover{
		background-image: url(${CrossHover});
	}
`;

const Light = () => {
	const ref = useRef();
	return (
		<>
			<ambientLight intensity={0.5} />
			<directionalLight
				ref={ref}
				intensity={0.6}
				position={[0, 2, 2]}
				shadow-mapSize-width={2048}
				shadow-mapSize-height={2048}
				castShadow
			/>
		</>
	);
};

const CameraController = () => {
	const {controls} = useGameContext();
	

	const { camera, gl } = useThree();
	const controlsRef = useRef<OrbitControls>(null);
	useEffect(
		() => {
			const controls = new OrbitControls(camera, gl.domElement);
			controls.minDistance = 0;
			controls.maxDistance = 99;
			controls.enableZoom = false;
			controls.enablePan = false;
			camera.position.set(0, 0, 2);
			controls.enableDamping = true;
			controls.dampingVector = 1;

			controlsRef.current = controls;
			return () => {
				if(controlsRef.current) {
					controlsRef.current.dispose();
				}
			};
		},
		[camera, gl]
	);

	useFrame(() => {
		if(controlsRef.current) {
			controlsRef.current.update();
		}
	});

	useEffect(() => {
		if(controls != controlsRef.current.enabled){
			controlsRef.current.enabled = controls;
		}
	}, [controls]);



	return null;
};

// eslint-disable-next-line react/display-name
export default (): JSX.Element => {

	// const { scene, onUpdateScene, sceneActive, onUpdateSceneActive} = useSceneContext();
	const {activeModel, onUpdateActiveModel, interactivePointsDisplay, closeButtonActive} = useGameContext();
	const gameContextValues = useContext(GameContext);
	const audioContextValues = useContext(AudioContext);



	const onCloseScene = () => {
		onUpdateActiveModel && onUpdateActiveModel(null);
	};

	return (<>
		{activeModel &&
		(
			<div className="canvas-container" id="canvas-container">
				{interactivePointsDisplay && closeButtonActive && <CloseButton onClick={onCloseScene}/>}
				<ThreeBackdrop/>
				<Canvas>
					{/*  //eslint-disable-next-line @typescript-eslint/ban-ts-comment     // @ts-ignore  */}
					<AudioContext.Provider value={audioContextValues}>
						<GameContext.Provider value={gameContextValues}>
							<>
								<CameraController/>
								<Suspense fallback={<mesh>loading</mesh>}>
									<Light/>
									<ModelRenderer activeModal={activeModel}/>
								</Suspense>
							</>
						</GameContext.Provider>
					</AudioContext.Provider>
				</Canvas>
			</div>
		)}
	</>);
};
