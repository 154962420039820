import { sceneProps } from 'data';

const Scene: sceneProps = 	{
	id: 6,
	story: {
		narrative: {
			'main': [],
			'subScenes': [],
			'requested': [
				'scene_05_requested_01', 
				'scene_05_requested_02', 
				'scene_05_requested_03',
				'scene_05_requested_04', 
				'scene_05_requested_05', 
				'scene_05_requested_06',
				'scene_05_requested_07', 
				'scene_05_requested_08', 
				'scene_05_requested_09', 
				'scene_05_requested_10', 
				'scene_05_requested_11', 
				'scene_05_requested_12', 
				'scene_05_requested_13', 
				'scene_05_requested_14', 
				'scene_05_requested_15'
			],
			'requestedAtStart': [
			],
		},
	},
	pannellumData: [
		'keuken_01_p1',
		'keuken_02_p1',
		'keuken_03_p1',
	]
};

export default Scene;