import { render_caddy_05_p1 } from 'assets/renders';
import { getHotspots } from '../Hotspots';
import getConfig from './universalConfig';

const customConfig = {
	imageSource: render_caddy_05_p1,
};

// action: {label: changeScene | changeSceneFragment, value: string | number};

let customHotspots = [
	{
		'pitch': -12.300,
		'yaw': 26.2743,
		'type': 'info',
		'createTooltipArgs': {
			'text': 'Tablet',
			'type': 'scene',
			'sceneId': 'tablet_caddy',
		}
	},
];

// Asign standard hotspot config
customHotspots = getHotspots(customHotspots);
customConfig['hotSpots'] = customHotspots;

const Scene = {
	sceneId: 'caddy_05_p1',
	config: getConfig(customConfig),
};

export default Scene;