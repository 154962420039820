import { useGLTF } from '@react-three/drei';
import {  Printer } from 'assets/models';
import InteractivePoints from 'components/InteractivePoints';
import Model from 'components/Model';
import { useGameContext } from 'context/gameContext';
import React, { Suspense, useEffect } from 'react';

useGLTF.preload(Printer);

const scale = 4;

const PrinterScene = () => { 
	const {currentModelData, onAddActiveAnimation, activeAnimations, onUpdateActiveModel, onSetActiveLayer, onStoryEvent, modelIntroDone, activeModelLayer} = useGameContext();

	const onInteractivePointClick = (point) => {
		switch(point.name){
		case '01':
			onAddActiveAnimation && onAddActiveAnimation({nodeName: 'papier', animationName:'papierAction'});
			onSetActiveLayer && onSetActiveLayer(2);
		}
	};

	

	useEffect(() => {
		if(modelIntroDone){
			if(activeModelLayer == 3){
				onAddActiveAnimation && onAddActiveAnimation({nodeName: 'papier', animationName:'papierAction'});
			}
			if(activeModelLayer == 1){
				// onStoryEvent && onStoryEvent({type: 'openDialogue', param: 'scene_03_requested_12'});
			}
		}
	}, [modelIntroDone]);
	
	useEffect(() => {
		if(activeAnimations && activeAnimations.length === 0){
			switch(activeModelLayer){

			case 2:
				onStoryEvent && onStoryEvent({type: 'openDialogue', param: 'scene_03_requested_12'});
				onSetActiveLayer && onSetActiveLayer(3);

				break;
			}

		}
	}, [activeAnimations]);

	return (
		<>
			<Suspense fallback={null}>
				{currentModelData && <Model customScale={scale}/>}
				{currentModelData && <InteractivePoints onInteractivePointClick={(name: string) => onInteractivePointClick(name)} customModelScale={scale} />}
			</Suspense>
		</>
	);
};

export default PrinterScene;
