import Input from 'components/Input';
import { modelScenes } from 'constants/Scenes';
import {narativeSceneName} from '../../data';
import {useGameContext } from 'context/gameContext';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Interface } from 'readline';

const InterfaceContainer = styled.div`
    z-index: 10;
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    align-items: center;
`;

const ButtonContainer = styled.div`
	z-index: 5;
	position: absolute;
	top: 20px;
	right: 20px;
`;

const InterfaceWrap = styled.div`
    z-index: 5;
    width: 60vw;
    background: white;
    height: 80vh;
    opacity: 1;
    border-radius: 15px;
    color: black;
    overflow: auto;
    text-align: left;
	padding: 20px;
	border: 10px solid pink;
	button {
		float: right;
	}
`;

const InterfaceRow = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    button {
        margin-left: 30px;
    }
	select {
		font-size: 24px;
	}
`;

const ColapseHeader = styled.div`
    display: flex;
    text-align: left;
    align-items: center;
    button {
        margin-left: 30px;
    }
`;

const ColapseContainer = styled.div<{open: boolean, padding: boolean}>`
    max-height: ${(props) => props.open ? 'unset' : '50px'};
    padding-left: ${(props) => props.padding ? '20px' : 0};
    overflow: hidden;
    margin-top: 5px;
    text-align: left;
`;

const ObjectItemList = styled.ul`
`;

const ObjectDisplay = styled.div`
padding: 20px;
`;

interface ColapsableItemProps {
	children: JSX.Element,
    title: string,
    bigTitle?: boolean,
    padding?: boolean,
}


const ObjectDisplayer = ({payload, title}: any) => {

	const keys = payload && Object.keys(payload);
	return (
		<ColapsableItem padding title={title}>
			<ObjectItemList>
				{!payload.isOptions && keys && keys.map((key) => (
					<p key={key}><b>{[key]}:</b> {payload[key]}</p>
				))}
			</ObjectItemList>   
		</ColapsableItem>
	);

};


const ObjectListDisplayer = ({payload, title}: any) => {
	// const keys = payload && Object.keys(payload);

	return (
		<ColapsableItem padding title={title}>
			<ObjectItemList>
				{payload.map((object, index) => (
					<ObjectDisplayer key={object.id | index} title={object.id | index + 1} payload={object}/>
				))}
			</ObjectItemList>   
		</ColapsableItem>
	);
};

const ColapsableItem = ({children, title, bigTitle = false, padding = false}: ColapsableItemProps) => {
	const [open, setOpen] = useState(false);
    
	return (
		<ColapseContainer padding={padding} open={open}>
			<ColapseHeader> {bigTitle ? <h2>{title}</h2> : <p><b>{title}</b></p>} <div><button onClick={() => setOpen((oldState) => !oldState)}>{open ? '-' : '+'}</button></div></ColapseHeader>
			{children}
		</ColapseContainer>
	);
};

interface selectInterface {
	options: any[]
	setSelected: (id: any) => void
	selected?: string,
	withNone?: boolean,
}

const Select = ({options, setSelected, selected, withNone = true}: selectInterface) => {
	const sceneSelectRef = useRef<HTMLSelectElement>(null);

	return (
		<select onChange={(e) => setSelected(e.target.value)} ref={sceneSelectRef} value={selected}>
			{withNone && <option label={'none'} value={''}/>}
			{options && options.map((opt) => (<option key={opt} label={String(opt)} value={opt}/>)) 
			}
		</select>
	);
};


const ModelStateInfo = () => {
	const {activeModel, onUpdateActiveModel, animationList, currentAnimationName, setCurrentAnimationName, triggerQueuedStoryEvent } = useGameContext();
		
	return (
		<> 
			<ObjectDisplay>
				<h4>Model data interface</h4>

				<InterfaceRow>
					<p>activeModel: {activeModel || 'there is no model selected'}</p>
					<Select options={modelScenes} selected={activeModel} setSelected={(val) => onUpdateActiveModel && onUpdateActiveModel(val)}/>
				</InterfaceRow>
				{animationList && <InterfaceRow>
					<p>active animation: {currentAnimationName}</p>
					<Select options={animationList} selected={currentAnimationName} setSelected={(val) => setCurrentAnimationName && setCurrentAnimationName(val)}/>
				</InterfaceRow>}
				<button onClick={triggerQueuedStoryEvent}></button>

			</ObjectDisplay>
		</>
	);
};

const StoryChunkPreview = ({previewData}: any) => {
	const {id, narrative, options} = previewData;
	return <div>
		<p>id: <b>{id}</b></p>
		<p>narrative: <b>{narrative.text}</b></p>
		<p>audioId: <b>{narrative.audioId}</b></p>
		<div>
			<br/>
			<b>OPTIONS:</b>
			{options && options.map((option) => {
				const {label, subStoryID, value} = option;
				return (
					<div style={{borderBottom: '2px solid black'}} key={label}>
						<p>value: <b>{value}</b></p>
						{subStoryID && <p>links to: <b>{subStoryID}</b></p>}
						<p>label: <b>{label}</b></p>
					</div>
				);
			})
			}
		</div>
	</div>;
};

const StoryStateInfo = () => { 
	const {storyChunkObject, activeStoryChunk, setActiveStoryChunk, getStoryChunk, dialogueButtonState, onSetDialogueButtonState, onIncrementStoryProgression, endOfGame, setEndOfGame} = useGameContext();

	const [previewStoryChunk, setPreviewStoryChunk] = useState<any>();
	
	const getStoryChunkOptions = () => {
		const keys = Object.keys(storyChunkObject);
		return keys.map((key) => {const chunks = storyChunkObject[key]; const ids = chunks.map((storyChunk) => storyChunk.id); return ids; }).flat();
	};	

	const storyChunkOptions = getStoryChunkOptions();

	const dialogueButtonStateOptions = [
		'hidden',
		'inactive',
		'active',
		'available'
	];

	useEffect(() => {
		setPreviewStoryChunk(getStoryChunk && getStoryChunk());
	}, [activeStoryChunk]);

	return (
		<div>
			{!endOfGame && <button onClick={() => setEndOfGame && setEndOfGame(true)}>END THIS GAME!</button>}
			{storyChunkOptions && <Select options={storyChunkOptions} selected={activeStoryChunk} setSelected={(val: narativeSceneName) => setActiveStoryChunk && setActiveStoryChunk(val)}/>}
			<div>
				the dialogue button is currently in state {dialogueButtonState};
				{dialogueButtonStateOptions && dialogueButtonState && <Select selected={dialogueButtonState} withNone options={dialogueButtonStateOptions} setSelected={(val: any) => onSetDialogueButtonState && onSetDialogueButtonState(val)}/>}
				<br/>
				<p> go to the next scene narrative<button onClick={() => onIncrementStoryProgression && onIncrementStoryProgression()}> next </button>
				</p>
				{/* the next dialogue to be opened is {nextRequestedDialogue}; */}
			</div>

			<div>
				{previewStoryChunk && <StoryChunkPreview previewData={previewStoryChunk}/>}
			</div>
		</div>
	);
};

const SceneInfo = () => { 
	const {currentScene, storyChunkObject, amountOfScenes, onUpdateScene, setSceneFragment, sceneFragment, setGameStarted} = useGameContext();
	const sceneSelectRef = useRef<HTMLSelectElement>(null);
	const fragmentSelectRef = useRef<HTMLSelectElement>(null);

	const onUpdateSelectedScene = () => {  const newValue = sceneSelectRef.current && sceneSelectRef.current.value;  newValue && onUpdateScene && onUpdateScene(Number(newValue));};
	const onUpdateFragment = () => { const newValue = fragmentSelectRef.current && fragmentSelectRef.current.value; newValue && setSceneFragment && setSceneFragment(Number(newValue));};

	const options = Array.from(Array(amountOfScenes).keys());
	const fragments = currentScene?.pannellumData;

	const skipIntroduction = () => {setGameStarted && setGameStarted(true);};

	return (
		<>
			<InterfaceRow>
				<button onClick={skipIntroduction}>skip intro</button>
			</InterfaceRow>

			<InterfaceRow>
				<p> the current scene is: <b>{currentScene && currentScene.id || 'not defined'}</b></p>
				<div>
					<select ref={sceneSelectRef}>
						{options && options.map((opt) => (<option key={opt} label={String(opt + 1)} value={opt + 1}/>)) 
						}
					</select>
					<button onClick={onUpdateSelectedScene}> set new Scene</button>
				</div>

			</InterfaceRow>

			<InterfaceRow>
				<div>
					<p> the current fragment is: <b>{sceneFragment && sceneFragment}</b></p>
					<select ref={fragmentSelectRef}>
						{fragments && fragments.map((opt, index) => {return(<option key={`key-${opt}-${index}`} label={`${index}`} value={index}/>);})
						}
					</select>
					<button onClick={onUpdateFragment}> set fragment</button>
				</div>
			</InterfaceRow>

			<InterfaceRow>
				<p> In scene <b>{currentScene && currentScene.id || 'not defined'}</b> the following <i>storyChunks</i> are used</p>
			</InterfaceRow>

                            
			<ObjectDisplay>
				{storyChunkObject && Object.keys(storyChunkObject).map((key) => {
					const storyChunkGroup = storyChunkObject[key];
					return (
						<ColapsableItem bigTitle title={key} key={key}>
							<>  
								{storyChunkGroup.map((scene) => (
									<ColapsableItem padding title={`- ${scene.id}`} key={scene.id}>
										<>
											{scene.narrative && 
                                                <ObjectDisplayer title='narrative' payload={scene.narrative}/>
											}
											{scene.options && 
                                                <ObjectListDisplayer title='options' payload={scene.options}/>
											}
										</>
									</ColapsableItem>
								))}
							</>
						</ColapsableItem>
					);
				})}
			</ObjectDisplay>
		</>
	);
};

const tabOptions = ['scene', 'storyState', 'modelState'];

const StoryInterface = () => {

	const {displayInterface, setDisplayInterface} = useGameContext();

	const [activeInterfaceItem, setActiveInterfaceItem] = useState('scene');
	const selectTabRef = useRef<HTMLSelectElement>(null);


	return <> {displayInterface && (<InterfaceContainer>
		<InterfaceWrap>
			{/* <button onClick={() => setDisplayInterface && setDisplayInterface(false)}> close interface</button> */}
			<InterfaceRow>
				<h2>Game interface</h2>
				<select onChange={(e) => setActiveInterfaceItem(e.target.value)} ref={selectTabRef} value={activeInterfaceItem}>
					{tabOptions && tabOptions.map((opt) => (<option key={opt} label={String(opt)} value={opt}/>)) 
					}
				</select>
			</InterfaceRow>
			{activeInterfaceItem === 'scene' && <SceneInfo/>}
			{activeInterfaceItem === 'storyState' && <StoryStateInfo/>}
			{activeInterfaceItem === 'modelState' && <ModelStateInfo/>}
		</InterfaceWrap>
	</InterfaceContainer>)}
	</>;
};


export default StoryInterface;
