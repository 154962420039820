import { useGLTF, Html } from '@react-three/drei';
import { Numpad } from 'assets/models';
import InteractivePoints from 'components/InteractivePoints';
import Model from 'components/Model';
import { useAudioContext } from 'context/AudioContext';
import { useGameContext } from 'context/gameContext';
import React, {Suspense, useEffect, useState} from 'react';

useGLTF.preload(Numpad);

const scale = 10;
const correctCode = '3148';

const NumpadSceneWoonkamer = () => { 
	const {currentModelData, onAddActiveAnimation, onStoryEvent, activeModelLayer, onUpdateActiveModel} = useGameContext();
	const { playSound } = useAudioContext();

	const [code, setCode] = useState<number[]>([]);
	const [screenText, setScreenText] = useState<string>('');

	const enterNumber = (number: number) => {
		setCode([...code, number]);
	};

	const checkCode = () => {
		if (code.join('') == correctCode) {
			//correct code entered
			setScreenText('correct');
			onStoryEvent && onStoryEvent({type:'swapFragment', param:6});
			onUpdateActiveModel && onUpdateActiveModel(null);

			//TODO: callback when code is correct
		}
		else{
			//wrong code entered
			playSound && playSound('code_wrong');
			setScreenText('wrong');

			onStoryEvent && onStoryEvent({type: 'openDialogue', param: 'scene_02_requested_12'});
			
		}

		resetCode();
	};

	const resetCode = (setText = false) => {
		if(setText) {
			setScreenText('reset');
		}
		setCode([]);
	};

	useEffect(() => {
		if(code && code.length > 0) {
			setScreenText(code.join(''));
		}

	}, [code]);

	const onInteractivePointClick = (point) => {
		switch(point.name){

		case '01':
			onAddActiveAnimation && onAddActiveAnimation({nodeName: 'Key_01', animationName:'Button_Press'});
			enterNumber(1);
			break;
		case '02':
			onAddActiveAnimation && onAddActiveAnimation({nodeName: 'Key_02', animationName:'Button_Press'});
			enterNumber(2);
			break;
		case '03':
			onAddActiveAnimation && onAddActiveAnimation({nodeName: 'Key_03', animationName:'Button_Press'});
			enterNumber(3);
			break;
		case '04':
			onAddActiveAnimation && onAddActiveAnimation({nodeName: 'Key_04', animationName:'Button_Press'});
			enterNumber(4);
			break;
		case '05':
			onAddActiveAnimation && onAddActiveAnimation({nodeName: 'Key_05', animationName:'Button_Press'});
			enterNumber(5);
			break;
		case '06':
			onAddActiveAnimation && onAddActiveAnimation({nodeName: 'Key_06', animationName:'Button_Press'});
			enterNumber(6);
			break;
		case '07':
			onAddActiveAnimation && onAddActiveAnimation({nodeName: 'Key_07', animationName:'Button_Press'});
			enterNumber(7);
			break;
		case '08':
			onAddActiveAnimation && onAddActiveAnimation({nodeName: 'Key_08', animationName:'Button_Press'});
			enterNumber(8);
			break;
		case '09':
			onAddActiveAnimation && onAddActiveAnimation({nodeName: 'Key_09', animationName:'Button_Press'});
			enterNumber(9);
			break;
		case '00':
			onAddActiveAnimation && onAddActiveAnimation({nodeName: 'Key_00', animationName:'Button_Press'});
			enterNumber(0);
			break;
		case '010':
			onAddActiveAnimation && onAddActiveAnimation({nodeName: 'Key_010', animationName:'Button_Press'});
			//green

			setTimeout(() => {
				checkCode();
			}, 1000);
			break;
		case '011':
			onAddActiveAnimation && onAddActiveAnimation({nodeName: 'Key_011', animationName:'Button_Press'});
			//red
			resetCode(true);
			break;
		}
	};

	return (
		<>
			<Suspense fallback={null}>
				{currentModelData && <Model customScale={scale}/>}
				{currentModelData && <InteractivePoints onInteractivePointClick={(name: string) => onInteractivePointClick(name)} customModelScale={scale} customPointScale={0.05}/>}
				<Html
					as='div' // Wrapping element (default: 'div')
					//fullscreen // Aligns to the upper-left corner, fills the screen (default:false) [ignored in transform mode]
					//prepend
					transform
					occlude
					position={[0,0.64,0.07]}
					scale={[0.18,0.18,0.18]}
				>
					<h1>{screenText}</h1>
				</Html>
			</Suspense>
		</>
	);
};

export default NumpadSceneWoonkamer;