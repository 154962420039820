import { useGLTF } from '@react-three/drei';
import {  KabelFout } from 'assets/models';
import InteractivePoints from 'components/InteractivePoints';
import Model from 'components/Model';
import { useGameContext } from 'context/gameContext';
import React, { Suspense, useEffect } from 'react';

useGLTF.preload(KabelFout);

const scale = 8;

const KabelFoutScene = () => { 
	const {currentModelData, onAddActiveAnimation, onSetActiveLayer, onStoryEvent, activeAnimations, activeModelLayer, modelIntroDone} = useGameContext();

	const onInteractivePointClick = (point) => {
		switch(point.name){
		case '01':
			onAddActiveAnimation && onAddActiveAnimation({nodeName: 'RJ45', animationName:'kabel_zoom'});
			onSetActiveLayer && onSetActiveLayer(2);
		}
	};

	useEffect(() => {
		if(activeModelLayer && modelIntroDone && activeAnimations && activeAnimations.length === 0){
			switch(activeModelLayer){

			case 2:	
				onStoryEvent && onStoryEvent({type:'setRequestedNarrative', param:'scene_04_automatic_01'});
				onSetActiveLayer && onSetActiveLayer(3);
				break;
			}
		}
	}, [activeAnimations]);

	return (
		<>
			<Suspense fallback={null}>
				{currentModelData && <Model customScale={scale}/>}
				{currentModelData && <InteractivePoints onInteractivePointClick={(name: string) => onInteractivePointClick(name)} customModelScale={scale} />}
			</Suspense>
		</>
	);
};

export default KabelFoutScene;
