import React, { useEffect, useState } from 'react';
import Window from 'components/Window2';
import styled from 'styled-components';
import { proportional } from 'util/proportional';
import Title from 'components/text/Title';
import Button from 'components/Button';
import { useGameContext } from 'context/gameContext';
import PDF from './PDF';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { story } from 'data';
import { ThermostaatScene } from 'components/CanvasContainer/scenes';
import { scorm, Scorm } from '@gamestdio/scorm';

interface QuestionProp {
	id: string,
	correctValue: number
	feedback: string[],
	feedbackEmpty?: string
}

const aandachtQuestions : QuestionProp[] = [
	{id: 'scene_00_requested_01', correctValue: 2,
		feedback: [
			'Geef geen oordeel maar toon meer begrip voor de situatie van de klant.',
			'Je bent vriendelijk maar toon meer begrip en wees minder dwingend in je communicatie naar de klant.',
			'Goed zo! Je toont begrip voor de klant en geeft een alternatief!',
		]
	},
	{id: 'scene_02_requested_01', correctValue: 1,
		feedback: [
			'Geef geen oordeel over dingen die je opvallen bij de klant! Stel je vraag vanuit oprechte aandacht.',
			'Goed zo! Je maakt een emo-opmerking en toont interesse in de klant door een vraag te stellen.',
			'Goed dat je een vraag stelt, uit je opmerking blijkt echter dat je niet echt geïnteresseerd bent in de klant.',
		]
	},
]; 

const aanvangQuestions : QuestionProp[] = [
	{id: 'scene_01_main_01', correctValue: 1,
		feedback: [
			'Val je collega monteurs nooit en toon meer begrip voor de impact van de storing op de klant.',
			'Goed zo! Je toont begrip en snapt de ernst van de situatie voor de klant!',
			'Toon meer begrip voor de impact van de storing voor de klant.',
		]
	},
	{id: 'scene_01_requested_01', correctValue: 0,
		feedback: [
			'Goed zo! Je bent duidelijk naar de klant zonder een oordeel te geven over de rommel, geeft aan wat de kosten zijn en brengt de boodschap op een klantgerichte manier!',
			'Je geeft aan dat de situatie onveilig is en wat de kosten zijn, je mag het nog wat meer klantgericht brengen.',
			'Wees minder negatief naar de klant, geef minder een oordeel over de klant en breng je boodschap klantgerichter.',
		],
		feedbackEmpty: 'Je hebt de kans gemist om met Robért te praten over het opruimen van de meterkast.'
	},
];

const aanbodQuestions : QuestionProp[] = [
	{id: 'scene_03_requested_02', correctValue: 2,
		feedback: [
			'Zorg dat je altijd weet wat een extra kabel of extra wifi punt kost!',
			'Geef duidelijk aan wat de voordelen van het aanbod zijn voor de klant zodat de klant makkelijker een keuze kan maken.',
			'Goed zo! Je geeft duidelijk de voordelen van het aanbod aan!',
		]
	},
	{id: 'scene_03_requested_06', correctValue: 1,
		feedback: [
			'Het is niet akkoord om naar het wachtwoord van de klant te vragen, maak een tijdelijk wachtwoord aan dat de klant nog kan aanpassen.',
			'Goed zo! Je bent duidelijk in wat je gaat doen en waarom!',
			'Het is niet klantvriendelijk om te vragen aan de klant of het goed is om een wachtwoord te verzinnen. Geef aan dat je een tijdelijk wachtwoord aanmaakt wat de klant nog kan aanpassen eventueel.',
		],
		feedbackEmpty: 'Je hebt niet met Robért gesproken over het instellen van een wachtwoord'
	},
	{id: 'scene_04_requested_01', correctValue: 2,
		feedback: [
			'Wees secuur in je analyse en stel de vraag of je het kunt aanleggen echt als vraag aan de klant en minder als een mededeling',
			'Praat niet te populair met de klant en vraag of de klant wil dat je iets aanlegt.',
			'Goed zo! Je hebt snel de analyse gedaan en een goed voorstel aan de klant gedaan!',
		]
	},
	{id: 'scene_04_automatic_05', correctValue: 1,
		feedback: [
			'Dit lijkt handig om te doen maar is het niet, gamen onder bedrijfstijd is niet de bedoeling.',
			'Goed zo! Mooi dat je aangeeft het testen aan de echte gamer over te laten!',
			'Je antwoord is correct maar je kunt het iets mooier verpakken en mist de kans om een mooie emo-opmerking te maken over de dochter van Robèrt.',
		]
	},
	{id: 'scene_05_requested_08', correctValue: 2,
		feedback: [
			'Focus minder op de extra kosten maar meer wat het extra televisiepakket oplevert voor de klant. En prijs het extra pakket dan ook enthousiast aan.',
			'Goed dat je uitleg geeft over het pakket, je kunt het extra televisiepakket nog wat enthousiaster aanprijzen.',
			'Goed zo! Je geeft duidelijke uitleg over het aanbod, zo kan de klant er bijna geen nee meer tegen zeggen!',
		]
	},
]; 

const analyseQuestions : QuestionProp[] = [
	{id: 'scene_01_requested_05', correctValue: 2,
		feedback: [
			'Je kunt de oorzaak van de kapotte kabel iets diplomatieker brengen en je mag de meerkosten positiever brengen naar de klant.',
			'Je laat te duidelijk blijken dat je baalt van de situatie en je kunt de meerkosten positiever brengen naar de klant.',
			'Goed zo! Je brengt de boodschap uitstekend en geeft duidelijk aan dat er meerkosten zijn voor vervanging!',
		],
		feedbackEmpty: 'Je hebt de kans gemist om betaald een kapotte kabel te vervangen.'

	},
	{id: 'scene_01_automatic_02', correctValue: 0,
		feedback: [
			'Goed zo! Je analyse is uitstekend!',
			'Goed dat je de kabels omwisselt, je weet echter nog niet of alles het weer doet, wees voorzichtig met deze uitspraak.',
			'Je zet jezelf als een topper neer en valt je collega monteur af. Je conclusie dat alles het nu weer doet is wellicht te voorbarig.',
		]
	},
	{id: 'scene_02_requested_05', correctValue: 0,
		feedback: [
			'Goed zo! Je benoemt wat je ziet en stelt een vraag aan de klant om de storing te toetsen!',
			'Je stelt een goede vraag aan de klant en trekt de conclusie dat de storing vast vaker gebeurt, wees daar voorzichtig mee.',
			'Je trekt een conclusie over de storing zonder bij de klant te checken, wees voorzichtig met dit soort aannames en stel een vraag.',
		],
		feedbackEmpty: 'Door niet met Robért te praten heeft er geen analysegeprek plaatsgevonden.'
	},
	{id: 'scene_02_requested_09', correctValue: 1,
		feedback: [
			'Geef geen oordeel over wat de klant verkeerd heeft gedaan, stel een vraag en wees positief over het kunnen oplossen.',
			'Goed zo! Je stelt je klantgericht op en communiceert positief!',
			'Je stelt je klantgericht en tactisch op, vermijd echter vakjargon met betrekking tot het soort kabels.',
		],
		feedbackEmpty: 'Je bent vergeten zelf het gesprek te starten over een verkeerd verbonden kabel.'
	},
	{id: 'scene_02_automatic_01', correctValue: 2,
		feedback: [
			'Je bent duidelijk over het feit dat je de klant niet kunt helpen, doorverwijzen naar andere bedrijven behoort echter niet tot je taken.',
			'Je mag niet op een beschadigde ladder staan.',
			'Goed zo! Je bent duidelijk over het gebruik van de ladder en communiceert positief met de klant!',
		]
	},
	{id: 'scene_03_main_01', correctValue: 0,
		feedback: [
			'Goed zo! Je toont begrip voor de klant en geeft duidelijk aan wat je gaat doen!',
			'Je toont beperkt begrip. Jij bent de expert die een speedtest uitvoert, het maakt niet uit of de klant dat ook al zelf gedaan heeft.',
			'Probeer niet te populair te zijn in je taalgebruik. Geef geen oordeel over wat de klant al dan niet zelf heeft geprobeerd.',
		]
	},
	{id: 'scene_04_main_01', correctValue: 0,
		feedback: [
			'Goed zo! Je maakt een emo-opmerking en geeft aan dat je gaat onderzoeken hoe je het probleem kunt oplossen!',
			'Je laat een eigen oordeel doorschemeren, doe dat niet. Je kunt in deze situatie ook een emo-opmerking maken over hoe leuk het is dat de dochter van Robèrt gamet.',
			'Goed dat je gelijk aangeeft dat je gaat onderzoeken hoe de verbinding beter kan worden. Je mist de kans om een emo-opmerking te maken over hoe leuk het is dat de dochter van Robèrt gamet.',
		]
	},
	{id: 'scene_04_requested_05', correctValue: 2,
		feedback: [
			'Wees respectvol tegen de klant en zeg ’mag ik’ in plaats van ‘ik moet’.',
			'Geef aan dat je toestemming nodig hebt van de klant om de dichte ruimte te betreden.',
			'Goed zo! Je stelt je respectvol op, vraagt om toestemming en stelt een vraag aan de klant!',
		]
	},
	{id: 'scene_04_automatic_01', correctValue: 0,
		feedback: [
			'Goed zo! Je bent duidelijk richting de klant en stelt een goede vraag!',
			'Wees specifieker dat deze kabel niet geschikt is voor de PlayStation.',
			'Ai, dit was een telefoonkabel en daar kun je helaas niets mee.',
		]
	},
];

const afsluitingQuestions : QuestionProp[] = [
	{id: 'scene_05_requested_03', correctValue: 0,
		feedback: [
			'Goed zo! Je bent respectvol en vriendelijk richting de klant en wijst de fooi af!',
			'Je mag inderdaad geen fooi aannemen, dus geef niet aan dat je dat ‘helaas’ niet mag aannemen, want dat geeft de indruk dat je het het liefste wel zou doen.',
			'Je mag geen fooi aannemen! Je kunt wel een opmerking maken dat je het fijn vindt dat de klant je werk waardeert.',
		]
	},
	{id: 'scene_05_requested_07', correctValue: 2,
		feedback: [
			'Wees iets specifieker waarom dit aanbod interessant is voor de klant en dan met name voor de dochter van Robèrt.',
			'Als je een aanbod doet aan de klant, zorg dan altijd dat de klant zich vrij voelt om zelf te beslissen.',
			'Goed zo! Je denkt mee met de klant en doet een extra aanbod!',
		],
		feedbackEmpty: 'Je hebt een belangrijk crossell moment gemist! Je had hier het kids-pakket aan Robért kunnen aanbieden.'

	},
]; 

type ScoringContentProps = {
	label: string,
	questions: QuestionProp[],

}

const scoringContent : ScoringContentProps[] = [
	{label: 'Aandacht', questions: aandachtQuestions},
	{label: 'Aanvang', questions: aanvangQuestions},
	{label: 'Analyse', questions: analyseQuestions},
	{label: 'Aanbod', questions: aanbodQuestions},
	{label: 'Afsluiting', questions: afsluitingQuestions},
];

const ScoreWrap = styled.div`
	margin-top: 20px;

`;
const TimerWrap = styled.div.attrs({padding: [20, 20], paddingLeft: [,70], paddingRight: [,70], borderRadius: [12, 12]})`
    ${proportional};
    border: 3px solid ${(props) => props.theme.colors.light_200};
    display: inline-block;
    display: inline-flex;
    /* justify-content: space-between; */
    align-items: center;
    text-align: center;
    h3 {
        margin-right: 20px;
        &:last-child {
            font-weight: 800;
        }
    }
`;

const DetailsWrap = styled.ul.attrs({padding: [20, 20], borderRadius: [12, 12]})`
    ${proportional};
    border: 3px solid ${(props) => props.theme.colors.light_200};
    list-style: none;
    padding: 0;
`;

const Detail = styled.li.attrs({marginTop:[5, 15], marginBottom:[5, 15],height: [38,38]})`
    ${proportional};
    display: flex;
    justify-content: space-between;
    align-items: center;
    h3 {
        margin: 0;
    }
    h3:first-child {
        flex: 30%;
        flex-shrink: 0;
        flex-grow: 0;
    }
    h3:last-child {
        flex:5%;
        text-align: center;
        flex-shrink: 0;
    }
`;

const ColoredTitle = styled(Title)`
    color: ${(props) => props.theme.colors.accent_1 ? props.theme.colors.accent_1 : 'green'};
`;

const ScoreBar = styled.div.attrs({height: [10, 10], marginRight: [23,23]})<{percentage: number}>`
    ${proportional};
    background-color: ${(props) => props.theme.colors.light_200};
    width: 100%;
    position: relative;
    flex: 50%;
    flex-shrink: 0;
    &:after {
        border-radius: inherit;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: inherit;
        width: ${(props) => props.percentage}%;
        background-color: ${(props) => props.theme.colors.accent_1};
    }
`;

const isCorrect = (storedAnswers, question) => {
	const storedAnswer = storedAnswers[question.id];
	return storedAnswer ? storedAnswer.value == question.correctValue : false;
};

const getPointsPerSegment = (storedAnswers, scoreData) => {
	const correctAnswers = scoreData.questions.filter((question) => isCorrect(storedAnswers, question));
	return correctAnswers && correctAnswers.length; 
};


const getScoring = (storedAnswers, label) => {
	const currentScoreData = scoringContent.find((dataSet) => dataSet.label === label);
	const allPossiblePoints = currentScoreData?.questions.length;
	const pointsAchieved = allPossiblePoints && getPointsPerSegment(storedAnswers, currentScoreData);
	let newLabel: any = null;
	const gotPoints = allPossiblePoints && allPossiblePoints >  0;
	if(allPossiblePoints) {
		newLabel = gotPoints ? `${pointsAchieved}/${allPossiblePoints}` : `${pointsAchieved + 1}/${allPossiblePoints + 1}`;
	} else {
		newLabel = '1/1';
	}
	return {
		label: newLabel,
		percentage: allPossiblePoints && pointsAchieved && gotPoints ? Math.round(pointsAchieved / allPossiblePoints * 100) : gotPoints ? 0 : 100
	};
};

const refreshPage = ()=>{
	window.location.reload();
};

const ScoreDisplay = () => {
	const {storedAnswers, totalTime} = useGameContext();

	useEffect(() => {
		scorm.set('cmi.core.lesson_status', 'completed');
	},[]);

	return (
		<ScoreWrap>
			<TimerWrap>
				<Title variant='h3'>Eindtijd:</Title>
				
				<ColoredTitle variant='h3'>{totalTime}</ColoredTitle>

			</TimerWrap>
			<DetailsWrap>
				{scoringContent.map((scoring) => {
					const scoreDetails = getScoring(storedAnswers, scoring.label);
					return (<Detail key={scoring.label}>
						<Title variant='h3'>{scoring.label} </Title>
						<ScoreBar percentage={scoreDetails.percentage}/>
						<ColoredTitle variant='h3'>{scoreDetails.label}</ColoredTitle>
					</Detail>);
				})}
			</DetailsWrap>
		</ScoreWrap>
	);
};

const Choice = styled.div`

	font-weight: bold;

 	&.correct{
		 color: green;
	 }

	 &.wrong{
		 color: red;
	 }

`;

const Feedback = styled.div`
	font-style: italic;
	margin-bottom: 20px;
`;



const EndScreen = () => {
	const lastTab = scoringContent.length;
	const [activeId, setActiveId] = useState<number>(0);
	const [pdfData, setPdfData] = useState<any>(null);

	const {endOfGame, storedAnswers} = useGameContext();

	const createPdfData = () => {

		const p: any = {};
		
		p.themeContent = [...scoringContent];
		p.themeScoring = [];
		p.playerChoice = [];

		scoringContent.map((theme, index) => {
			p.themeScoring[index] = getScoring(storedAnswers, theme.label);
			p.playerChoice[index] = [];
			theme.questions.map((question, iindex) => {

				const storedAnswer = storedAnswers[question.id];

				p.playerChoice[index][iindex] = {
					text: story[question.id].options[storedAnswer ? storedAnswer.value : 0].label,
					correct: storedAnswer ? (storedAnswer.value == question.correctValue) : false,
					choice: storedAnswer ? storedAnswer.value : 0,
					empty: storedAnswer ? false : true,
				};
			});
		});

		setPdfData(p);
	};

	useEffect(() => {
		if(!pdfData && endOfGame){
			createPdfData();
		}

	}, [pdfData, endOfGame]);



	return (
		<>
			{endOfGame && (<EndScreenSpace>
				<Window>

					{activeId === 0 && (
						<TextWrap>
							<Title variant="h2">Eindresultaat</Title>
							<div>
								{ 'Goed gedaan! Je hebt Robèrt geholpen en hem achtergelaten als ambassadeur van KPN. In de game kwamen gesprekken langs die gekoppeld zijn aan de vijf A’s in een klantgesprek. Kijk hoe goed je hebt gescoord door op \'verder \' te klikken. Download je resultaatverslag-PDF door op de link te klikken links onderin om jouw resultaten te kunnen bespreken.'}
							</div>
							<TextWrapInner>
								<ScoreDisplay/>
							</TextWrapInner>
						</TextWrap>)
					}

					{ activeId < 6 ? pdfData && scoringContent.map((scoring, index) => {
						const prep = '';
						const playerChoices = pdfData.playerChoice[index];

						return activeId === index + 1 && (
							<TextWrap key={scoring.label}>
								<Title variant="h2">{scoring.label}</Title>
								{ playerChoices.map((choice, iindex)=>{
									console.log(choice.empty, 'here');
									return (
										<>
											<Choice className={choice.correct ? 'correct' : 'wrong'}> { choice.empty == false && choice.text} <span>({choice.empty ? 'niet beantwoord' : choice.correct ? 'correct' : 'niet correct'})</span></Choice>
											{ 
												<Feedback>{choice.empty && scoring.questions[iindex].feedbackEmpty ? scoring.questions[iindex].feedbackEmpty : choice.empty ? 'N.V.T.'  : scoring.questions[iindex].feedback[choice.choice] }</Feedback>
											}
										</>
									);
								})}
							</TextWrap>
						);
					})
				
						:
						<>
							<Title variant="h2">Bedankt voor het spelen!</Title>
							<div><Button onClick={refreshPage}>Speel opnieuw</Button></div>
						</>

					}

                    
					<br/>


					{activeId < 6 ?
						<ButtonWrap>
							<div>	
								{pdfData &&
								<PDFDownloadLink document={<PDF pdfData={pdfData}/>} fileName="kpnescapegame_score.pdf">
								Download PDF
								</PDFDownloadLink>
								}			

							</div>
							<div>					
								{ activeId != 0 && <Button onClick={() => (setActiveId((oldId) => oldId - 1))}>
							Terug
								</Button>}
							</div> 
							<Button onClick={() => (setActiveId((oldId) => oldId + 1))}>
							Verder
							</Button>
							
						</ButtonWrap>
						: 
						<ButtonWrap>
							<div>	
								{pdfData &&
							<PDFDownloadLink document={<PDF pdfData={pdfData}/>} fileName="kpnescapegame_score.pdf">
							Download PDF
							</PDFDownloadLink>
								}			

							</div>					
						</ButtonWrap>
					}
				</Window>
			</EndScreenSpace>)}
		</>
	);
};

const EndScreenSpace = styled.div.attrs({top: [, 80], width: [,834],paddingLeft:[,60], paddingTop:[,60], paddingBottom:[,60]})`

    ${proportional};

	overflow-y: auto;
    position: absolute;
    left:0;
    top:0;
    bottom:0;

    z-index: 1;

`;

const ButtonWrap = styled.div`
    display: flex;
	justify-content: right;

	& > div{
		margin-right: auto;
		margin-top: auto;
		margin-bottom: auto;
	}
`;

const TextWrap = styled.div`

`;

const TextWrapInner = styled.div`
    max-height: 50vh;
    overflow: auto;
`;

export default EndScreen;