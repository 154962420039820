import { render_woonkamer_02_p2} from 'assets/renders';
import { getHotspots } from '../Hotspots';
import getConfig from './universalConfig';

const customConfig = {
	imageSource: render_woonkamer_02_p2,
};

// action: {label: changeScene | changeSceneFragment, value: string | number};

let customHotspots = [
	{
		pitch: -15.041,
		yaw: 126.985,
		'type': 'scene',
		'createTooltipArgs': {
			'text': '',
			action: {
				'label': 'changeScene',
				value: 4,
			}
		}
	},
];

// Asign standard hotspot config
customHotspots = getHotspots(customHotspots);
customConfig['hotSpots'] = customHotspots;

const Scene = {
	sceneId: 'woonkamer_04_p2',
	config: getConfig(customConfig),
	startingPitch: -15.041,
	startingYaw: 126.985,

};

export default Scene;

