import { OptionsAvailable, OptionsActive, OptionsInactive } from 'assets/icons';
import { useAudioContext } from 'context/AudioContext';
import { useGameContext } from 'context/gameContext';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { proportional } from 'util/proportional';


const Circle = styled.div.attrs({height:[,120], width:[,120], left:[,50], bottom:[,50]})`
	${proportional}

	position: absolute;

	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;

	z-index: 6;

	cursor:pointer;

	&.hidden{
		display: none;
	}

	&.inactive{
		background-image: url(${OptionsInactive});
		pointer-events: none;
		cursor:none;

	}

	&.active{
		background-image: url(${OptionsActive});
	}

	&.active-no-pointer{
		background-image: url(${OptionsActive});
		pointer-events: none;
		cursor:none;
	}

	&.available{
		background-image: url(${OptionsAvailable});
	}
`;

const StartDialogueButton = () => {
	const {dialogueButtonState, openRequestedNarrative} = useGameContext();
	const { playSound } = useAudioContext();


	const handler = () => {
		openRequestedNarrative && openRequestedNarrative();
	};

	useEffect( () => {
		if(dialogueButtonState === 'available'){	
			playSound && playSound('notification');
		}
	}, [dialogueButtonState]);
	

	return (
		<>
			{dialogueButtonState && <Circle className={`${dialogueButtonState}`} onClick={handler}/>}
		</>
	);
};

export default StartDialogueButton;
