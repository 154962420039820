import { render_woonkamer_01_p2} from 'assets/renders';
import { getHotspots } from '../Hotspots';
import getConfig from './universalConfig';

const customConfig = {
	imageSource: render_woonkamer_01_p2,
};

// action: {label: changeScene | changeSceneFragment, value: string | number};

let customHotspots = [
	{
		'pitch': '-26.257407542213943', 
		'yaw': '177.336847407560626',
		'type': 'scene',
		'createTooltipArgs': {
			'text': '',
			action: {
				'label': 'navigation',
				value: 2,
			}
		}
	},
	{
		'pitch': -5.99453,
		'yaw': 29.1052,
		'type': 'info',
		'createTooltipArgs': {
			'text': 'ladder',
			'type': 'scene',
			'sceneId': 'ladder',
		}
	},
	{
		'pitch': -7.957,
		'yaw': 110.18,
		'type': 'info',
		'createTooltipArgs': {
			'text': 'thermostaat',
			'type': 'scene',
			'sceneId': 'thermostaat',
		}
	},
	{
		'pitch': -23.8808,
		'yaw': -38.3430,
		'type': 'info',
		'createTooltipArgs': {
			'text': 'televisie',
			'type': 'scene',
			'sceneId': 'tv',
		}
	},
];

// Asign standard hotspot config
customHotspots = getHotspots(customHotspots);
customConfig['hotSpots'] = customHotspots;

const Scene = {
	sceneId: 'woonkamer_02_p2',
	config: getConfig(customConfig),
	startingPitch: -46.0,
	startingYaw: -65.335,

};

export default Scene;

