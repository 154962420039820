import { render_gamekamer_01_p1} from 'assets/renders';
import { getHotspots } from '../Hotspots';
import getConfig from './universalConfig';

const customConfig = {
	imageSource: render_gamekamer_01_p1,
};

// action: {label: changeScene | changeSceneFragment, value: string | number};

let customHotspots = [
	{
		'pitch': -16.1010,
		'yaw': 166.329,
		'type': 'info',
		'createTooltipArgs': {
			'text': 'kast',
			'type': 'scene',
			'sceneId': 'kast2',
		}
	},
	{
		'pitch': -26.78453,
		'yaw': -103.1795,
		'type': 'info',
		'createTooltipArgs': {
			'text': 'kast',
			'type': 'scene',
			'sceneId': 'kast',
		}
	},
	{
		'pitch': -33.498,
		'yaw': -114.382,
		'type': 'scene',
		'createTooltipArgs': {
			'text': '',
			action: {
				'label': 'navigation',
				value: 3,
			}
		}
	},
	{
		'pitch': -25.4869,
		'yaw': -5.06757,
		'type': 'info',
		'createTooltipArgs': {
			'text': 'kabel',
			'type': 'scene',
			'sceneId': 'kabel_fout',
		}
	},
	{
		'pitch': -10.2981,
		'yaw': -168.924,
		'type': 'info',
		'createTooltipArgs': {
			'text': 'Prive deur',
			'type': 'scene',
			'sceneId': 'prive_deur',
		}
	},
];

// Asign standard hotspot config
customHotspots = getHotspots(customHotspots);
customConfig['hotSpots'] = customHotspots;

const Scene = {
	sceneId: 'gamekamer_02_p1',
	config: getConfig(customConfig),
	// startingPitch: -15.041,
	// startingYaw: 126.985,
};

export default Scene;

