import { useGameContext } from 'context/gameContext';
import React, {useEffect, useRef} from 'react';
//import {loadScene} from 'react-pannellum';

const Input = () => {
	
	const eventRef = useRef<any>();

	useEffect(() => {
		eventRef.current = document.addEventListener('keyup', keyDown );
		
		return () => {
			document.removeEventListener(eventRef.current, keyDown);
		};
	}, []);

	const {toggleDisplayInterface} = useGameContext();

	function keyDown(e){
		switch(e.keyCode){
		case 32:
			//loadScene('s2');
			break;
		case 70:
			toggleDisplayInterface && toggleDisplayInterface();
			break;
		// case 49:
		// 	if (playBackground) {
		// 		playBackground(true);
		// 	}
		// 	break;
		// case 50:
		// 	if (playSound) {
		// 		playSound('teststeps', .05);
		// 	}
		// 	break;
		}
	}

	return (
		<></>
	);
};

    
export default Input;