import React, { ReactNode, forwardRef } from 'react';
import styled from 'styled-components';
import { variant } from 'styled-system';
import { designFormats } from 'theme';

interface variantProps {
    variant: string;
    children: ReactNode
}

const formatedPropValue = (val1, val2) => {
	return [`${(val1 / designFormats[1]) * 100}vw`, `${(val2 / designFormats[1]) * 100}vw`, `${val2}px`];
};


const Title = styled.h1<variantProps>`
  ${(props) =>
		variant({
			variants: {
				h1: {
					margin: 0,
					fontSize: formatedPropValue(110, 110),
					lineHeight: formatedPropValue(110, 110),
					marginBottom: formatedPropValue(15, 20),
				},
				h2: {
					marginBottom: formatedPropValue(10, 10),
					marginTop: formatedPropValue(10, 10),
					fontSize: formatedPropValue(60, 60),
					lineHeight: formatedPropValue(72, 72),
				},
				h3: {
					marginBottom: formatedPropValue(5, 5),
					marginTop: formatedPropValue(5, 5),
					fontSize: formatedPropValue(30, 30),
					lineHeight: formatedPropValue(30, 30),
					fontWeight: 500,

				},
				p: {
					fontSize: formatedPropValue(30, 30),
					lineHeight: formatedPropValue(30, 30),
					fontWeight: 400
				},
				p2: {
					fontSize: formatedPropValue(20, 20),
					lineHeight: formatedPropValue(20, 20),
					fontWeight: 400,
					margin: 0
				}
			},
		})}
`;

const H = forwardRef(({ variant = 'h1', children, ...rest }: any, ref) => {
	const validHeadingTypes = ['h1', 'h2', 'h3', 'h4', 'h5', 'p'];
	const safeHeadingType =
    validHeadingTypes.find((type) => type === variant.toLowerCase()) || 'p';
	return (
		<Title as={safeHeadingType} variant={variant} {...rest} ref={ref}>
			{children}
		</Title>
	);
});

H.displayName = 'Title';


export default H;