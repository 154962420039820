import React, {useState} from 'react';
import Scene from '../constants/Scenes';

type ContextProps = { 
    scene: Scene,
    onUpdateScene: (newScene) => void,
    sceneActive: boolean,
    onUpdateSceneActive: (newval) => void,

  };

const SceneContext = React.createContext<Partial<ContextProps>>({});

interface SceneProps {
    children: JSX.Element,
}


const SceneProvider = ({ children }: SceneProps) => {
	// selected scene logic
	const [scene, setScene] = useState<Scene>('painting');
	const onUpdateScene = (newScene) => setScene(newScene);

	// is scene active logic
	const [sceneActive, setSceneActive] = useState(false); 
	const onUpdateSceneActive = (newVal) => setSceneActive(newVal);

	return (
		<SceneContext.Provider value={{ scene, onUpdateScene, sceneActive, onUpdateSceneActive }}>
			{children}
		</SceneContext.Provider>
	);
};

const useSceneContext = () => React.useContext(SceneContext);

export {useSceneContext};

export default SceneProvider;