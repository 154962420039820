import { useGLTF } from '@react-three/drei';
import { Rommel1 } from 'assets/models';
import InteractivePoints from 'components/InteractivePoints';
import Model from 'components/Model';
import { useGameContext } from 'context/gameContext';
import React, { Suspense, useEffect } from 'react';

useGLTF.preload(Rommel1);

const scale = 2;

const Rommel1Scene = () => { 
	const {currentModelData, onAddActiveAnimation, onSetActiveLayer, modelIntroDone, onUpdateActiveModel, activeAnimations, activeModelLayer ,onSetNotificationText, setNotificationActive, onStoryEvent} = useGameContext();
	
	useEffect(() => {
		return () => {
			onSetNotificationText && onSetNotificationText(5);
		};
	}, []);


	useEffect(() => {
		if(modelIntroDone){
			onSetNotificationText && onSetNotificationText(3);
		}
	}, [modelIntroDone]);

	const onInteractivePointClick = (point) => {
		switch(point.name){
		case '01':
			onAddActiveAnimation && onAddActiveAnimation({nodeName: 'vuilniszak', animationName:'zak_fade_in'});
			onSetNotificationText && onSetNotificationText(4);
			onSetActiveLayer && onSetActiveLayer(2);
			break;
		case '02':
			onAddActiveAnimation && onAddActiveAnimation({nodeName: 'troep_01', animationName:'troep_01'});
			onSetActiveLayer && onSetActiveLayer(3);
			break;

		case '03':
			onAddActiveAnimation && onAddActiveAnimation({nodeName: 'troep_02', animationName:'troep_02_bijrijder'});
			onSetActiveLayer && onSetActiveLayer(4);
			break;

		case '04':
			onAddActiveAnimation && onAddActiveAnimation({nodeName: 'troep_03', animationName:'troep_03'});
			onSetActiveLayer && onSetActiveLayer(5);
			onSetNotificationText && onSetNotificationText(5);
			break;
		}
	};

	useEffect(() => {
		if(activeAnimations && activeAnimations.length === 0){
			switch(activeModelLayer){

			case 5:	
				onStoryEvent && onStoryEvent({type:'swapFragment', param: 1});
				// onUpdateActiveModel && onUpdateActiveModel(null);
				break;
			}

		}
	}, [activeAnimations]);

	return (
		<>
			<Suspense fallback={null}>
				{currentModelData && <Model customScale={scale}/>}
				{currentModelData && <InteractivePoints onInteractivePointClick={(name: string) => onInteractivePointClick(name)} customModelScale={scale}/>}
			</Suspense>
		</>
	);
};

export default Rommel1Scene;
