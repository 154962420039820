import { sceneProps } from 'data';

const Scene: sceneProps = 	{
	id: 5,
	story: {
		narrative: {
			'main': [
				'scene_04_main_01' , 	
			],
			'subScenes': [],
			'requested': [
				'scene_04_requested_01', 
				'scene_04_requested_02', 
				'scene_04_requested_03',
				'scene_04_requested_04', 
				'scene_04_requested_05', 
				'scene_04_requested_06',
				'scene_04_requested_07', 
				'scene_04_requested_08',
				'scene_04_requested_09', 
				'scene_04_requested_10', 
				'scene_04_automatic_01',
				'scene_04_automatic_02',
				'scene_04_automatic_03',
				'scene_04_automatic_04',
				'scene_04_automatic_05',
				'scene_04_automatic_06',
				'scene_04_automatic_07'
			],
			'requestedAtStart': [
			],
		},
	},
	pannellumData: [
		'gamekamer_01_p1',
		'gamekamer_01_p2',
		'gamekamer_02_p1',
		'gamekamer_02_p2',
		'gamekamer_03_p2',
		'gamekamer_04_p2',
		'gamekamer_04_p1',
		'gamekamer_05_p2'
	]
};

export default Scene;