const storyChunks = { 
	'scene_00_requested_00': {
		id: 'scene_00_requested_00',
		narrative: {
			text: 'Ja, hallo, met Robèrt.',
			audioId: 'caddy_00'
		},
		storyEvent: {
			type:'setNotificationText',
			param: 8
		},
		options: [
			{
				label: 'Goedemiddag u spreekt met Rene van KPN. Ik begrijp dat er een storing bij u thuis is. Klopt dat? Ik ben onderweg en ben er met een minuut of 10. Klopt het dat u woont op Computerweg 1 in Zeist?', 
				value: 1, 
				storyId: 'scene_00_requested_01'
			}, 
		],
	},
	'scene_00_requested_01': {
		id: 'scene_00_requested_01',
		narrative: {
			text: 'Hè, hè, eindelijk. Ben benieuwd of het jou wel gaat lukken dit op te lossen, je bent de vijfde al die langskomt. Het adres klopt inderdaad, maar ik ben nu niet thuis want mijn dochter is op school gevallen.',
			audioId: 'caddy_01'
		},
		storyEvent: {
			type:'setNotificationText',
			param: -1
		},
		options: [
			{
				label: 'Dat is niet handig, zo kan ik u helaas niet helpen want u moet mij wel kunnen laten zien waar de storingen zitten. ', 
				value: 1, 
				storyId: 'scene_00_requested_02'

			}, 			
			{
				label: 'Geen probleem, maar u moet wel aanwezig zijn voor mijn bezoek. We kunnen de afspraak best verplaatsen. ', 
				value: 2, 
				storyId: 'scene_00_requested_03'

			}, 			
			{
				label: 'Wat vervelend, hopelijk valt het mee? Lukt het als ik over tien minuten langskom?', 
				value: 3, 
				storyId: 'scene_00_requested_04'

			}, 
		],
	},
	'scene_00_requested_02': {
		id: 'scene_00_requested_02',
		narrative: {
			text: 'Ja dat snap ik ook wel, maar nu ben ik niet thuis. Over tien minuten wel. ',
			audioId: 'caddy_02'
		},
	},	
	'scene_00_requested_03': {
		id: 'scene_00_requested_03',
		narrative: {
			text: 'Nee dat is niet handig, ik wil de storing verholpen hebben. Over tien minuten kan ik thuis zijn. ',
			audioId: 'caddy_03'
		},
	},

	'scene_00_requested_04': {
		id: 'scene_00_requested_04',
		narrative: {
			text: 'Ja dat gaat zeker lukken, tot zo!',
			audioId: 'caddy_04'
		},
	},
};

export default storyChunks;