import { useSpring } from '@react-spring/core';
import { useGLTF, Html } from '@react-three/drei';
import { Tablet } from 'assets/models';
import Button from 'components/Button';
import InteractivePoints from 'components/InteractivePoints';
import Model from 'components/Model';
import { useGameContext } from 'context/gameContext';
import React, { Suspense, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

useGLTF.preload(Tablet);

const scale = 7;

const Wrapper = styled.div`
	/* background-color: pink; */

	display: flex;
	flex-direction: column;

	justify-content: center;

	width: 417px;
	height: 250px;

	padding: 15px;
	user-select: none;
`;

const TabletButton = styled.button`

	background-color: #009900;
	color:black;
	padding: 7px 70px 7px 70px;
	border-radius: 1em;
	font-size: 1.3rem;
	margin-bottom: 1rem;

	border: none;

	&:disabled{
		background-color: grey;
	}

	&:hover, :focus{
		color:white;
	}

	cursor: pointer;

`;
const TabletSceneKeuken = () => { 
	const {currentModelData, modelIntroDone, onUpdateScene, onUpdateActiveModel, activeModelLayer, onStoryEvent, onSetNotificationText, onSetActiveLayer} = useGameContext();

	useEffect(() => {
		if(activeModelLayer == 1)
			onStoryEvent && onStoryEvent({type:'setRequestedNarrative', param:'scene_05_requested_01'});
		
		return () => {
			onStoryEvent && onStoryEvent({type:'setRequestedNarrative', param:null});
		};
	}, []);

	const onInteractivePointClick = (point) => {
		switch(point.name){
		case '01':
		}
	};

	const [clicked, setClicked] = useState<boolean>(false);

	const clickHandler = () => {
		setClicked(true);
		onStoryEvent && onStoryEvent({type:'openDialogue', param:'scene_05_requested_14'});
		onUpdateActiveModel && onUpdateActiveModel(null);
	};

	const [distanceFactor, setDistanceFactor] = useState<number>(0);

	useEffect(() => {
		setTimeout(() => {
			setDistanceFactor(2);
		}, 1);
	},[]);
	return (
		<>
			<Suspense fallback={null}>
				<Html
					as='div'
					distanceFactor={distanceFactor}
					transform
					occlude
					position={[0,0,0.04]}
					scale={1}
				>
					<Wrapper>
						{activeModelLayer != 1 &&
							<TabletButton onClick={clickHandler}>Start afsluitend gesprek</TabletButton>
						}
					</Wrapper>
				</Html>
				{currentModelData && <Model customScale={scale}/>}
				{currentModelData && <InteractivePoints onInteractivePointClick={(name: string) => onInteractivePointClick(name)} customModelScale={scale}/>}
			</Suspense>
		</>
	);
};

export default TabletSceneKeuken;
