import { render_gamekamer_02_p2} from 'assets/renders';
import { getHotspots } from '../Hotspots';
import getConfig from './universalConfig';

const customConfig = {
	imageSource: render_gamekamer_02_p2,
};

// action: {label: changeScene | changeSceneFragment, value: string | number};

let customHotspots = [
	{
		pitch: -14.2080,
		yaw: 56.1600,
		'type': 'scene',
		'createTooltipArgs': {
			'text': '',
			action: {
				'label': 'changeScene',
				value: 6,
			}
		}
	},
];

// Asign standard hotspot config
customHotspots = getHotspots(customHotspots);
customConfig['hotSpots'] = customHotspots;

const Scene = {
	sceneId: 'gamekamer_05_p2',
	config: getConfig(customConfig),
	startingPitch: -8.9121,
	startingYaw: 56.7318,
};

export default Scene;

