import SplitText from 'components/SplitText';
import Window from 'components/Window';
import { useAudioContext } from 'context/AudioContext';
import { useGameContext } from 'context/gameContext';
import { motion } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { proportional } from 'util/proportional';
import Title from '../text/Title';

const DialogueContainer = styled(motion.div).attrs({paddingLeft:[,60], paddingTop:[,120], paddingBottom:[,25]})`
	${proportional}
	display:flex;
	flex-direction: column;
	align-items: stretch;
	position: absolute;
	left:0;
	top:0;
	bottom: 50vh;
	width: 50vw;
	text-align:left;

	z-index: 5;
`;

const OptionsContainer = styled(motion.div).attrs({paddingLeft:[,60], paddingTop:[,25], paddingBottom:[,60]})`
	${proportional}
	display:flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: stretch;
	position: absolute;
	left:0;
	top:35vh;
	bottom: 0;
	width: 50vw;
	text-align:left;


	z-index: 5;

`;

const DialogueBackdrop = styled.div`
	position: absolute;
	inset:0;
	background-color: black;
	opacity: 0.5;

	z-index: 5;
	
`;

const Options = styled.div`

`;

const Option = styled.div`



	border-bottom: solid 2px grey;

	&:hover, :focus{
		color:${(props) => props.theme.colors.accent_1 ? props.theme.colors.accent_1 : 'green'};
	}

	&:first-child{
		padding-top:0!important;
	}

	&:last-child{
		padding-bottom:0!important;
		border-bottom:none!important;
	}

	cursor: pointer;

	user-select: none;

`; 

const NextButton = styled.div`


	color: 'red'!important;

	padding-top: 20px;
	padding-bottom: 20px;

	cursor: pointer;

	user-select: none;

	&:hover, :focus{
		color:${(props) => props.theme.colors.accent_1 ? props.theme.colors.accent_1 : 'green'};
	}

`;

const NextInterface = ({onNext}: any) => {
	return (
		<OptionsContainer initial={{opacity: 0, y: 300}} animate={{opacity: 1, y: 0}} transition={{type: 'spring', stiffness: 100 }}>
			<Window>
				<NextButton onClick={() => {onNext();}}><Title variant="p">klik hier om door te gaan...</Title></NextButton>
			</Window>
		</OptionsContainer>
	);
};


const TextInterface = ({narrative, duration, ready }: any) => {
	const [displayed, setDisplayed] = useState(true);
	const [renderState, setRenderState] = useState(true);
	const [splitTextReady, setSplitTextReady] = useState(false);
	const [windowReady, setWindowReady] = useState(false);
	const [storedNarrative, setStoredNarrative] = useState('not set yet');

	useEffect(() => {

		if((narrative && storedNarrative) && storedNarrative !== narrative) {
			setDisplayed(false);
			setStoredNarrative(narrative);
		}

	}, [narrative, storedNarrative]); 

	const onForceReRender = () => {
		setRenderState(false);
	};

	// useEffect(() => {
	// },[]);

	useEffect(() => {
		if(!renderState) {
			setSplitTextReady(false);
			setWindowReady(false);
			ready && ready(false);
			setRenderState(true);
			setDisplayed(true);
		}

	}, [renderState]); 

	useEffect(() => {
		if(splitTextReady && windowReady) {
			ready(true);
		}
	}, [splitTextReady, windowReady]);

	return (
		<>
			{renderState && (<DialogueContainer onAnimationComplete={() => !displayed ? onForceReRender() : setWindowReady(true)} initial={{opacity: 0, y: -300}} animate={displayed ? {opacity: 1, y: 0} : {opacity: 1, y: -500}} transition={{type: 'spring', stiffness: 100 }}>
				<Window iconEnabled={true}>
					<Title variant="p">{duration && <SplitText ready={(splitTextReady && windowReady) || false} readyCallBack={(val) => setSplitTextReady(val)} duration={duration} text={narrative.text} words/>}</Title>
				</Window>
			</DialogueContainer>)}
		</>
	);
};

const TimerContainer = styled.div.attrs({marginBottom:[,50], width:[,80], height:[,80]})`
	${proportional}

	position: absolute;

	right:0;
	bottom:0;
	background: white;

	border-radius: 100%;

	transform: translate(50%, -50%);

`;

const TimerInnerRim = styled.div.attrs({})`
	${proportional}

	position: absolute;

	inset:0;

	border-radius: 100%;

	background-color:${(props) => props.theme.colors.accent_1 ? props.theme.colors.accent_1 : 'green'};

	margin: 3px;
`;

const TimerInnerestRim = styled.div.attrs({fontSize:[,50]})`
	${proportional}

	position: absolute;

	inset:0;

	border-radius: 100%;

	background-color:white;

	margin: 3px;

	color:${(props) => props.theme.colors.accent_1 ? props.theme.colors.accent_1 : 'green'};
	display:flex;
	
	span{
		margin:auto;
	}
`;

const TimerRadial = styled.div.attrs({})`
	${proportional}

	position: absolute;

	inset:0;

	border-radius: 100%;

	background-color:${(props) => props.theme.colors.accent_1 ? props.theme.colors.accent_1 : 'green'};


	margin: 3px;
`;

const Timer = ({callback} : any) => {

	const [time, setTime] = useState<number>(37);

	const onEnd = () => {
		callback && callback();
	};

	const timer = useRef<any | null>(null);

	useEffect(()=>{
		timer.current = setInterval(() => {
			setTime(e => e - 1);

		}, 1000);
		return () => {
			clearTimeout(timer.current);
		};
	},[]);

	useEffect(()=> {
		if(time <= 0){
			onEnd();
			clearTimeout(timer.current);
		}
	},[time]);



	return(
		<TimerContainer>
			<TimerInnerRim>
				<TimerInnerestRim>
					<span>{time}</span>
				</TimerInnerestRim>
			</TimerInnerRim>
		</TimerContainer>
	);
};

const OptionsInterface = ({options, callback}: any) => {
	// const {activeStoryChunk, getStoryChunk, onIncrementStoryProgression} = useGameContext();
	const {storeAnswer} = useGameContext();
	const onOptionSelect = (props : {value: number, storyId: number | undefined} | undefined) => {
		console.log(props);
		if(props !== undefined){
			if (props.value) {
				console.log('stored answer');
				storeAnswer && storeAnswer({value: String(props.value - 1)});
			}
		
			if(props.storyId) {
				// action to set story to specific ID, current index will have to be updated.
				callback(props.storyId);
			} else {
				callback();
			}
		}		
		else {
			// action to get next story in the main story line (if relevant);
			callback();
		}


	};
	return (
		<OptionsContainer initial={{opacity: 0, y: 300}} animate={{opacity: 1, y: 0}} transition={{type: 'spring', stiffness: 100 }}>
			<Window>
				<Options>
					{options && options.map((option, index) => (
						<>
							<Option onClick={() => onOptionSelect(option)} key={`${option.storyId}-${index}`}><Title variant="p">  {'"'}{option.label}{'"'}</Title></Option>
						</>
					))}
				</Options>
			</Window>
			{options.length > 1 &&
				<Timer callback={() => onOptionSelect(undefined)}/>
			}
		</OptionsContainer>
	);
};


const RequestedNarrativeTemplate = () => {
	const {storyData, setActiveStoryChunk, onIncrementStoryProgression, onStoryEvent, onSaveDialogueButtonState} = useGameContext();
	const {narrative, options, nextStoryId, storyEvent, storyEvent2, continueMain} = storyData;
	const {duration, setInterfaceReady, clientDone} = useAudioContext();



	const onOptionSelect = (storyId: any = null) => {
		onSaveDialogueButtonState && onSaveDialogueButtonState('inactive');
		if(storyId) {
			// set storyID
			setActiveStoryChunk && setActiveStoryChunk(storyId);
		} else {
			// set next main story in line
			if(continueMain){
				onIncrementStoryProgression && onIncrementStoryProgression();
			}
			else{
				setActiveStoryChunk && setActiveStoryChunk('');
			}
		}

		if(storyEvent){
			onStoryEvent && onStoryEvent(storyEvent);
		}
		if(storyEvent2){
			onStoryEvent && onStoryEvent(storyEvent2);
		}

	};

	const onNext = () => {
		onSaveDialogueButtonState && onSaveDialogueButtonState('inactive');
		if(storyEvent){
			onStoryEvent && onStoryEvent(storyEvent);
		}
		if(storyEvent2){
			onStoryEvent && onStoryEvent(storyEvent2);
		}

		if(nextStoryId){
			setActiveStoryChunk && setActiveStoryChunk(nextStoryId);
		} else{
			setActiveStoryChunk && setActiveStoryChunk('');
		}
	};

	return (
		<>
			<TextInterface ready={(newval: boolean) => setInterfaceReady && setInterfaceReady(newval)} duration={1} narrative={narrative}/>
			{options && clientDone && <OptionsInterface options={options} callback={(val) => onOptionSelect(val)}/>}
			{!options && clientDone && <NextInterface onNext={() => onNext()}/>}
		</>
	);
};

const MainNarrativeTemplate = () => {
	const {storyData, setActiveStoryChunk, onIncrementStoryProgression, onStoryEvent} = useGameContext();
	const {duration, setInterfaceReady, clientDone} = useAudioContext();

	const {narrative, options, nextStoryId, storyEvent, storyEvent2} = storyData;

	const onOptionSelect = (storyId: any = null) => {
		if(storyId) {
			// set storyID
			setActiveStoryChunk && setActiveStoryChunk(storyId);
		} else {
			// set next main story in line
			onIncrementStoryProgression && onIncrementStoryProgression();
		}
	};

	const onNext = () => {
		if(storyEvent){
			onStoryEvent && onStoryEvent(storyEvent);
		}
		if(storyEvent2){
			onStoryEvent && onStoryEvent(storyEvent2);
		}
		if(nextStoryId){
			setActiveStoryChunk && setActiveStoryChunk(nextStoryId);
		} else {
			// set next main story in line
			onIncrementStoryProgression && onIncrementStoryProgression();
		}
	};

	return (
		<>
			<TextInterface ready={(newval: boolean) => setInterfaceReady && setInterfaceReady(newval)} duration={1} narrative={narrative}/>
			{options && clientDone && <OptionsInterface options={options} callback={(val) => onOptionSelect(val)}/>}
			{!options &&  <NextInterface onNext={() => onNext()}/>}
		</>
	);
};

const SubNarrativeTemplate = () => {
	const {storyData, setActiveStoryChunk, onIncrementStoryProgression, onStoryEvent} = useGameContext();
	const {narrative, options, nextStoryId, storyEvent, storyEvent2} = storyData;
	const {duration, setInterfaceReady, clientDone} = useAudioContext();

	const onOptionSelect = (storyId: any = null) => {
		if(storyId) {
			// set storyID
			setActiveStoryChunk && setActiveStoryChunk(storyId);
		} else {
			// set next main story in line
			onIncrementStoryProgression && onIncrementStoryProgression();
		}
	};

	const onNext = () => {
		if(storyEvent){
			onStoryEvent && onStoryEvent(storyEvent);
		}
		if(storyEvent2){
			onStoryEvent && onStoryEvent(storyEvent2);
		}
		if(nextStoryId){
			setActiveStoryChunk && setActiveStoryChunk(nextStoryId);
		} else {
			// set next main story in line
			onIncrementStoryProgression && onIncrementStoryProgression();
		}
	};

	return (
		<>
			{/* <button onClick={() => setActiveStoryChunk && setActiveStoryChunk(null)}> skip</button> */}
			<TextInterface ready={(newval: boolean) => setInterfaceReady && setInterfaceReady(newval)} duration={1} narrative={narrative}/>
			{options && clientDone && <OptionsInterface options={options} callback={(val) => onOptionSelect(val)}/>}
			{!options && clientDone && <NextInterface onNext={() => onNext()}/>}
		</>
	);
};


const DialogueRender = ({type}: any) => {
	return (
		<>
			{type === 'requested' && <RequestedNarrativeTemplate />}
			{type === 'main' && <MainNarrativeTemplate />}
			{type === 'subScenes' && <SubNarrativeTemplate/>}
		</>
	);
};


const DialogueInterface = () => {
	const {activeStoryChunk, storyType} = useGameContext();

	return (
		<>
			{activeStoryChunk && storyType &&
				<>
					<DialogueBackdrop/>
					<DialogueRender type={storyType}/>
				</>
			}
		</>
	);
};


export default DialogueInterface;
