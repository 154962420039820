const storyChunks = {

	// Main Narratives & Sub Narratives

	'scene_03_main_01': {
		id: 'scene_03_main_01',
		narrative: {
			text: 'Dit is mijn office en hier werk ik bijna elke dag, maar sinds kort ben ik slecht of niet meer connected met internet.',
			audioId: 'werkkamer_00'
		},
		options: [
			{
				label: 'Niet verbonden zijn is inderdaad erg lastig. Ik start met een speedtest om te kijken of het wifi-bereik tot in deze kamer komt.',
				value: 1,
			},
			{
				label: 'Ja dat is natuurlijk niet handig, thuis werken zonder internet. Ik start met een speedtest, heeft u dat al geprobeerd?',
				value: 2
			},
			{
				label: 'Dat is very annoying als u thuis moet werken! Ik doe wel even een speedtest dat heeft u vast niet zelf geprobeerd. ',
				value: 3
			}
		],
	},

	'scene_03_main_02': {
		id: 'scene_03_main_02',
		narrative: {
			text: 'ontbrekend antwoord',
			audioId: 1
		},
	},

	// Requested Narrative 1

	'scene_03_requested_01':{
		id: 'scene_03_requested_01',
		narrative: {
			text: '...?',
			audioId: 1
		},
		options: [
			{
				label: 'De speedtest toont aan dat het bereik niet goed genoeg is voor deze kamer. Dit kan opgelost worden door een extra kabel aan te leggen of een extra wifi-punt te installeren.',
				value: 1,
				storyId: 'scene_03_requested_02'
			},
		],
	},
	'scene_03_requested_02':{
		id: 'scene_03_requested_02',
		narrative: {
			text: 'Nou meer kabels hoeft echt niet. Wat raad jij aan en wat kost een extra wifi-punt?',
			audioId: 'werkkamer_02'
		},
		storyEvent:{
			type:'queueStoryEvent',
			param: {
				type: 'openModel',
				param: 'wifipunt'
			},
		},
		storyEvent2:{
			type:'toggleCloseButton',
			param: true,
		},
		options: [
			{
				label: 'Dat weet ik nu even niet uit mijn hoofd, dus ik kom later op de kosten terug. Maar goed, zal iets aanleggen of niet?',
				value: 1,
				storyId: 'scene_03_requested_03'
			},
			{
				label: 'De kabel is iets goedkoper dan het wifi-punt, dus het ligt er maar net aan wat u wilt. Als u dat wilt kan ik het aanleggen.',
				value: 2,
				storyId: 'scene_03_requested_04'
			},
			{
				label: 'Een extra kabel kan, maar dan ligt er nóg een kabel in deze kamer. Een wifi-punt is duurder, maar valt wel minder op. Bij akkoord kan ik deze direct installeren voor u.',
				value: 3,
				storyId: 'scene_03_requested_05'
			},
		],
	},

	//#region scene_03_requested_02 options

	'scene_03_requested_03': {
		id: 'scene_03_requested_03',
		narrative: {
			text: 'Nou, not. Ik hoor het liever nu.',
			audioId: 'werkkamer_03'
		},
	},

	'scene_03_requested_04': {
		id: 'scene_03_requested_04',
		narrative: {
			text: 'Naja ik wil gewoon dat het werkt allemaal dus prima doe maar.',
			audioId: 'werkkamer_04'
		},
	},

	'scene_03_requested_05': {
		id: 'scene_03_requested_05',
		narrative: {
			text: 'Minder kabels is wel fancier ja, doe maar een wifi-kastje. ',
			audioId: 'werkkamer_05'
		},
	},


	//#endregion

	// Requested Narrative 2

	'scene_03_requested_06':{
		id: 'scene_03_requested_06',
		narrative: {
			text: '...?',
			audioId: 1
		},
		options: [
			{
				label: 'Ik moet een wachtwoord invoeren om alles weer te resetten. Wat is uw wachtwoord, dan stel ik het internet opnieuw in.',
				value: 1,
				storyId: 'scene_03_requested_07'
			},
			{
				label: 'Ik maak voor nu een tijdelijk wachtwoord aan dat u later nog kunt aanpassen, want voor de reset moet ik kunnen inloggen.',
				value: 2,
				storyId: 'scene_03_requested_08'

			},
			{
				label: 'Zoals u natuurlijk weet moet ik bij een reset ook even een nieuw wachtwoord invoeren. Zal ik er maar gewoon een verzinnen? Die kunt u dan later nog aanpassen enzo.',
				value: 3,
				storyId: 'scene_03_requested_09'
			}
		],
	},

	//#region scene_02_requested_05 options

	'scene_03_requested_07': {
		id: 'scene_03_requested_07',
		narrative: {
			text: 'Dat gaat je niks aan, maak maar een tijdelijke aan die ik later verander.',
			audioId: 'werkkamer_07'
		},
	},

	'scene_03_requested_08': {
		id: 'scene_03_requested_08',
		narrative: {
			text: 'Ja fijn, dank je.',
			audioId: 'werkkamer_08'
		},
	},

	'scene_03_requested_09': {
		id: 'scene_03_requested_09',
		narrative: {
			text: 'Dat weet ik, of course. Maak maar een tijdelijk wachtwoord.',
			audioId: 'werkkamer_09'
		},
	},
	'scene_03_requested_10': {
		id: 'scene_03_requested_10',
		narrative: {
			text: 'Ja die 7 en de 0 kunnen nog wel eens van pas komen. ',
			audioId: 'werkkamer_10'
		},
	},

	'scene_03_requested_11': {
		id: 'scene_03_requested_11',
		narrative: {
			text: 'Dude, heb je mijn eigen gemaakte keyboard al bekeken?',
			audioId: 'werkkamer_11'
		},
	},

	'scene_03_requested_12': {
		id: 'scene_03_requested_12',
		narrative: {
			text: 'Wat ga je doen? Wifi voor me uitprinten?',
			audioId: 'werkkamer_12'
		},
	},
	//#endregion
};

export default storyChunks;