import { render_gamekamer_01_p1} from 'assets/renders';
import { getHotspots } from '../Hotspots';
import getConfig from './universalConfig';

const customConfig = {
	imageSource: render_gamekamer_01_p1,
};

// action: {label: changeScene | changeSceneFragment, value: string | number};

let customHotspots = [
	{
		'pitch': -20.4824,
		'yaw': 78.5083,
		'type': 'info',
		'createTooltipArgs': {
			'text': 'Numpad',
			'type': 'scene',
			'sceneId': 'numpad_gamekamer',
		}
	},
	{
		'pitch': 0.557,
		'yaw': -152.20,
		'type': 'info',
		'createTooltipArgs': {
			'text': 'Vlinders',
			'type': 'scene',
			'sceneId': 'vlinders',
		}
	},
	{
		'pitch': 5.4664,
		'yaw': 130.297,
		'type': 'info',
		'createTooltipArgs': {
			'text': 'Hexagons',
			'type': 'scene',
			'sceneId': 'hexagons1',
		}
	},
	{
		'pitch': -27.188,
		'yaw': 144.962,
		'type': 'info',
		'createTooltipArgs': {
			'text': 'Flipperkast',
			'type': 'scene',
			'sceneId': 'flipperkast',
		}
	},
	{
		'pitch': -1.5594,
		'yaw': -143.565,
		'type': 'info',
		'createTooltipArgs': {
			'text': 'Hexagons',
			'type': 'scene',
			'sceneId': 'hexagons2',
		}
	},
	{
		'pitch': 0.3334,
		'yaw': 168.8969,
		'type': 'info',
		'createTooltipArgs': {
			'text': 'Kastjes',
			'type': 'scene',
			'sceneId': 'hexagonkastjes',
		}
	},
	{
		'pitch': -33.498,
		'yaw': -114.382,
		'type': 'scene',
		'createTooltipArgs': {
			'text': '',
			action: {
				'label': 'navigation',
				value: 5,
			}
		}
	},
];

// Asign standard hotspot config
customHotspots = getHotspots(customHotspots);
customConfig['hotSpots'] = customHotspots;

const Scene = {
	sceneId: 'gamekamer_04_p1',
	config: getConfig(customConfig),
	startingPitch: -51.63,
	startingYaw: -33.039,
};

export default Scene;

