import { useGLTF } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import {  Kast } from 'assets/models';
import InteractivePoints from 'components/InteractivePoints';
import Model from 'components/Model';
import { useGameContext } from 'context/gameContext';
import React, { Suspense, useEffect } from 'react';

useGLTF.preload(Kast);

const scale = 2;

const KastScene = () => { 
	const {currentModelData, onAddActiveAnimation, onSetActiveLayer, activeAnimations, activeModelLayer, setSceneFragment, modelIntroDone, onStoryEvent} = useGameContext();
	const { scene } = useThree();



	const onInteractivePointClick = (point) => {
		switch(point.name){
		case '01':
			onAddActiveAnimation && onAddActiveAnimation({nodeName: 'kast_deur', animationName:'kast_open'});
			onSetActiveLayer && onSetActiveLayer(2);
			break;
		case '02':
			onAddActiveAnimation && onAddActiveAnimation({nodeName: 'RJ45', animationName:'kabel_grab'});
			// onAddActiveAnimation && onAddActiveAnimation({nodeName: 'GameRoom_Kast', animationName:'kast_fade'});
			onSetActiveLayer && onSetActiveLayer(3);
			break;

		}
	};

	useEffect(() => {
		if(activeAnimations && modelIntroDone && activeAnimations.length === 0){
			switch(activeModelLayer){

			case 3:
				onAddActiveAnimation && onAddActiveAnimation({nodeName: 'RJ45', animationName:'rj45_zoom'});
				onAddActiveAnimation && onAddActiveAnimation({nodeName: 'GameRoom_Kast', animationName:'kast_fade'});
				setSceneFragment && setSceneFragment(4);
				onSetActiveLayer && onSetActiveLayer(4);

				break;
			}
			if(activeModelLayer == 3){
				onStoryEvent && onStoryEvent({type: 'openDialogue', param: 'scene_04_requested_10'});
			}
		}
	}, [activeAnimations]);

	return (
		<>
			<Suspense fallback={null}>
				{currentModelData && <Model customScale={scale}/>}
				{currentModelData && <InteractivePoints onInteractivePointClick={(name: string) => onInteractivePointClick(name)} customModelScale={scale} />}
			</Suspense>
		</>
	);
};

export default KastScene;
