const storyChunks = {

	// Requested Narrative 1

	'scene_05_requested_01':{
		id: 'scene_05_requested_01',
		narrative: {
			text: '...?',
			audioId: 1
		},
		storyEvent: {
			type:'setActiveModelLayer',
			param: 2
		},
		options: [
			{
				label: 'Ik vat graag voor u samen wat ik heb allemaal bij u thuis heb gedaan. Ik heb uw meterkast voor een klein bedrag opgeruimd, heb een paar kabels in de juiste poort gedaan, heb een nieuwe kabel aangelegd in de meterkast en een nieuw wifi-punt voor u aangelegd.',
				value: 1,
				storyId: 'scene_05_requested_02'
			},
		],
	},
	'scene_05_requested_02':{
		id: 'scene_05_requested_02',
		narrative: {
			text: 'Oh ja over die extra kosten wilde ik het nog hebben. Kijk eens in die keukenla.',
			audioId: 'keuken_01'
		},
		storyEvent: {
			type:'swapFragment',
			param: 1
		},
	},
	'scene_05_requested_03':{
		id: 'scene_05_requested_03',
		narrative: {
			text: 'Dat is voor jou, dan kun je even lekker lunchen.',
			audioId: 'keuken_02'
		},
		options: [
			{
				label: 'Dank u wel voor het aanbod, fijn dat u mijn werk waardeert, maar ik mag geen fooi aannemen. Wilt u de bon nog tekenen voor akkoord?',
				value: 1,
				storyId: 'scene_05_requested_04'
			},
			{
				label: 'Ja bedankt, lekker lunchen klinkt goed, maar nee dit mag ik helaas niet aannemen. Kunt u de bon voor akkoord tekenen?',
				value: 2,
				storyId: 'scene_05_requested_05'
			},
			{
				label: 'Heel chill, wij begrijpen elkaar tenminste en een goede lunch gaat er natuurlijk altijd lekker in. Tekent u nog wel even de bon?',
				value: 3,
				storyId: 'scene_05_requested_06'
			},
		],
	},

	//#region scene_05_requested_03 options

	'scene_05_requested_04': {
		id: 'scene_05_requested_04',
		narrative: {
			text: 'Fine, ik snap het, maar ik kon het proberen toch? Ik teken de bon.',
			audioId: 'keuken_03'
		},
	},

	'scene_05_requested_05': {
		id: 'scene_05_requested_05',
		narrative: {
			text: 'Is goed hoor.',
			audioId: 'keuken_04'
		},
	},

	'scene_05_requested_06': {
		id: 'scene_05_requested_06',
		narrative: {
			text: 'Love it! Ik teken de bon. ',
			audioId: 'keuken_05'
		},
	},


	//#endregion

	// Requested Narrative 2

	'scene_05_requested_07':{
		id: 'scene_05_requested_07',
		narrative: {
			text: '...?',
			audioId: 1
		},
		storyEvent: {
			type:'setActiveModelLayer',
			param: 2
		},
		options: [
			{
				label: 'We hebben trouwens ook een Kidspakket, is dat iets voor u?',
				value: 1,
				storyId: 'scene_05_requested_08'
			},
			{
				label: 'Kent u het Kidspakket trouwens? Dat moet u ook maar nemen.',
				value: 2,
				storyId: 'scene_05_requested_08'
			},
			{
				label: 'Ik bedacht me net dat uw dochter misschien ons Kidspakket met extra zenders leuk zou kunnen vinden.',
				value: 3,
				storyId: 'scene_05_requested_08'
			},
		],
	},
	'scene_05_requested_08':{
		id: 'scene_05_requested_08',
		narrative: {
			text: 'Wat is dat Kidspakket dan?',
			audioId: 'keuken_07'
		},
		options: [
			{
				label: 'Het zijn wel weer extra kosten van €4,99 per maand, maar met het Kidspakket heeft u meer zenders voor kinderen.',
				value: 1,
				storyId: 'scene_05_requested_11'
			},
			{
				label: 'In het Kidspakket zitten vier nieuwe zenders met films en series die uw dochter misschien wel leuk vindt.',
				value: 2,
				storyId: 'scene_05_requested_12'
			},
			{
				label: 'Het Kidspakket heeft een groot aanbod aan leuke en leerzame series en films voor alle leeftijden. Het is veilig en zonder reclame!',
				value: 3,
				storyId: 'scene_05_requested_13'
			},
		],
	},
	'scene_05_requested_09':{
		id: 'scene_05_requested_09',
		narrative: {
			text: 'Waarom zou ik dat moeten nemen?',
			audioId: 'keuken_08'
		},
		options: [
			{
				label: 'Het zijn wel weer extra kosten van €4,99 per maand, maar met het Kidspakket heeft u meer zenders voor kinderen.',
				value: 1,
				storyId: 'scene_05_requested_11'
			},
			{
				label: 'In het Kidspakket zitten vier nieuwe zenders met films en series die uw dochter misschien wel leuk vindt.',
				value: 2,
				storyId: 'scene_05_requested_12'
			},
			{
				label: 'Het Kidspakket heeft een groot aanbod aan leuke en leerzame series en films voor alle leeftijden. Het is veilig en zonder reclame!',
				value: 3,
				storyId: 'scene_05_requested_13'
			},
		],
	},
	'scene_05_requested_10':{
		id: 'scene_05_requested_10',
		narrative: {
			text: 'Alles voor mijn darling! Wat is het Kidspakket?',
			audioId: 'keuken_09'
		},
		options: [
			{
				label: 'Het zijn wel weer extra kosten van €4,99 per maand, maar met het Kidspakket heeft u meer zenders voor kinderen.',
				value: 1,
				storyId: 'scene_05_requested_11'
			},
			{
				label: 'In het Kidspakket zitten vier nieuwe zenders met films en series die uw dochter misschien wel leuk vindt.',
				value: 2,
				storyId: 'scene_05_requested_12'
			},
			{
				label: 'Het Kidspakket heeft een groot aanbod aan leuke en leerzame series en films voor alle leeftijden. Het is veilig en zonder reclame!',
				value: 3,
				storyId: 'scene_05_requested_13'
			},
		],
	},

	//#region scene_05_requested_08/09/10 options

	'scene_05_requested_11': {
		id: 'scene_05_requested_11',
		narrative: {
			text: 'No thank you, ik heb al genoeg geld uitgegeven.',
			audioId: 'keuken_10'
		},
	},

	'scene_05_requested_12': {
		id: 'scene_05_requested_12',
		narrative: {
			text: 'Oh ja dat vindt ze misschien wel leuk.',
			audioId: 'keuken_11'
		},
	},

	'scene_05_requested_13': {
		id: 'scene_05_requested_13',
		narrative: {
			text: 'Great! Doe maar, dat vindt mijn dochter vast een leuke verrassing. ',
			audioId: 'keuken_12'
		},
	},

	//#endregion

	// Requested Narrative 3

	'scene_05_requested_14':{
		id: 'scene_05_requested_14',
		narrative: {
			text: '...?',
			audioId: 1
		},
		options: [
			{
				label: 'Ik hoop dat ik u voldoende heb geholpen! Als u de volgende keer weer een probleem heeft, kunt u altijd met de klantenservice bellen. Zij lossen het probleem voor u op. En als dat niet lukt, maken ze een afspraak met een monteur. Het kan zijn dat u nog een mail krijgt waarin u uw mening over dit bezoek kunt geven. Het zou fijn zijn als u die dan invult.',
				value: 1,
				storyId: 'scene_05_requested_15'
			},
		],
	},

	//#region scene_05_requested_14 options

	'scene_05_requested_15': {
		id: 'scene_05_requested_15',
		narrative: {
			text: 'Jij bedankt voor je komst! Ik ben heel happy dat jij wel alles hebt kunnen repareren en zal KPN zeker aanbevelen bij bekenden!',
			audioId: 'keuken_13'
		},
		storyEvent: {
			type:'endGame',
			param: 0,
		},
		storyEvent2:{
			type:'swapFragment',
			param: 2,
		}
	},

	//#endregion
};

export default storyChunks;