import React, {useEffect} from 'react';
// import LTIAuthenticator from 'authentication/LTI';
import './App.css';
import CanvasContainer from 'components/CanvasContainer';
import Pannellum from 'components/Pannellum';
import Layout from 'components/Layout';
import Input from 'components/Input';
import AudioProvider from './context/AudioContext';
import SceneProvider from './context/sceneContext';
import GameProvider from 'context/gameContext';
import GameInterface from 'components/GameInterface';
import DialogueInterface from 'components/DialogueInterface';
import NotificationPanel from 'components/NotificationPanel';
import StartDialogueButton from 'components/StartDialogueButton';
import EndScreen from 'components/EndScreen';
import IntroductionScreen from 'components/IntroductionScreen';
import ScormApi from './ScormApi';


function App(): JSX.Element {
	useEffect(() => {
		// const scormApi = ScormApi.getInstance();
		// if(!scormApi.init(true)){
		// 	alert('scorm initialization failed');
		// }
	},[]);

	return (
		<div className="App">
			<Layout>
				<AudioProvider>
					<GameProvider>
						<SceneProvider>
							<>
								<NotificationPanel/>
								<StartDialogueButton/>
								<EndScreen/>
								<IntroductionScreen/>
								<DialogueInterface/>
								<GameInterface/>
								<CanvasContainer />
								<Pannellum/>
								<Input/>
							</>
						</SceneProvider>
					</GameProvider>
				</AudioProvider>
			</Layout>
		</div>
	);
}

export default App;

